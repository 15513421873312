import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row, InputGroup } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllVideos } from "../../../redux/actions/video.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo, removeDuplicate } from "../../../utils/core";
import { RESET_VIDEOS } from "../../../redux/constants/videoActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import dataLoading from "../../../img/data_loader.gif"


const BrowseSurgeryVideo = () => {

  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  let videosList = useSelector((state) => state.video.videosList);
  const pageNo = useSelector((state) => state.video.page);
  // const [pageNo, setPageNo] = useState(1);
  const [pageLoader, setPageLoader] = useState(false);
  const hasMore = useSelector((state) => state.video.hasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);
  const [searchParams, setSearchParams] = useState("");

  useEffect(() => { }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (isInitialMount.current) {
      dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: pageNo }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: 1 }, true));
    }
    // dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: 1 }, true));
  }, [selectedFilter]);

  const fetchVideos = () => {
    dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: pageNo }));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_VIDEOS });
    };
  }, []);

  const handleSearch = () => {
    dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: 1 }, true));
  };


  // const loadMoreData = async () => {
  //   setPageLoader(true)
  //   setPageNo((prevPageNo) => prevPageNo + 1);
  //   if(pageNo > 1) {
  //     dispatch(getAllVideos({ ...selectedFilter, title: searchParams, page: pageNo }));
  //   }
  // }

  // useEffect( async () => {
  //   if(videosList.length > 0) {
  //     videosList = await removeDuplicate(videosList);
  //   }
  //   setPageLoader(false)
  // }, [pageNo]);
  

  return (
    <Root>
      <Breadcrumb
        title={"Surgery Videos"}
        fieldOne="Dashboard"
        fieldTwo="Browse Surgery Videos"
      />

      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Surgery Videos</h4>
              <p>Browse Surgery Videos from top surgeons of your field.</p>
            </div>
            <div className="d-lg-block">
              <Button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/uploadSurgeryVideos");
                }}
              >
                Upload Surgery Videos{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>
              <Button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/mySurgeryVideos");
                }}
              >
                My Uploads{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>

            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>

          <Filters title="Filter Surgery Videos" module="observership" />
        </div>
        <Row>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={searchParams}
              onChange={(e) => setSearchParams(e.target.value)}
              placeholder="Search Surgery Videos by Title"
            />
            <Button onClick={()=>handleSearch()} type="button">
              <i className="fa-solid fa-search fa-lg" /> Search
            </Button>
          </InputGroup>
        </Row>
        <Row>
          {videosList && videosList.length > 0 ? (
            videosList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-video"
                  badgeTitle="Video"
                  cardTitle={val.title}
                  detailsLink={`/video-details/${val._id}`}
                  cardId={val._id}
                  doctorName={`Dr. ${val.user_details?.firstName} ${val.user_details?.lastName}`}
                  doctorId={val.user_details?._id}
                  doctorAddress={`${val.user_details?.institution?.instituteName}, ${val.user_details?.country?.state?.city?.cityName}`}
                  viewsCount={`${val.views} Views`}
                  noOfDays={timeAgo(val.createdAt)}
                  mediaType={val.urlType}
                  profilePicture={val?.user_details?.profilePicture}
                  url={val.url}
                  isHost={false}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
            {/* { pageLoader ? (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <img src={dataLoading} style={{textAlign: "center"}} width={"20%"}/>
              </div>
            ) : (
              <InfiniteScroll
                loadMoreData={loadMoreData}
                globalState={videosList}
                hasMore={hasMore}
              />
            )} */}


        <InfiniteScroll
          fetchFunction={fetchVideos}
          globalState={videosList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseSurgeryVideo;
