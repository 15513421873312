import React, { useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { formatDate, getStatusFromDateString , isMeetingEnd} from "../../../utils/core";
import DetailsComponent from "../../components/DetailsComponent/DetailsComponent";
import { useNavigate, useParams } from "react-router-dom";
import { getLivesurgery, registerToSurgery, joinLiveSurgery } from "../../../redux/actions/livesyrgery.action";



const LiveSurgeryDetails = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const surgeryById = useSelector(
    (state) => state.livesurgery.getSurgeryDetails
  );

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  const [infoArray, setInfoArray] = useState([]);
  useEffect(() => {
    dispatch(getLivesurgery({ id: id }));
  }, []);

  useEffect(() => {
    if (surgeryById) {
      setInfoArray([
        { name: "Speciality", value: surgeryById?.speciality?.specialityName },
        {
          name: "Subspeciality",
          value: surgeryById?.speciality?.subSpeciality?.subSpecialityName,
        },
        {
          name: "Date & Time",
          value: `${formatDate(surgeryById?.startDate)} to ${formatDate(
            surgeryById?.endDate
          )} from ${surgeryById?.startTime} to ${surgeryById?.endTime}`,
        },
      ]);
    }
  }, [surgeryById]);

  const getButtonText = (id) => {
    if (currentUser == id) {
      return "Start";
    } else {
      return "Join";
    }
  };

  const handleActionButton = (id) => {
    // dispatch(joinLiveSurgery({ id: id }, navigate));
    if (currentUser == id) {
      if (surgeryById?.startUrl) {
        window.open(surgeryById.startUrl, "_self");
      }
    } else {
      if (surgeryById?.joinUrl) {
        window.open(surgeryById.joinUrl, "_self");
      }
    }
  };


  const registerNow = (id) => {
    dispatch(registerToSurgery({ id: id }, { page: 1 }));
  };


  const renderFooterButton = () => {
    if(!isMeetingEnd(surgeryById?.endDate)){
      if (currentUser == surgeryById?.user_details?._id) {
        if (surgeryById.isApprove === "1") {
            return (
              <button
                onClick={() => handleActionButton(surgeryById?.user_details?._id)}
                class="btn btn-primary btn-lg mb-2"
              >
                Start<i class="fa-solid fa-arrow-right"></i>
              </button>
            );
        } else {
          return <span class={`badge bg-cme mb-2`}>Under Review</span>;
        }
      } else {
        let member = surgeryById?.members?.find(
          (member) => member.user_id === currentUser
        );
        if (member?.transaction_status === "COMPLETED") {
            return (
              <button
                onClick={() => handleActionButton(surgeryById?.user_details?._id)}
                class="btn btn-primary btn-lg mb-2"
              >
                Join<i class="fa-solid fa-arrow-right"></i>
              </button>
            );
        } else {
          return (
            <>
              <button
                className=" btn btn-primary mb-2 me-1"
                onClick={() => registerNow(surgeryById?._id)}
              >
                {/* <i className="fa-solid fa-check me-1"></i> */}
                 Register Now <i className="fa-solid fa-arrow-right"></i>
              </button>
            </>
          );
        }
      }
    } else {
      return <span class={`badge bg-cme mb-2`}>Ended</span>;
    }
  };


  return (
    <Root>
      <Breadcrumb
        title={"Live Surgery Details"}
        fieldOne="Dashboard"
        fieldTwo="Live Surgery"
        fieldThree="Live Surgery Details"
      />
      <ContainerWrapper>
        <DetailsComponent
          hideThumbNail={true}
          hideShareField={true}
          title={surgeryById.title}
          infoArray={infoArray}
          module="Live Surgery"
          badgeClassName="bg-surgery"
          detailsTitle="Live surgery details"
          detailsDescription={surgeryById?.description}
          facultyTitle={"Faculty"}
          facultyName={ surgeryById?.faculty_text ? surgeryById?.faculty_text : surgeryById?.faculty_details?.firstName + " " +surgeryById?.faculty_details?.lastName}
          facultyInstitution={
            surgeryById?.institution_text ? surgeryById?.institution_text : surgeryById?.faculty_details?.institution?.instituteName
          }
          fee={surgeryById?.observershipFee}
          fileUrl={surgeryById?.brochure}
          buttonText={`${getButtonText(surgeryById?._id)} Meeting`}
          buttonAction={() => {
            handleActionButton(surgeryById?.user_details?._id);
          }}
          hideActionButton={!surgeryById?.joinUrl && !surgeryById?.startUrl}
          profilePicture={surgeryById?.faculty_details?.profilePicture}
          facultyId={surgeryById?.faculty_details?._id}
          isHost={ currentUser == surgeryById.user_details?._id ? true : false}
          footerButton={renderFooterButton()}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default LiveSurgeryDetails;
