import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Privacy = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Privacy Policy"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Privacy Policy",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 col-lg-10 order-2 order-lg-1">
              <p className="title-meta mt-0">
                Privacy Policy <span className="line"></span>
              </p>
              <p>
                <strong>Patient Confidentiality:</strong> Surgic Share strictly
                prohibits the disclosure of any patient’s identity, either
                directly or indirectly, in any activity conducted on the
                platform. If any participant violates this policy, they are
                solely responsible for their actions and may be subject to legal
                action.
              </p>
              <p>
                <strong>Purpose of the Platform:</strong> Surgic Share is
                exclusively for academic purposes. The platform may not be used
                for any other purpose, including but not limited to commercial,
                promotional, or personal activities that are unrelated to the
                exchange of medical and surgical knowledge.
              </p>
              <p>
                <strong>Restricted Access:</strong> Surgic Share is intended
                solely for registered medical, surgical, and dental doctors,
                surgeons, and students. Non-medical individuals are not allowed
                to register or participate. If an individual is found to have
                registered with false information or to have posted content that
                is not academically relevant, Surgic Share reserves the right to
                suspend their account and take appropriate legal action.{" "}
              </p>
              <p>
                <strong>Identity Verification:</strong> All participants must
                use their real names and credentials. Using a false identity or
                misrepresenting qualifications will result in immediate
                suspension from the platform and potential legal action.
              </p>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  );
};

export default Privacy;
