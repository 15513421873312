import React, { useState, useEffect } from "react";
import Root from "../../components/Root";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Details from "../../components/Details/Details";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { formatDate } from "../../../utils/core";

import { getVideoDetails } from "../../../redux/actions/video.action";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const VideoDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const videoData = useSelector((state) => state.video.videoDetails);

  console.log(id);
  console.log(videoData);

  useEffect(() => {
    dispatch(getVideoDetails({ id: id }));
  }, []);

  return (
    <Root>
      <Breadcrumb
        title={"Video Details"}
        fieldOne="Dashboard"
        fieldTwo="Surgery Videos"
        fieldThree="Video Details"
      />
      <ContainerWrapper>
        {videoData ? (
          <Details
            tag={"Video"}
            title={videoData.title}
            patientDetails={videoData.patient_details}
            discriptionName="Patient Profile"
            speciality={videoData.speciality}
            comments={videoData.comments}
            createDate={formatDate(videoData.createdAt)}
            likes={videoData.likes}
            totalComments={videoData.totalComments}
            totalLikes={videoData.totalLikes}
            totalviews={videoData.views}
            userDetails={videoData.user_details}
            url={videoData.url}
            urlType={videoData.urlType ? videoData.urlType : "video"}
            videoId={videoData._id}
          />
        ) : (
          <NoDataFound />
        )}
      </ContainerWrapper>
    </Root>
  );
};

export default VideoDetails;
