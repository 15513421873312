import { toast } from 'react-toastify';
import * as api from '../../services/api';
import { GET_FILTER_CITIES, GET_FILTER_COUNTRY, GET_FILTER_INSTITUTE, GET_FILTER_SPECIALITY, GET_FILTER_STATES, GET_FILTER_SUB_SPECIALITY, GET_FACULTY, GET_DESIGNATION, GET_YEAR_OF_EXPERIENCE, GET_QUALIFICATION, GET_REQUEST_TYPE, GET_FACULTY_SPECIALITY, GET_COUNTRY_CODE } from '../constants/filterActionTypes';

export const getSpeciality = () => async (dispatch) => {
    try {
      const result = await api.fetchSpeciality();
      dispatch({ type: GET_FILTER_SPECIALITY, payload: result.data.data });
    } catch (error) {
      console.log(error.message);
  }
};

export const getSubSpeciality = (data) => async (dispatch) => {
    try {
      const result = await api.fetchSubSpeciality(data);
      dispatch({ type: GET_FILTER_SUB_SPECIALITY, payload: result.data.data });
      return result.data.data
    } catch (error) {
      console.log(error.message);
    }
};

export const getCountries = (data) => async (dispatch) => {
    try {
      const result = await api.fetchCountries(data);
  
      dispatch({ type: GET_FILTER_COUNTRY, payload: result.data.data });
    } catch (error) {
      console.log(error.message);
    }
};
export const getCountryCode = (data) => async (dispatch) => {
    try {
      const result = await api.fetchCountryCode(data);
  
      dispatch({ type: GET_COUNTRY_CODE, payload: result.data.data.data });
    } catch (error) {
      console.log(error.message);
    }
};

export const getStates = (data) => async (dispatch) => {
    try {
      const result= await api.fetchStates(data);
  
      dispatch({ type: GET_FILTER_STATES, payload: result.data.data });
      return result.data.data
    } catch (error) {
      console.log(error.message);
    }
};

export const getCities = (data) => async (dispatch) => {
  try {
    const result= await api.fetchCities(data);

    dispatch({ type: GET_FILTER_CITIES, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getInstitute = (data) => async (dispatch) => {
  try {
    const result= await api.fetchInstitute(data);

    dispatch({ type: GET_FILTER_INSTITUTE, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};


export const getFaculty = (data) => async (dispatch) => {
  try {
    const result= await api.fetchFaculty(data);

    dispatch({ type: GET_FACULTY, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getFacultySpeciality = (data) => async (dispatch) => {
  try {
    const result= await api.fetchFacultyByInstSpeciality(data);

    dispatch({ type: GET_FACULTY_SPECIALITY, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getDesignation = (data) => async (dispatch) => {
  try {
    const result= await api.fetchDesignation(data);

    dispatch({ type: GET_DESIGNATION, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getYearOfEperience = (data) => async (dispatch) => {
  try {
    const result= await api.fetchExperience(data);

    dispatch({ type: GET_YEAR_OF_EXPERIENCE, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getQualification = (data) => async (dispatch) => {
  try {
    const result= await api.fetchQualification(data);

    dispatch({ type: GET_QUALIFICATION, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};

export const getRequestType = (data) => async (dispatch) => {
  try {
    const result= await api.fetchRequestType(data);

    dispatch({ type: GET_REQUEST_TYPE, payload: result.data.data });
    return result.data.data
  } catch (error) {
    console.log(error.message);
  }
};


