import React from "react";
import { Container } from "react-bootstrap";
import CustomLoader from "../components/CustomLoader/CustomLoader";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CompleteProfilePopUp from "../components/CompleteProfile/CompleteProfilePopUp";

const ContainerWrapper = ({ children }) => {
  const isLoading = useSelector((state) => state.globalState.globalLoading);
  const isCompleteProfile = useSelector((state) => state.globalState.isCompleteProfile);

  return (
    <section className="py-4">
      <Container>{children}</Container>
      {isLoading ? <CustomLoader /> : null}
      {/* <CompleteProfilePopUp show={!isCompleteProfile}/> */}
    </section>
  );
};

export default ContainerWrapper;
