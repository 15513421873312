import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import CompleteProfilePopUp from "./CompleteProfilePopUp";
import { isProfileComplete } from "../../../redux/actions/global.action";
import { checkProfile } from "../../../redux/actions/auth.action";

const ProfileComplete = () => {
  const dispatch = useDispatch();
  const isCompleteProfile = useSelector(
    (state) => state.globalState.isCompleteProfile
  );

  useEffect(() => {
    dispatch(checkProfile());
    // dispatch(isProfileComplete());
  }, []);

  return <CompleteProfilePopUp show={!isCompleteProfile} />;
};

export default ProfileComplete;
