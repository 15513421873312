import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllObserverShipById,
  getMyObservership,
} from "../../../redux/actions/observership.action";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RESET_OBSERVERSHIP } from "../../../redux/constants/observershipActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { timeAgo, formatDate, formattTime, getStatusFromDate } from "../../../utils/core";

const MyObserverCourse = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const myObservershipList = useSelector(
    (state) => state.observership.myObservership
  );
  console.log("myObservershipList", myObservershipList);
  const pageNo = useSelector((state) => state.observership.page);
  const hasMore = useSelector((state) => state.observership.hasMore);

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getMyObservership({ page: 1 }, true));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_OBSERVERSHIP });
    };
  }, []);

  const fetchObservership = () => {
    dispatch(getMyObservership({ page: pageNo }));
  };

  const handleEdit = (val) => {
    dispatch(getAllObserverShipById({ observershipId: val._id }));
    navigate(`/editObservership/${val._id}`);
  };

  const [headerLabel, setHeaderLable] = useState([
    { title: "Topic", key: "title" },
    { title: "Date", key: "date" },
    // { title: "Speciality", key: "speciality" },
    // { title: "Attendees", key: "attendees" },
    { title: "Observership Fees", key: "fees" },
    { title: "Status", key: "status" },
    // { title: "No. of Registration", key: "registration_count" },
  ]);
  return (
    <Root>
      <Breadcrumb
        title={"Observership"}
        fieldOne="Dashboard"
        fieldTwo="Observership"
        fieldThree="My Uploads"
      />
      <ContainerWrapper>
        <Row className=" mt-2">
          <Col>
            <div>
              <h2 className="h4 mb-1">My Uploads</h2>
              <p>Observership course posted by me</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {myObservershipList.length > 0 ? (
              <Card className="bg-white rounded-3 mb-4">
                <Card.Body className="px-4 py-3">
                  <div className="table-responsive">
                    <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        {headerLabel.map((val, index) => (
                          <th className="p-2">{val.title}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {myObservershipList.map((val, index) => (
                          <tr key={index}>
                            <td class="fw-bold w-50">
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 class="mb-0">{val.observershipName}</h6>
                            </td>
                            {/* <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Speciality:
                              </p>
                              <p class="mb-0">{val.speciality}</p>
                            </td> */}
                            <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date:
                              </p>
                              <p class="mb-0">
                                {moment(val.startDate).format("D MMMM YYYY")} to{" "}
                                <br />{" "}
                                {moment(val.endDate).format("D MMMM YYYY")}
                              </p>
                            </td>
                            {/* <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Attendees:
                              </p>
                              <a
                                href="attendee-list.html"
                                class="mb-0 fw-bold btn btn-link btn-sm text-decoration-underline"
                              >
                                13
                              </a>
                            </td> */}
                            <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Fees:
                              </p>
                              <h6 class="mb-0 fw-bold text-success">
                                {val.observershipFee > 0 ? `Rs.${val.observershipFee}` : "Free"}
                              </h6>
                            </td>
                             {/* <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              <span class="badge badge-info">Upcoming</span>
                            </td> */}
                            <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                              {/* <span class="badge badge-info">{getStatusFromDate(val.startDate, val.endDate)}</span> */}
                            </td>
                            <td class="text-left text-lg-end">
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleEdit(val)}
                                class=" btn btn-info mb-2 btn-sm me-1"
                              >
                                <i class="fa-solid fa-pencil me-1"></i> Edit
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="mb-2 btn btn-link btn-sm"
                                onClick={() => {
                                  navigate(`/observershipDetails/${val._id}`);
                                }}
                              >
                                Details <i class="fa-solid fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchObservership}
          globalState={myObservershipList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default MyObserverCourse;
