import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from 'crypto-js';


import { getMyUploads } from "../../../redux/actions/meeting.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo, getStatusFromDate } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { getMyAssociationCME } from "../../../redux/actions/association.action";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";

const MyAssociationCME = () => {
  let { associationId } = useParams();
  const isInitialMount = useRef(true);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.association.myMeetingLists);
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const encrypted = CryptoJS.SHA1(process.env.REACT_APP_ASSOCIATION_SECRET).toString();

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getMyAssociationCME({ page: 1, assocId: associationId }, true));
  }, []);

  const fetchMeetings = () => {
    dispatch(getMyAssociationCME({ page: pageNo, assocId: associationId }));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  return (
    <Root>
      {/* <Breadcrumb title={"CME (Continuing Medical Education)"} fieldOne="Dashboard" fieldTwo="My Uploaded CME (Continuing Medical Education)" /> */}
      <Breadcrumb
        title={"CME (Continuing Medical Education)"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "My Uploads",
          },
        ]}
      />

      <ContainerWrapper>
        <Row>
          <Col>
            <div className="col-12 d-lg-flex justify-content-lg-between">
              <div>
                <h4 className=" mb-1">My Uploads</h4>
                <p>Total {meetingsList.length} CME (Continuing Medical Education)</p>
              </div>
              <div className=" d-lg-block">
                
                <button
                  title="attendcme"
                  className="btn btn-primary m-2"
                  onClick={() => {
                    navigate(`/AssociationMeeting/${associationId}`);
                  }}
                >
                  Attend CME <i className="fa-solid fa-arrow-right"></i>
                </button>
                <button
                  title="Invitations"
                  className="btn btn-primary m-2"
                  onClick={() => {
                    navigate(`/association-cme-invitation/${associationId}`);
                  }}
                >
                  Invitations <i className="fa-solid fa-arrow-right"></i>
                </button>
                
                <button
                  title="Schedule CME (Continuing Medical Education)"
                  className="btn btn-primary m-2 "
                  onClick={() => {
                    navigate(`/scheduleLiveCmeRtm/${associationId}`);
                  }}
                >
                  Schedule CME (Continuing Medical Education) <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white rounded-3 mb-4">
              <Card.Body className=" px-4 py-3">
                <div className="table-responsive">
                  <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      <th>Topic</th>
                      <th>Date &amp; Time</th>
                      <th>Attendees</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {meetingsList.length > 0 ? (
                        meetingsList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.meetingDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Attendees:
                              </p>
                              <a
                                href="javascript:void(0)"
                                className="mb-0 fw-bold btn btn-link btn-sm text-decoration-underline"
                              >
                                {val.members.length}
                              </a>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                            </td>
                            <td className="text-center text-lg-end">
                              <button
                                // href="/edit-cme-rte/:id"
                                onClick={() => {
                                  navigate(`/edit-association-cme-rte/${val._id}/${associationId}`);
                                }}
                                className=" btn btn-info mb-2 btn-sm"
                              >
                                <i className="fa-solid fa-pencil me-1"></i> Edit
                              </button>
                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}?association=${encrypted}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                      <InfiniteScroll
                        fetchFunction={fetchMeetings}
                        globalState={meetingsList}
                        hasMore={hasMore}
                      />
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default MyAssociationCME;
