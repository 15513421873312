import { GET_ALL_TUTORIAL,
   ADD_TUTORIAL,
   SET_PERCENTAGE,
   MY_UPLOADS_TUTORIAL,
   GET_TUTORIAL_DETAILS,
   LIKE_TUTORIAL,
   DISLIKE_TUTORIAL,
   GET_TYPES,
   RESET_TUTORIAL
  } from "../constants/tutorialActionTypes";

const initialState = {
  tutorialsList: [],
  mytutorialsList: [],
  tutorialDetails: "",
  tutorialTypes: [],
  like: 0,
  progress: 0,
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (tutorial = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TUTORIAL:
      if (action.reset) {
        return {
          ...tutorial,
          tutorialsList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...tutorial,
          tutorialsList: [
            ...tutorial.tutorialsList,
            ...action.payload,
          ],
          page: tutorial.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_TUTORIAL:
      return {
        ...tutorial,
        tutorialsList: action.payload,
      };
    case SET_PERCENTAGE:
      return {
        ...tutorial,
        progress: action.payload,
      };
    case MY_UPLOADS_TUTORIAL:
      return {
        ...tutorial,
        mytutorialsList: action.payload,
        page: 1,
        hasMore: action.hasMore,
      };
    case GET_TUTORIAL_DETAILS:
      return {
        ...tutorial,
        tutorialDetails: action.payload
      };
    case GET_TYPES:
      return {
        ...tutorial,
        tutorialTypes: action.payload
      };
    case LIKE_TUTORIAL:
      if (action.payload.success) {
        return {
          ...tutorial,
          like: 1
        };
      }
    case DISLIKE_TUTORIAL:
      if (action.payload.success) {
        return {
          ...tutorial,
          like: -1
        };
      }
      case RESET_TUTORIAL:
        return{
          ...initialState
        }
    default:
      return tutorial;
  }
};
