import React from 'react';
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import logo from "../../../img/logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OTPLogin } from "../../../redux/actions/auth.action"
import SuccessPopup from "../../components/Popup/SuccessPopup"

const OtpLogin = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const isForgotSuccess = useSelector((state) => state.auth.isForgot);
    
    const validation = Yup.object({
        email: Yup.string().required("Enter your email or mobile no")
    });

    const initialValues = {
        email: ""
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: (values, action) => {
            const payload = { 
                contact: values.email
            }
            dispatch(OTPLogin(payload, navigate));
            action.resetForm();
        }
    });

    return (

        <main className="main pt-0 bg-light">
            <section className="py-4">
                <ContainerWrapper>
                    {isForgotSuccess ? (
                        <SuccessPopup 
                            message={"Reset password link has been sent to registered mobile number and email."}
                            isButton={false}
                        />
                    ) : (
                      <Row>
                        <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
                            <Card className="bg-white rounded-3 mb-4 shadow-lg">
                                <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                                    <Col className="text-center">
                                        <img src={logo} alt="Logo" className="mb-4" />
                                        <div>
                                            <h3 className="mb-1">Login using OTP</h3>
                                            <p></p>
                                            {/* <p>Enter your registered email ID OR mobile No.</p> */}
                                        </div>
                                    </Col>
                                </Card.Header>
                                <Card.Body className="pt-2 px-4 pb-4">
                                    <Form class="row g-3 g-lg-4" onSubmit={handleSubmit}>
                                       <Col className='mx-md-4'>
                                            <Form.Label for="">
                                                Registered Email ID or Mobile No<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control 
                                                className='form-control'
                                                type="text"
                                                autoComplete="off"
                                                name="email"
                                                id="email"
                                                // placeholder="Email or Mobile"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.email && touched.email ? (
                                                <p className="form-error" style={{ color: "red", fontSize: "12px"}}>{errors.email}</p>
                                            ) : null}
                                        </Col>
                                        <Col md={12} className=" text-center mt-3">
                                            <Button  type="submit" class="btn btn-primary btn-lg mb-2">Submit <i class="fa-solid fa-arrow-right"></i></Button>
                                        </Col>
                                    </Form>
                                </Card.Body>
                                <hr className="mb-0" />
                            </Card>
                        </Col>
                    </Row>
                    )}

                </ContainerWrapper>
            </section>
        </main>

    )
}

export default OtpLogin