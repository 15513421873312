import {
  ADD_OBSERVERSHIP,
  GET_ALL_OBSERVERSHIP,
  GET_ALL_OBSERVERSHIP_BY_FILTER,
  GET_ALL_OBSERVERSHIP_BY_ID,
  GET_MY_OBSERVERSHIP,
  REGISTERED_OBSERVERSHIP,
  RESET_OBSERVERSHIP,
  SET_EDIT_OBSERVERSHIP_ID,
} from "../constants/observershipActionTypes";

const initialState = {
  observershipList: [],
  myObservership: [],
  registeredObservership: [],
  editObsevershipId: "",
  observershipById: "",
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (observership = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OBSERVERSHIP:
      return {
        ...observership,
        observershipList: action.payload,
      };
    case GET_ALL_OBSERVERSHIP_BY_FILTER:
      if (action.reset) {
        return {
          ...observership,
          observershipList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...observership,
          observershipList: [
            ...observership.observershipList,
            ...action.payload,
          ],
          page: observership.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_OBSERVERSHIP:
      return {
        ...observership,
        observershipList: action.payload,
      };
    case GET_MY_OBSERVERSHIP:
      if (action.reset) {
        return {
          ...observership,
          myObservership: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...observership,
          myObservership: [...observership.myObservership, ...action.payload],
          page: observership.page + 1,
          hasMore: action.hasMore,
        };
      }
    case SET_EDIT_OBSERVERSHIP_ID:
      return {
        ...observership,
        editObsevershipId: action.payload,
      };
    case REGISTERED_OBSERVERSHIP:
      return {
        ...observership,
        registeredObservership: action.payload,
      };
    case GET_ALL_OBSERVERSHIP_BY_ID:
      return {
        ...observership,
        observershipById: action.payload,
      };

      case RESET_OBSERVERSHIP:
      return{
        ...initialState
      }
    default:
      return observership;
  }
};
