import React, { useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import moment from "moment";
import { formatDate, formattTime, getStatusFromDateString, isMeetingEnd } from "../../../utils/core";
import DetailsComponent from "../../components/DetailsComponent/DetailsComponent";
import { useParams } from "react-router-dom";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { getAllConferenceShipById } from "../../../redux/actions/conference.action";

const ConfDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const ConferenceById = useSelector(
    (state) => state.conference.ConferenceById
  );

  const [infoArray, setInfoArray] = useState([]);
  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getAllConferenceShipById({ id: id }));
  }, []);

  useEffect(() => {
    if (ConferenceById) {
      setInfoArray([
        { name: "Speciality", value: ConferenceById.speciality.specialityName },
        { name: "Subspeciality", value: ConferenceById.subSpeciality },
        { name: "Slots Available", value: ConferenceById.slot },
        {
          name: "Date",
          value: `${formatDate(ConferenceById.conferenceDate)} from ${formattTime(
            ConferenceById.startTime
          )} to ${formattTime(
            ConferenceById.endTime
          )}`,
        },
      ]);
    }
  }, [ConferenceById]);


  const [currentUser, setCurrentUser] = useState(localStorage.getItem('userId'))

  const registerNow = (url) => {
    window.open(url, '_blank');
    // dispatch(registerToConference({ id: id }));
  }

  
  const renderFooterButton = () => {
    if(!isMeetingEnd(ConferenceById?.conferenceEndDate)){
      if (currentUser == ConferenceById?.user_details?._id) {
           return <span class={`badge bg-cme mb-2`}>Host</span>;
      } else {
        return (
          <>
            <button
              className=" btn btn-primary mb-2 me-1"
              onClick={() => registerNow(ConferenceById?.url)}
            >
              Register Now <i className="fa-solid fa-arrow-right"></i>
            </button>
          </>
        );
      }
    } else {
      return <span class={`badge bg-cme mb-2`}>Ended</span>;
    }
  };

  return (
    <Root>
      <Breadcrumb
        title={"Conference / Workshop"}
        fieldOne="Dashboard"
        fieldTwo="Conference / Workshop"
        fieldThree="Conference Details"
      />
      <ContainerWrapper>
        <DetailsComponent
        hideThumbNail={true}
        hideShareField={true}
          title={ConferenceById?.title}
          infoArray={infoArray}
          module="workshop"
          badgeClassName="bg-workshop"
          detailsTitle="Workshop details:"
          detailsDescription={ConferenceById?.description}
          facultyTitle={"Faculty"}
          facultyName={ConferenceById?.facultyName}
          facultyInstitution={ConferenceById?.institution?.instituteName}
          fee={ConferenceById?.fees}
          fileUrl={ConferenceById?.brochure}
          buttonText="Register Now"
          confUrl={ConferenceById?.url}
          buttonAction={(url) => { registerNow(url) }}
          venue={ConferenceById?.venue}
          location={ConferenceById?.country?.state?.city.cityName + "," + ConferenceById?.country?.state?.stateName + "," + ConferenceById?.country?.countryName}
          isHost={ ConferenceById?.user_details?._id == currentUser ? true : false }
          footerButton={renderFooterButton()}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default ConfDetails;
