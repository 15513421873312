import { toast } from "react-toastify";
import {
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  MARK_READ_NOTIFICATION,
  NOTIFICATION_COUNT
} from "../constants/notificationTypes";
import * as api from "../../services/api";

export const getNotification = () => async (dispatch) => {
  try {
    const result = await api.getNotification();
    console.log("result", result)

    dispatch({ type: GET_NOTIFICATIONS, payload: result.data.data.notifications});
    dispatch({ type: NOTIFICATION_COUNT, payload: result.data.data.message_count});
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllNotification =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllNotification(data);

      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
        notificationCount:result.data.data.totalDocs
      });
    } catch (error) {
      console.log(error.message);
    }
  };

export const markAsRead = (data) => async (dispatch) => {
  try {
    const result = await api.markAsReadNotification(data);

    dispatch({ type: MARK_READ_NOTIFICATION, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
