import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { myConference } from "../../../redux/actions/conference.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo, formatDate, formattTime, getStatusFromDate } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_CONFERENE } from "../../../redux/constants/conferenceActionTypes"

const MyConfWork = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const conferenceList = useSelector((state) => state.conference.myConference);
  const pageNo = useSelector((state) => state.conference.page);

  useEffect(() => {

    dispatch({ type: SET_LOADING });

    // dispatch(myConference({ page: pageNo }));
    dispatch(myConference({ page: 1 }));
  }, []);
  

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CONFERENE });
    };
  }, []);
  

  const handleEdit = (val) => {
    navigate(`/editConfWorkshop/${val._id}`);
  };

  const [headerLabel, setHeaderLable] = useState([
    { title: "Title", key: "title" },
    { title: "Speciality", key: "speciality" },
    { title: "Date & Time", key: "dateTime" },
    // { title: "Attendees", key: "attendees" },
    // { title: "Fee", key: "fee" },
    { title: "Status", key: "status" },
  ]);
  return (
    <Root>
      <Breadcrumb
        title={"Conference / Workshop"}
        fieldOne="Dashboard"
        fieldTwo="My Upload of Conference/ Workshop / Hands on training"
      />

      <ContainerWrapper>
        <Row>
          <Col>
            <div>
              <h4 className=" mb-1">My Upload of Conference/ Workshop / Hands on training</h4>
              <p>
                Total {conferenceList.length} Conference, Workshop and Hands on
                Trainings
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white rounded-3 mb-4">
              <Card.Body className=" px-4 py-3">
                {conferenceList.length > 0 ? (
                <div className="table-responsive">
                  
                  <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      {headerLabel.map((val, index) => (
                        <th className="p-2">{val.title}</th>
                      ))}
                      <th className="text-end">&nbsp;</th>
                    </thead>
                    <tbody>
                      
                        { conferenceList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Title:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Speciality:
                              </p>
                              <p className="mb-0">
                                {val.speciality.specialityName}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {/* {formatDate(val.conferenceDate)}
                                <br />
                                {`${formattTime(
                                  val.startTime
                                )} to ${formattTime(val.endTime)}`} */}
                                {moment(val?.conferenceDate).format("D MMMM YYYY")}{" "}
                                <br /> to{" "}
                                {moment(val?.conferenceEndDate).format("D MMMM YYYY")}
                              </p>
                            </td>
                            {/* <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Attendees:
                              </p>
                              <a
                                href="javascript:void(0)"
                                className="mb-0 fw-bold btn btn-link btn-sm text-decoration-underline"
                              >
                                {val.totalMembers}
                              </a>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Fees:
                              </p>
                              <h6 className="mb-0 fw-bold text-success">
                                {val.fees}
                              </h6>
                            </td> */}
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.conferenceDate, val.conferenceEndDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                              {/* <span className="badge badge-info">{getStatusFromDate(val.conferenceDate, val.conferenceEndDate)}</span> */}
                            </td>
                            <td className="text-left text-lg-end">
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleEdit(val)}
                                class=" btn btn-info mb-2 btn-sm me-1"
                              >
                                <i class="fa-solid fa-pencil me-1"></i>Edit
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="mb-2 btn btn-link btn-sm"
                                onClick={() => {
                                  navigate(`/confDetails/${val._id}`);
                                }}
                              >
                                Details <i class="fa-solid fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        )) }

                     
                    </tbody>
                  </Table>
                   
                </div>
                ) : (
                    <NoDataFound />
                  )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default MyConfWork;
