import React from "react";

const NoDataFound = ({title,description}) => {
  return (
    <div className="text-center">
      <img
        height={250}
        src={require("../../../img/nodata.png")}
        alt="No Data"
        className="mb-3"
      />
      <h3 className="text-muted">{title}</h3>
      <p className="text-muted">{description}</p>
    </div>
  );
};

NoDataFound.defaultProps = {
  title: "No Data Found",
  description: "There is no data available for the current filter",
};

export default NoDataFound;


