import React, { useEffect } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Col, Row, Card } from "react-bootstrap";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { getPatientRefferralReceived } from "../../../redux/actions/patientReferral.action";
import { useNavigate } from "react-router-dom";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";

const ReferalsReceived = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(
    (state) => state.patientReferral.refferalReceived
  );
  const pageNo = useSelector((state) => state.patientReferral.page);
  const hasMore = useSelector((state) => state.patientReferral.hasMore);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(getPatientRefferralReceived({ page: 1 }, true));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_PATIENT_DATA });
    };
  }, []);

  const fetchPatient = () => {
    dispatch(getPatientRefferralReceived({ page: pageNo }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"My Referrals"}
        fieldOne="Dashboard "
        fieldTwo="Patient Referral"
        fieldThree="Patient Referrals Received"
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Patient Referrals Received</h4>
              <p>Total {patientList?.length} patients referrals received.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white card rounded-3 mb-4">
              {patientList?.length > 0 ? (
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Referring Surgeon</th>
                        <th>Patient Details</th>
                        <th>Patient Contacts</th>
                        <th width="400">Profile</th>
                      </thead>
                      <tbody>
                        {patientList.map((val) => (
                          <tr>
                            <td className="">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Referring Surgeon:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap me-2">
                                  <img
                                    src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.user.profilePicture}`}
                                    alt="Surgeon Name"
                                    width="44"
                                  />
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <a
                                      onClick={() =>
                                        navigate(
                                          `/surgeon-profile/${val.user?._id}`
                                        )
                                      }
                                      href="javascript:void(0)"
                                    >
                                      {val.user.fullName}
                                    </a>
                                  </p>
                                  <p className="text-muted mb-0">
                                    {val.user.institution.instituteName}
                                  </p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Patient Name:
                              </p>
                              <p className="fw-bold mb-0">{val.name}</p>
                              <p className="text-muted mb-0">
                                Age {val.age}, {val.gender}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Patient Contacts:
                              </p>
                              <p className="mb-0">{val.contact_number}</p>

                              <p className="mb-0">johndoe@gmail.com</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Designation:
                              </p>
                              <p className="mb-0">{val.profile}</p>
                            </td>
                          </tr>
                        ))}
                        <InfiniteScroll
                          fetchFunction={fetchPatient}
                          globalState={patientList}
                          hasMore={hasMore}
                        />
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              ) : (
                <NoDataFound />
              )}
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default ReferalsReceived;
