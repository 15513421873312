import React, { useState, useEffect } from 'react';
import Root from '../../components/Root';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Details from '../../components/Details/Details';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { formatDate } from '../../../utils/core';

import { getTutorialDetails } from "../../../redux/actions/tutorial.action";
import NoDataFound from "../../components/NoDataFound/NoDataFound";



const TutorialDetails = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const videoData = useSelector((state) => state.tutorial.tutorialDetails);

    console.log(id)
    console.log("videoData", videoData)

    useEffect(() => {
        dispatch(getTutorialDetails({ id: id }));
    }, []);




    return (
        <Root>
            <Breadcrumb
                title={"Tutorial Details"}
                fieldOne="Dashboard"
                fieldTwo="Browse Tutorials"
                fieldThree="Tutorial Detail"
            />
            <ContainerWrapper>
                {videoData ? (
                    <Details
                        tag={"Tutorial"}
                        title={videoData.title}
                        patientDetails={videoData.description}
                        discriptionName="Description"
                        speciality={videoData.speciality}
                        comments={videoData.comments}
                        createDate={formatDate(videoData.createdAt)}
                        likes={videoData.likes}
                        totalComments={videoData.totalComments}
                        totalLikes={videoData.totalLikes}
                        totalviews={videoData.views}
                        userDetails={videoData.user_details}
                        url={videoData.url}
                        urlType={videoData.urlType}
                        videoId={videoData._id}
                    />
                ) : (
                    <NoDataFound />
                )}
            </ContainerWrapper>
        </Root>
    )
}

export default TutorialDetails;