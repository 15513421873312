import React, { useEffect, useRef, useState } from "react";
import Root from "../../../components/Root";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo } from "../../../../redux/actions/recorded.conference.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

const UploadSurgeryVideo = () => {
  let { associationId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const requestData = new FormData();

    requestData.append("title", values.title);
    requestData.append("conferenceDate", values.conferenceDate);
    requestData.append("attachmentFile", file);
    requestData.append("associationId", associationId);

    dispatch(uploadVideo(requestData, navigate)).then((res) => {
      setSubmitting(false);
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
    });

    // Reset the form after submission
    // setFile(null);
    // resetForm();
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required("Conference title is required"),
    conferenceDate: Yup.date().required("Conference Date is required"),
    agreement1: Yup.boolean().oneOf([true], "You must confirm the data"),
    attachment: Yup.mixed().when("type", {
      is: "video",
      then: Yup.mixed().required("File is required"),
      otherwise: Yup.mixed(),
    })
  });

  return (
    <Root>
      {/* <Breadcrumb
        title={"Recorded Conference"}
        fieldOne="Dashboard"
        fieldTwo="Upload Your Recorded Conference"
      /> */}
       <Breadcrumb
        title={"Recorded Live Surgery / Web CME"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Upload Your Recorded Live Surgery / Web CME",
          },
        ]}
      />
      <ContainerWrapper>
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Upload Your Recorded Live Surgery / Web CME</h2>
                    <p>
                      Fill in the below details and upload Recorded Live Surgery / Web CME. Fields
                      with <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={{
                    title: "",
                    conferenceDate: "",
                    attachment: "",
                    agreement1: false
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => 
                  {    console.log('Formik Values:', formik.values);

                    return (
                      <Form className="row g-3 g-lg-4">

                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Conference Title
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            type="text"
                            name="title"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Conference Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="conferenceDate"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="conferenceDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                        <Col md={12}>
                          <ReactForm.Label>
                            Select Video File
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <div className="input-group">
                            <input
                              type="file"
                              name="attachment"
                              className="form-control"
                              onChange={(e) => {
                                handleFileInputChange(e);
                                formik.setFieldValue('attachment', e.currentTarget.files[0]); // Set the value for Formik
                              }}
                              ref={fileInputRef}
                            />
                          </div>
                          <ErrorMessage
                            name="attachment"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              name="agreement1"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="agreement1"
                            >
                              I confirm, patient’s identity has not been revealed
                              directly or indirectly in the video.
                            </label>
                            <ErrorMessage
                              name="agreement1"
                              component="div"
                              className="text-danger text-small"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          Note: Video uploaded will be published on website within
                          two working days, post basic check list.
                        </Col>
                        <Col className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            Post{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Col>
                      </Form>
                    )
                  }
                  }
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default UploadSurgeryVideo;
