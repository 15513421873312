import React from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";

const PersonalInformation = () => {
  return (
    <Root>
      <Breadcrumb
        title={"Personal Information"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Personal Information",
          },
        ]}
      />
      <ContainerWrapper>
        <div className="row d-flex align-items-stretch">
          <div className="col-lg-5 col-xl-4 d-flex align-items-stretch">
            <div className="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
              <div className="card-body px-4 py-4">
                <div className="">
                  <div className="mb-3">
                    <div className="author d-flex align-items-center">
                      <span className="user-image-wrap user-image-lg me-md-3 mb-3 mb-md-0">
                        <img
                          src="assets/images/surgeon-image.png"
                          alt="Surgeon Name"
                        />
                      </span>
                      <span className="author-details">
                        <h2 className="h5 fw-bold mb-1">Dr. John Doe</h2>
                        <p className="mb-2 text-muted">
                          Professor &amp; HOD, General Surgery
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="col text-start pt-1">
                    <p className="mb-2">
                      <strong>Email:</strong> johndoe@gmail.com
                    </p>
                    <p className="mb-2">
                      <strong>Phone:</strong> 9804959495
                    </p>
                    <p className="">
                      <strong>Location:</strong> Manipal, Karnataka, India
                    </p>
                  </div>
                </div>
                <hr />
                <div class="d-grid gap-2 profile-sidebar-links">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light bg-white border-0 btn-lg text-start "
                  >
                    <i class="fas fa-user text-primary me-2"></i> Public Profile
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light bg-white border-0 btn-lg text-start "
                  >
                    <i class="fas fa-user-shield text-primary me-2"></i>{" "}
                    Personal Information
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light bg-white border-0 btn-lg text-start"
                  >
                    <i class="fas fa-user-friends text-primary me-2"></i>{" "}
                    Patient Referral Profile
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light bg-white border-0 btn-lg text-start active"
                  >
                    <i class="fas fa-cog text-primary me-2"></i> Change Password{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 d-flex align-items-stretch">
            <div className="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
              <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
                <div className="col-12 ">
                  <div>
                    <h2 className="h4 mb-1">Personal Information</h2>
                    <p>
                      Update your personal information. Fields with{" "}
                      <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body pt-1 px-4 py-4">
                <form className="row g-3 g-lg-4">
                  <div className="col-md-6">
                    <label for="" className="form-label">
                      Mobile<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder=" "
                    />
                  </div>
                  <div className="col-md-6">
                    <label for="" className="form-label">
                      Email ID<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder=" "
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label for="" className="form-label">
                      Telementorship Fees<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder=" "
                      />
                      <span className="input-group-text">per hour</span>
                      <span className="input-group-text fw-bold">
                        + 18% GST
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label for="" className="form-label">
                      Online Class Fees<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder=" "
                      />
                      <span className="input-group-text">per hour</span>
                      <span className="input-group-text fw-bold">
                        + 18% GST
                      </span>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <label for="" className="form-label">
                      Follow Subspeciality<span className="text-danger">*</span>
                    </label>
                    <div className="input-group d-flex align-items-stretch">
                      <select id="" className="form-select">
                        <option selected="">Select</option>
                        <option>...</option>
                      </select>
                      <div className="input-group-btn d-flex align-items-stretch">
                        <button className="btn btn-primary">
                          <i className="fa-solid fa-check me-1"></i> Follow
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label for="" className="form-label d-block">
                      You are following:
                    </label>
                    <label className="badge bg-light badge-lg font-weight-bolder text-primary mb-2 me-2 p-2 rounde-3">
                      Pediatric Oncology &nbsp;{" "}
                      <a>
                        <span className="text-danger fs-6">
                          <i className="fa-solid fa-circle-xmark"></i>
                        </span>
                      </a>
                    </label>
                    <label className="badge bg-light badge-lg font-weight-bolder text-primary mb-2 me-2 p-2 rounde-3">
                      Pediatric Oncology &nbsp;{" "}
                      <a>
                        <span className="text-danger fs-6">
                          <i className="fa-solid fa-circle-xmark"></i>
                        </span>
                      </a>
                    </label>
                    <label className="badge bg-light badge-lg font-weight-bolder text-primary mb-2 me-2 p-2 rounde-3">
                      Pediatric Oncology &nbsp;{" "}
                      <a>
                        <span className="text-danger fs-6">
                          <i className="fa-solid fa-circle-xmark"></i>
                        </span>
                      </a>
                    </label>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mb-2 btn-lg"
                    >
                      <i className="fa-solid fa-check me-1"></i>
                      Update Personal Profile
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default PersonalInformation;
