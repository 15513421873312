import React, { useEffect } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Col, Row, Card } from "react-bootstrap";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients } from "../../../redux/actions/registry.action";
import { useNavigate, Link } from "react-router-dom";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";

const ViewPatients = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(
    (state) => state.registry.patientList
  );
  const pageNo = useSelector((state) => state.patientReferral.page);
  const hasMore = useSelector((state) => state.patientReferral.hasMore);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(getAllPatients({ page: 1 }, true));
  }, []);


  return (
    <Root>
      <Breadcrumb
        title={"Patient Registry"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "View Old Patient",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">View Old Patient</h4>
              <p>Total {patientList?.length} patients.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white card rounded-3 mb-4">
              {patientList?.length > 0 ? (
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Patient Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Mobile No.</th>
                        <th>Email ID</th>
                        
                      </thead>
                      <tbody>
                        {patientList.map((val) => (
                          <tr>
                            
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Patient Name:
                              </p>
                              <p className="fw-bold mb-0">{val.full_name}</p>

                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                               Age:
                              </p>
                              <p className="fw-bold mb-0">{val.age}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                               Gender:
                              </p>
                              <p className="fw-bold mb-0">{val.gender}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Mobile:
                              </p>
                              <p className="mb-0">{val.mobile}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Email:
                              </p>
                              <p className="mb-0">{val.email}</p>
                            </td>
                            <td>
                              <Link to={`/add-patient-procedure/${val._id}`} title="View Old Patients" class="btn btn-primary mt-2">Add Procedure <i class="fa-solid fa-arrow-right"></i></Link>
                              <Link to={`/track-patient-procedure/${val._id}`} title="View Report" className="mb-2 btn btn-link btn-sm">View Report <i class="fa-solid fa-arrow-right"></i></Link>
                             
                            </td>
                          </tr>
                        ))}
                        
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              ) : (
                <NoDataFound />
              )}
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default ViewPatients;
