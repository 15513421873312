import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getAllConferenceByFilter, registerToConference } from "../../../redux/actions/conference.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";


const BrowseConfWork = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const conferenceList = useSelector(
    (state) => state?.conference.conferenceList
  );
  const pageNo = useSelector((state) => state?.conference.page);
  const hasMore = useSelector((state) => state?.conference.hasMore);

  const selectedFilter = useSelector((state) => state?.filters.selectedFilters);
  useEffect(() => {
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    if (isInitialMount.current) {
      dispatch(getAllConferenceByFilter({ ...selectedFilter, page: pageNo }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllConferenceByFilter({ ...selectedFilter, page: 1 }, true));
    }
  }, [selectedFilter]);

  const fetchConference = () => {
    dispatch(getAllConferenceByFilter({ ...selectedFilter, page: pageNo }));
  };

  const registerNow = (url) => {
    window.open(url, '_blank');
    // dispatch(registerToConference({ id: id }));
  }

  const [currentUser, setCurrentUser] = useState(localStorage.getItem('userId'))


 
  return (
    <Root>
      <Breadcrumb
        title={"Conference / Workshop"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Browse Conference / Workshop",
          }
        ]}
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse upcoming conference/ workshop/ Hands on training.</h4>
              <p>Browse Conference, Workshop and Hands on Training in your field</p>
            </div>
            <div className="d-lg-block">
              <Button
                title="Post Conference Workshop & Hands On Training"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/postConfWorkshop")
                }}
              >
                Post Conference Workshop & Hands On Training {" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>
          <Filters title="Filter Conference" module="conference" />
        </div>
        {/* <Row>
          {conferenceList.length > 0 ? (
            conferenceList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-workshop"
                  badgeTitle="WORKSHOP"
                  feeAmount={`Rs. ${val?.fees}`}
                  feeText="Starting from"
                  cardTitle={val?.title}
                  duration={`${moment(val?.startDate).format(
                    "D MMMM YYYY"
                  )} to ${moment(val?.endDate).format("D MMMM YYYY")}`}
                  location={`${val?.country?.state?.city?.cityName}, ${val?.country?.state?.stateName}, ${val?.country?.countryName}`}
                  doctorName={val?.user_details.firstName}
                  doctorAddress={val?.user_details.institution.instituteName}
                  buttonTitle="RegisterNtow"
                  buttonAction={registerNow}
                  id={val?._id}
                  detailsLink={`/confDetails/${val?._id}`}
                  isHost={false}

                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row> */}
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {conferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Venue</th>
                        <th>Posted by</th>
                        {/* <th>Fee</th> */}
                        {/* <th>Status</th> */}
                      </thead>
                      <tbody>
                        {conferenceList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6
                                // onClick={() =>
                                //   navigate(`/confDetails/${val?._id}`)
                                // }
                                className="mb-0"
                              >
                                {val?.title}
                              </h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                
                                {moment(val?.conferenceDate).format("D MMMM YYYY")}{" "}
                                <br /> to{" "}
                                {moment(val?.conferenceEndDate).format("D MMMM YYYY")}
                              </p>
                            </td>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Venue:
                              </p>
                               <p className="text-muted mb-0">
                                {val?.venue} <br />{" "}
                                {val?.country?.state?.city?.cityName} {" "}
                                {/* {val?.country?.state?.stateName},{" "} */}
                                {val?.country?.countryName}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Posted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  {val?.user_details?.profilePicture ? (
                                      <img
                                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.user_details?.profilePicture}`}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../img/user.png")}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    )}
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <Link to={`/surgeon-profile/${val?.user_details._id}`}>Dr. {val?.user_details.firstName}</Link>
                                  </p>
                                  <p className="text-muted mb-0">
                                    {/* {val?.user_details.institution.instituteName} <br />{" "} */}
                                    {val?.user_details.country?.state?.city?.cityName}, {val?.user_details.country?.state?.stateName},{" "}
                                    {val?.user_details.country?.countryName}
                                  </p>
                                </span>
                              </div>
                            </td>
                            {/* <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Fee
                              </p>
                              <p>Starting from</p>
                               <h6 className="text-success">{val?.fees}</h6>

                            </td> */}
                            <td className="text-left text-lg-end">
                              {/* <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p> */}

                               {/* {val?.user_details._id != currentUser ? (
                                    <Button
                                      className="btn-primary mb-2"
                                      onClick={() => registerNow(val?.url)}
                                    >
                                      {val?.transaction_status === "COMPLETED"
                                        ? "Registered"
                                        : val?.transaction_status === "PROCESSING"
                                        ? "Processing"
                                        : "Register Now"}{" "}
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </Button>
                                  
                                ): (
                                  <span class={`badge bg-cme mb-2`}>Host</span>
                                )} */}

                                <Button
                                  className="btn-primary mb-2"
                                  style={{width: "90px"}}
                                  onClick={() => navigate(`/confDetails/${val?._id}`)}
                                >
                                  View Details <i className="fa-solid fa-arrow-right"></i>
                                </Button>
                                
                                {/* <Link to={`/confDetails/${val?._id}`} className="mb-2 btn btn-link btn-sm">
                                Details <i class="fa-solid fa-arrow-right"></i>
                              </Link> */}

                              {/* {["COMPLETED", "PROCESSING"].includes(
                                val?.transaction_status
                              ) ? (
                                <span
                                  className={`badge mb-2 ${
                                    val?.transaction_status === "COMPLETED"
                                      ? "bg-success"
                                      : "bg-info"
                                  }`}
                                >
                                  {val?.transaction_status === "COMPLETED"
                                    ? "Registered"
                                    : val?.transaction_status === "PROCESSING"
                                    ? "Processing"
                                    : "Register Now"}
                                </span>
                              ) : (
                               null
                              )} */}
                            </td>

                          
                          </tr>
                        ))}
                        <div className="d-flex">
                          <InfiniteScroll
                            fetchFunction={fetchConference}
                            globalState={conferenceList}
                            hasMore={hasMore}
                          />
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
        {/* <InfiniteScroll
          fetchFunction={fetchConference}
          globalState={conferenceList}
          hasMore={hasMore}
        /> */}
      </ContainerWrapper>
    </Root>
  )
}

export default BrowseConfWork