import React from 'react'
import Root from '../../components/Root'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import ContainerWrapper from '../../layouts/ContainerWrapper'

const ChangePassword = () => {
    return (
        <Root>
            <Breadcrumb
                title={"Change Password"}
                items={[
                    {
                        name: "Dashboard",
                        routeTo: "/dashboard",
                    },
                    {
                        name: "Change Password",
                    }
                ]}
            />
            <ContainerWrapper>
                <div class="row d-flex align-items-stretch">
                    <div class="col-lg-5 col-xl-4 d-flex align-items-stretch">
                        <div class="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
                            <div class="card-body px-4 py-4">
                                <div class="">
                                    <div class="mb-3">
                                        <div class="author d-flex align-items-center">
                                            <span class="user-image-wrap user-image-lg me-md-3 mb-3 mb-md-0"><img
                                                src="assets/images/surgeon-image.png" alt="Surgeon Name" /></span>
                                            <span class="author-details">
                                                <h2 class="h5 fw-bold mb-1">Dr. John Doe</h2>
                                                <p class="mb-2 text-muted">Professor &amp; HOD, General Surgery</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col text-start pt-1">
                                        <p class="mb-2"><strong>Email:</strong> johndoe@gmail.com</p>
                                        <p class="mb-2"><strong>Phone:</strong> 9804959495</p>
                                        <p class=""><strong>Location:</strong> Manipal, Karnataka, India</p>
                                    </div>
                                </div>
                                <hr />
                                <div class="d-grid gap-2 profile-sidebar-links">
                                    <a href="my-profile.html" class="btn btn-light bg-white border-0 btn-lg text-start "><i
                                        class="fas fa-user text-primary me-2"></i> Public Profile</a>
                                    <a href="personal-profile.html" class="btn btn-light bg-white border-0 btn-lg text-start "><i
                                        class="fas fa-user-shield text-primary me-2"></i> Personal Information</a>
                                    <a href="patient-referral-profile.html" class="btn btn-light bg-white border-0 btn-lg text-start"><i
                                        class="fas fa-user-friends text-primary me-2"></i> Patient Referral Profile</a>
                                    <a href="change-password.html" class="btn btn-light bg-white border-0 btn-lg text-start active"><i
                                        class="fas fa-cog text-primary me-2"></i> Change Password </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-8 d-flex align-items-stretch">
                        <div class="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
                            <div class="card-header bg-white border-0 px-4 pt-4 pb-0">
                                <div class="col-12 ">
                                    <div>
                                        <h2 class="h4 mb-1">Change Password</h2>
                                        <p>Enter your old and new password to change your password. Fields with <span
                                            class="text-danger">*</span> are mandatory.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-1 px-4 py-4">
                                <form class="row g-3 g-lg-4">
                                    <div class="col-md-12">
                                        <label for="" class="form-label">Old Password<span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="" placeholder=" " />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="" class="form-label">New Password<span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="" placeholder=" " />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="" class="form-label">Re-Enter New Password<span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="" placeholder=" " />
                                    </div>
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary mb-2 btn-lg"><i class="fa-solid fa-check me-1"></i>
                                            Change Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerWrapper>
        </Root>
    )
}

export default ChangePassword