import {
    GET_ALL_DASHBOARD,
} from "../constants/dashboardActionTypes";

const initialState = {
    dashboardList: [],
    isLoading: false,
    page: 1,
    hasMore: true,
};

export default (dashboard = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DASHBOARD:
            return {
                ...dashboard,
                dashboardList: action.payload,
            };
        default:
            return dashboard;
    }
};
