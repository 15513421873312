import { toast } from "react-toastify";
import * as api from "../../services/api";
import { doPayment } from "../../utils/payment";
import { GET_ALL_DASHBOARD } from "../constants/dashboardActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const getAllDashboard = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    var result = await api.getAllDashboard();
    const response = await api.getAssociationMeeting();

    if(response.data.data.data.length > 0) {
      if(response.data.data.data[0].meetings.length > 0) {
        result.data.data.data[0].matchedMeetings = [...result.data.data.data[0].matchedMeetings, ...response.data.data.data[0].meetings]
      }
      if(response.data.data.data[0].liveSurgeries.length > 0) {
        result.data.data.data[0].matchedLiveSurgeries = [...result.data.data.data[0].matchedLiveSurgeries, ...response.data.data.data[0].liveSurgeries]
      }
    }

    dispatch({ type: GET_ALL_DASHBOARD, payload: result.data.data.data[0] });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};
