import React, { useEffect } from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { getAllGalleries } from "../../../redux/actions/home.action";
import { useDispatch, useSelector } from "react-redux";
import "../../../globalstyles/css/lightbox.css"
import "../../../globalstyles/js/lightbox.js"

const Gallery = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch();
  
  const galleryList = useSelector((state) => state.home.galleryList);

  useEffect(() => {
      // window.scrollTo(0, 0);
      dispatch(getAllGalleries({ page: 1 }));
  }, []);

  return (
    <PublicRoot>
      <Breadcrumb
        title={"Gallery"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Gallery",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="title-meta mt-0 mb-0">
                Photos and Videos <span className="line"></span>
              </p>
              <h2 className="h4 mb-2">Our Gallery</h2>
              <p className="">
                Latest photos from Surgicshare presentation and events.
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-stretch">
            {galleryList.length > 0 ? (
              galleryList.map((val, index) => (
                <div className="col-6 col-sm-4 col-lg-3 col-xl-2 h-50">
                  <a
                    href={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.photo}`}
                    // to="gallery-details"
                    data-lightbox="gallery"
                    data-title={val.title}
                    className="mb-4 d-inline-block"
                  >
                    <img
                      src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.photo}`}
                      className="img-fluid rounded-3"
                      style={{ height: '200px', width:'200px' }}
                    />
                  </a>
                </div>
              ))
            ) : (
              <NoDataFound />
            )}
            
          </div>

          <div className="col-12">
            <div className="text-center">
              <a
                href="javascript:void(0)"
                title="Sign Up"
                className="btn btn-primary"
                onClick={() => navigate("/register")}
              >
                Join Surgic Share <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS/>
    </PublicRoot>
  );
};

export default Gallery;
