import React from "react";
import "./Loader.css";

const CustomLoader = () => {
  return (
    <div className="custom-loader-wrapper">
      <div className="custom-loader-container">
        <div className="custom-circle-container">
          <div className="custom-circle"></div>
          <div className="custom-circle"></div>
          <div className="custom-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default CustomLoader;
