import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Col, Row, Card } from "react-bootstrap";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { getPatientRefferralReceived, getProfileInfo, makePayment, updateSchedule } from "../../../redux/actions/patientReferral.action";
import { useNavigate } from "react-router-dom";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { timeFormatt, digitToWord } from "../../../utils/core";

import {
  getCities,
  getCountries,
  getInstitute,
  getStates
} from "../../../redux/actions/filter.action";

const CreateReferral = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(
    (state) => state.patientReferral.refferalReceived
  );
  const pageNo = useSelector((state) => state.patientReferral.page);
  const hasMore = useSelector((state) => state.patientReferral.hasMore);

  const patientReferralProfile = useSelector(
    (state) => state.patientReferral.patientReferralProfile
  );

  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);

  const [schedules, setSchedules] = useState({});

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  useEffect(() => {
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(getProfileInfo());
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_PATIENT_DATA });
    };
  }, []);

  const fetchPatient = () => {
    dispatch(getPatientRefferralReceived({ page: pageNo }));
  };


  const makePayments = () => {
    dispatch(makePayment());
  };


  const handleChange = (e) => {
      const { name, value } = e.target;
      setSchedules({...schedules, [e.target.name]: e.target.value})

      if (name === "country") {
        dispatch(getStates({ country_id: value }));
      } else if (name === "state") {
        dispatch(getCities({ state_id: value }));
      } else if (name === "city") {
        dispatch(getInstitute({ city_id: value }));
      }
  };

  const handleSubmit = () =>{

    if (schedules.hasOwnProperty('day') && 
      schedules.hasOwnProperty('available_from') && 
      schedules.hasOwnProperty('available_to') && 
      schedules.hasOwnProperty('country') && 
      schedules.hasOwnProperty('state') && 
      schedules.hasOwnProperty('city') && 
      schedules.hasOwnProperty('institution')
    ) {
      var modalCloseButton = document.querySelector('[data-dismiss="modal"]');
      modalCloseButton.click();
      let payload = [];
      payload.push(schedules)
      dispatch(updateSchedule({ schedule: JSON.stringify(payload) }));
    } else {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }



  const tableContent = patientReferralProfile.subscription_status === true && patientReferralProfile.schedules.length > 0 ? patientReferralProfile.schedules.reduce((acc, curr) => {
      const foundIndex = acc.findIndex(item => item.institution_id === curr.institution_id);
      if (foundIndex === -1) {
          acc.push({
              institution_id: curr.institution_id,
              institution_name: curr.institution_name,
              schedules: {
                  [curr.schedules.length > 0 ? curr.schedules[0].day : ""]: curr.schedules
              }
          });
      } else {
          if (curr.schedules.length > 0) {
              const day = curr.schedules[0].day;
              acc[foundIndex].schedules[day] = acc[foundIndex].schedules[day]
                  ? [...acc[foundIndex].schedules[day], ...curr.schedules]
                  : curr.schedules;
          }
      }
      return acc;
  }, []) : null;

  
  return (
    <Root>
      <Breadcrumb
        title={"Create Your Customized Profile"}
        fieldOne="Dashboard "
        fieldTwo="My Availability"
        fieldThree="Create Your Customized Profile"
      />
      <ContainerWrapper>
        {patientReferralProfile.subscription_status === true ? (
            <>
              <Row className="mt-2 mb-2">
                <Col className="d-lg-flex justify-content-lg-between">
                  <div>
                    <h4 className="mb-1">My Availability</h4>
                  </div>
                  <div className=" d-lg-block">
                    <button
                      title="Invitations"
                      className="btn btn-primary mt-2 me-2"
                      data-toggle="modal" 
                      data-target="#addSchedules"
                    >
                      Add Schedules <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="bg-white card rounded-3 mb-4">
                    {tableContent?.length > 0 ? (
                      <Card.Body className="card-body px-4 py-3">
                        <div id="accordion">
                          {tableContent.map((val, index) => (
                            <div class="card" key={index}>
                              <div class="card-header d-flex justify-content-between align-items-center" id="headingOne">
                                  <h5 class="mb-0">
                                      <span class="text-primary m-5">{ val.institution_name }</span>
                                  </h5>
                                  <button
                                      class="btn btn-primary"
                                      data-toggle="collapse"
                                      data-target={`#collapse${index}`}
                                      aria-expanded="false"
                                      aria-controls={`collapse${index}`}
                                  >
                                      Schedules
                                  </button>
                              </div>

                              <div id={`collapse${index}`} class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div
                                  class="card-body"
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gridColumnGap: '20px'
                                  }}
                                >
                                  
                                  <div style={{ marginBottom: '20px' }}>
                                      <p><strong>MONDAY</strong></p>
                                      {val.schedules.MONDAY && val.schedules.MONDAY.length > 0 ? (
                                          val.schedules.MONDAY.map((res, index) => (
                                              <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                          ))
                                      ) : (
                                          <p>---</p>
                                      )}
                                  </div>
                                  
                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>TUESDAY</strong></p>
                                    {val.schedules.TUESDAY && val.schedules.TUESDAY.length > 0 ? (
                                        val.schedules.TUESDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>WEDNESDAY</strong></p>
                                    {val.schedules.WEDNESDAY && val.schedules.WEDNESDAY.length > 0 ? (
                                        val.schedules.WEDNESDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>THURSDAY</strong></p>
                                    {val.schedules.THURSDAY && val.schedules.THURSDAY.length > 0 ? (
                                        val.schedules.THURSDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>FRIDAY</strong></p>
                                    {val.schedules.FRIDAY && val.schedules.FRIDAY.length > 0 ? (
                                        val.schedules.FRIDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>SATURDAY</strong></p>
                                    {val.schedules.SATURDAY && val.schedules.SATURDAY.length > 0 ? (
                                        val.schedules.SATURDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                  <div style={{ marginBottom: '20px' }}>
                                    <p><strong>SUNDAY</strong></p>
                                    {val.schedules.SUNDAY && val.schedules.SUNDAY.length > 0 ? (
                                        val.schedules.SUNDAY.map((res, index) => (
                                            <p key={index}>{ `${res.available_from} to ${res.available_to}` }</p>
                                        ))
                                    ) : (
                                        <p>---</p>
                                    )}
                                  </div>

                                </div>
                              </div>
                            </div>
                          ))}
  
                        </div>
                      </Card.Body>
                    ) : (
                      <NoDataFound />
                    )}
                  </Card>
                </Col>
              </Row>
             
              <div class="modal fade" id="addSchedules" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Add New Schedule</h5>
                      <button type="button" class="btn btn-danger" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                      <>
                        <Row>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              Select Day
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={schedules.day}
                              id=""
                              className="form-select"
                              name="day"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            >
                              <option selected>Select</option>
                              {daysOfWeek.map((day) => (
                                <option key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              Available From
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              id=""
                              placeholder=""
                              value={schedules.available_from}
                              name="available_from"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              Available To
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              id=""
                              placeholder=" "
                              value={schedules.available_to}
                              name="available_to"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              Country
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={schedules.country}
                              id=""
                              className="form-select"
                              name="country"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            >
                              <option selected>Select</option>
                              {countryList.map((val, index) => (
                                <option key={index} value={val?._id}>
                                  {val.countryName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              State<span className="text-danger">*</span>
                            </label>
                            <select
                              value={schedules.state}
                              id=""
                              className="form-select"
                              name="state"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            >
                              <option selected>Select</option>
                              {stateList.map((val, index) => (
                                <option key={index} value={val?._id}>
                                  {val.stateName}
                                </option>
                              ))}

                            </select>
                          </div>
                          <div className="col-md-4">
                            <label for="" className="form-label">
                              City<span className="text-danger">*</span>
                            </label>
                            <select
                              value={schedules.city}
                              id=""
                              className="form-select"
                              name="city"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            >
                              <option selected>Select</option>
                              {cityList.map((val, index) => (
                                <option key={index} value={val?._id}>
                                  {val.cityName}
                                </option>
                              ))}
                              
                            </select>
                          </div>
                          <div className="col-md-12">
                            <label for="" className="form-label">
                              Hospital/College/Institute Name
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              value={schedules.institution}
                              id=""
                              className="form-select"
                              name="institution"
                              onChange={(e) =>
                                handleChange(e)
                              }
                            >
                              <option selected>Select</option>
                              {instituteList.map((val, index) => (
                                <option key={index} value={val?._id}>
                                  {val.instituteName}
                                </option>
                              ))}

                            </select>
                          </div>
                        </Row>
                        </>
                    </div>
                    <div class="modal-footer">
                      {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                      <button type="button" class="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                    </div>
                  </div>
                </div>
              </div>

            </>
          ) : ( 
            <div className="card-body pt-1 px-4 py-4">
              <div className="bg-primary p-4 text-center rounded-3">
                <h4 className="text-white">Create Your Customized Profile</h4>
                <p className="text-light ">
                  <strong>LAUNCH OFFER:</strong> Create your customized profile for <strong>Rs 2,490/-</strong>{" "}
                  <em>(Inclusive of GST)</em> and get 1 year of patient
                  referrals.
                </p>
                <p className="text-light">
                  Kindly{" "}
                  {/* <Link
                    // href="my-profile.html"
                    onClick={renderData("publicProfile")}
                    className="text-white text-decoration-underline"
                  >
                    click here
                  </Link>{" "} */}
                  update your public profile before creating referral
                  profile.
                </p>
                <Button
                  type="button"
                  onClick={() => makePayments()}
                  className="btn btn-success mb-2 btn-lg"
                >
                  Proceed to Payment{" "}
                  <i className="fa-solid fa-arrow-right me-1"></i>
                </Button>
              </div>
              {/* <div className="bg-primary p-4 text-center rounded-3">
                  <h4 style={{color: '#fff'}}>This service will be availble soon...</h4>
                </div> */}
              
            </div>
            
          )
        }
      </ContainerWrapper>
    </Root>
  );
};

export default CreateReferral;
