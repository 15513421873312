export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";
export const GET_VIDEO_DETAILS = "GET_VIDEO_DETAILS";
export const ADD_VIDEO = "ADD_VIDEO";
export const EDIT_VIDEO = "EDIT_VIDEO";
export const MY_UPLOADS = "MY_UPLOADS";
export const VIEWS = "VIEWS";
export const LIKE = "LIKE";
export const DISLIKE = "DISLIKE";
export const COMMENT = "COMMENT";
export const SET_PERCENTAGE = "SET_PERCENTAGE";
export const RESET_VIDEOS = "RESET_VIDEOS";
