import React, { useEffect, useState } from 'react';
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import logo from "../../../img/logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkForgotPasswordLink, resetPassword } from "../../../redux/actions/auth.action"
import SuccessPopup from "../../components/Popup/SuccessPopup"

const ConfirmPassword = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [token, setToken] = useState('');
    const isResetPasswordSuccess = useSelector((state) => state.auth.isResetPassword);
    
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const tokenParam = searchParams.get('token');
        setToken(tokenParam);
    }, []);

    const validation = Yup.object({
        password: Yup.string().min(6).required("Enter Password"),
        confirm_password: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Password must match")
    });

    useEffect(() => {
        if(token != "") {
            dispatch(checkForgotPasswordLink({ token }, navigate));
        }
    }, [token]);


    const initialValues = {
        password: "",
        confirm_password: "",
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: (values, action) => {
            console.log(values);
            const payload = { 
                token: token, 
                password: values.password, 
                confirmPassword: values.confirm_password
            }
            dispatch(resetPassword(payload, navigate));
            action.resetForm();
        }
    });

    return (
        <main className="main pt-0 bg-light">
            <section className="py-4">
                <ContainerWrapper>
                    {isResetPasswordSuccess ? (
                        <SuccessPopup 
                            message={"Your password has been updated successfully."}
                            isButton={true}
                        />
                    ) : (
                    <Row>
                        <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
                            <Card className="bg-white rounded-3 mb-4 shadow-lg">
                                <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                                    <Col className="text-center">
                                        <img src={logo} alt="Logo" className="mb-4" />
                                        <div>
                                            <h3 className="mb-1">Reset Password</h3>
                                            <p>Re Enter Password to Reset</p>
                                        </div>
                                    </Col>
                                </Card.Header>
                                <Card.Body className="pt-2 px-4 pb-4">
                                    <Form class="row g-3 g-lg-4" onSubmit={handleSubmit}>
                                        <Col md={6}>
                                            <Form.Label for="">
                                                Password<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control 
                                                type="password"
                                                autoComplete="off"
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.password && touched.password ? (
                                                <p className="form-error" style={{ color: "red" }}>{errors.password}</p>
                                            ) : null}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label for="">
                                                Confirm Password<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control 
                                                type="password"
                                                autoComplete="off"
                                                name="confirm_password"
                                                id="confirm_password"
                                                placeholder="Confirm Password"
                                                value={values.confirm_password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.confirm_password && touched.confirm_password ? (
                                                <p className="form-error" style={{ color: "red" }}>{errors.confirm_password}</p>
                                            ) : null}
                                        </Col>
                                        <div class="col-12 text-center">
                                            <Button type="submit" class="btn btn-primary btn-lg mb-2">Submit <i class="fa-solid fa-arrow-right"></i></Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                                <hr className="mb-0" />
                            </Card>
                        </Col>
                    </Row>
                    )}
                </ContainerWrapper>
            </section>
        </main>
    )
}

export default ConfirmPassword