import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import {
  CLEAR_FILTER,
  SET_SELECTED_FILTER,
} from "../../../redux/constants/filterActionTypes";

const Filters = ({ title }) => {
  const dispatch = useDispatch();
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const selectedFilter = useSelector((state) => state.filters.selectedFilters);
  const instituteList = useSelector((state) => state.filters.institutes);
  

  useEffect(() => {
 
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
 
    // if(stateList.length === 0) {
    //   dispatch(getStates({ country_id: "647463309596a53f50eedb1d" }));
    // }
    return () => {
      dispatch({ type: CLEAR_FILTER });
    };
  }, []);


  if(stateList.length === 0) {
      dispatch(getStates({ country_id: "647463309596a53f50eedb1d" }));
  }


  const handleChange = (e) => {
    dispatch({
      type: SET_SELECTED_FILTER,
      payload: e.target.value,
      name: e.target.name,
    });
    if (e.target.value) {
      if (e.target.name === "speciality") {
        dispatch(getSubSpeciality({ speciality_id: e.target.value }));
      } else if (e.target.name === "country") {
        dispatch(getStates({ country_id: e.target.value }));
      } else if (e.target.name === "state") {
        dispatch(getCities({ state_id: e.target.value }));
      } else if (e.target.name === "city") {
        dispatch(getInstitute({ city_id: e.target.value }));
      }
    }
  };

  return (
    <div className="" id="collapseExample">
      <Row className="p-3 pb-0">
        <Col className="col-12">
          <h6>{title}:</h6>
        </Col>
        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              name="speciality"
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              value={selectedFilter.speciality}
            >
              <option value={""}>Select Speciality</option>
              {specialityList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.specialityName}
                </option>
              ))}
            </Form.Select>
            <Form.Label for="">Filter by Speciality</Form.Label>
          </Form.Floating>
        </Col>
        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              name="subspeciality"
              value={selectedFilter.subspeciality}
            >
              <option value={""} selected>Select Subspeciality</option>
              {subSpecialityList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.subSpecialityName}
                </option>
              ))}
            </Form.Select>
            <label for="">Filter by Subspeciality</label>
          </Form.Floating>
        </Col>

        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              name="country"
              value={selectedFilter.country}
            >
              <option value={""} selected>Select Country</option>
              {countryList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.countryName}
                </option>
              ))}{" "}
            </Form.Select>
            <label for="">Filter by Country</label>
          </Form.Floating>
        </Col>
        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              name="state"
              value={selectedFilter.state}
            >
              <option value={""} selected>Select State</option>
              {stateList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.stateName}
                </option>
              ))}{" "}
            </Form.Select>
            <label for="">Filter by State</label>
          </Form.Floating>
        </Col>

        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              name="city"
              value={selectedFilter.city}
            >
              <option value={""} selected>Select City</option>
              {cityList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.cityName}
                </option>
              ))}{" "}
            </Form.Select>
            <label for="">Filter by City</label>
          </Form.Floating>
        </Col>
        <Col sm={6} md={4} className="col-xl mb-3">
          <Form.Floating>
            <Form.Select
              onChange={(e) => handleChange(e)}
              className="form-select"
              id=""
              aria-label=""
              name="institution"
              value={selectedFilter.institution}
            >
              <option value={""} selected>Select Institution</option>
              {instituteList.map((val, index) => (
                <option key={index} value={val._id}>
                  {val.instituteName}
                </option>
              ))}{" "}
            </Form.Select>
            <label for="">Filter by Institution</label>
          </Form.Floating>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
