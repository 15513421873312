import { GET_ALL_VIDEOS, ADD_VIDEO, SET_PERCENTAGE, MY_UPLOADS, GET_VIDEO_DETAILS, LIKE, DISLIKE, EDIT_VIDEO, RESET_VIDEOS } from "../constants/recordedConferenceActionTypes";

const initialState = {
  videosList: [],
  myVideosList: [],
  videoDetails: "",
  like: 0,
  progress: 0,
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (recordedConference = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIDEOS:
      if (action.reset) {
        return {
          ...recordedConference,
          videosList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...recordedConference,
          videosList: [
            ...recordedConference.videosList,
            ...action.payload,
          ],
          page: recordedConference.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_VIDEO:
      return {
        ...recordedConference,
        videosList: action.payload,
      };
    case SET_PERCENTAGE:
      return {
        ...recordedConference,
        progress: action.payload,
      };
    case MY_UPLOADS:
      return {
        ...recordedConference,
        myVideosList: action.payload,
        page: 1,
        hasMore: action.hasMore,
      };
    case GET_VIDEO_DETAILS:
      return {
        ...recordedConference,
        videoDetails: action.payload
      };
    case LIKE:
      if (action.payload.success) {
        return {
          ...recordedConference,
          like: 1
        };
      }
    case DISLIKE:
      if (action.payload.success) {
        return {
          ...recordedConference,
          like: -1
        };
      }
    case RESET_VIDEOS:
      return{
        ...initialState
      }

    default:
      return recordedConference;
  }
};
