import React from "react";
import logo from "../../../img/logo.svg";
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import successImage from "../../../img/check.png";
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { useNavigate } from "react-router-dom";

const SuccessPopup = ({ message, isButton}) => {
  let navigate = useNavigate();
  return (
        <Row>
            <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
                <Card className="bg-white rounded-3 mb-4 shadow-lg">
                    <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                        <Col className="text-center">
                            <img src={logo} alt="Logo" className="mb-4" /> 
                            <div>
                                <img src={successImage} alt="Logo" className="mb-4" style={{width: "75px"}} />
                                {/* <h3 className="mb-1">Forgot Password</h3> */}
                            </div>
                        </Col>
                    </Card.Header>
                    <Card.Body className="pt-2 px-4 pb-4" style={{textAlign: "center"}}>
                      <h5>{message}</h5>
                    </Card.Body>
                    {/* <hr className="mb-0" /> */}
                    {isButton ? (
                      <Card.Footer className="pt-2 px-4 pb-4" style={{textAlign: "center"}}>
                        <Button onClick={() => { navigate(`/login`) }}>LogIn Now</Button>
                      </Card.Footer>
                    ) : (
                      null
                    )}
                </Card>
            </Col>
        </Row>
    );
};

export default SuccessPopup;
