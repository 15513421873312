import { toast } from "react-toastify";
import * as api from "../../services/api";
import {
  ADD_ASSOCIATION,
  ADD_ASSOCIATION_UPDATE,
  DELETE_ASSOCIATION_UPDATE,
  EDIT_ASSOCIATION_UPDATE,
  GET_ALL_ASSOCIATION,
  GET_ALL_ASSOCIATION_BY_FILTER,
  GET_ALL_ASSOCIATION_BY_ID,
  GET_ALL_ASSOCIATION_CME,
  GET_ALL_ASSOCIATION_CONFERENCE,
  GET_ALL_ASSOCIATION_LIVESURGERYS,
  GET_ALL_ASSOCIATION_TUTORIAL,
  GET_ALL_ASSOCIATION_VIDEOS,
  GET_ASSOCIATION_DASHBOARD,
  GET_ASSOCIATION_DROPDOWN,
  GET_ASSOCIATION_TYPE,
  GET_ASSOCIATION_UPDATE,
  GET_MY_ASSOCIATION,
  JOIN_ASSOCIATION,
  LIST_ASSOCIATION_MEMBERS,
  MY_ASSOCIATION_CME,
  MY_ASSOCIATION_CONFERENCE,
  MY_ASSOCIATION_LIVESURGERYS,
  MY_ASSOCIATION_TUTORIAL,
  MY_ASSOCIATION_VIDEO,
} from "../constants/assocGroupActionTypes";
import {} from "../constants/observershipActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const getAllAssociation =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      var result;

      if (data.groupType === "2") {
        result = await api.getAllClosedAssociation(data);
      } else {
        result = await api.getAllAssociation(data);
      }

      dispatch({
        type: GET_ALL_ASSOCIATION_BY_FILTER,
        payload:
          result.data.data?.docs?.length > 0 ? result.data.data.docs : [],
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
  };

export const getAssociationType = () => async (dispatch) => {
  try {
    const result = await api.getAssociationType();

    dispatch({
      type: GET_ASSOCIATION_TYPE,
      payload: result.data.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const getAssociationById = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.getAssociationById(data);

    dispatch({
      type: GET_ALL_ASSOCIATION_BY_ID,
      payload: result.data.data[0],
    });
    dispatch({ type: CLEAR_LOADING });
    return result.data.data[0];
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const getAssociationDropDown = () => async (dispatch) => {
  try {
    const result = await api.getAssociationDropDown();

    dispatch({
      type: GET_ASSOCIATION_DROPDOWN,
      payload: result.data.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const getMyAssociation = () => async (dispatch) => {
  try {
    const result = await api.getMyAssociation();

    dispatch({
      type: GET_MY_ASSOCIATION,
      payload: result.data.data.docs,
    });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const addNewAssociation = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.addAssociation(data);
    if (result.data.status.success) {
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_ASSOCIATION, payload: result.data.data });
      dispatch({ type: CLEAR_LOADING });
      navigate("/myAssocGroups");
    } else {
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
    }

    return "success";
  } catch (error) {
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    return false;
  }
};

export const updateAssociation = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.updateAssociation(data);
    toast.success("Association Updated Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    dispatch({ type: ADD_ASSOCIATION, payload: result.data.data });
    navigate("/myAssocGroups");
    return "success";
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
};

export const joinAssociation = (data) => async (dispatch) => {
  try {
    const result = await api.joinAssociation(data);
    toast.success("Association Joined Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: JOIN_ASSOCIATION, payload: result.data.data[0] });
  } catch (error) {
    console.log(error.message);
    toast.error("Failed to Join Association", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const getAllLiveSurgeryForAssociation =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.browseAssociationLiveSurgery(data);

      dispatch({ type: CLEAR_LOADING });

      dispatch({
        type: GET_ALL_ASSOCIATION_LIVESURGERYS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getAllAssociationVideos =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.browseAssociationVideo(data);
      dispatch({
        type: GET_ALL_ASSOCIATION_VIDEOS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
  };

export const getAllAssociationTutorials =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.browseAssociationTutorial(data);
      dispatch({
        type: GET_ALL_ASSOCIATION_TUTORIAL,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getAllAssociationConference =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.browseAssociationConference(data);
      dispatch({
        type: GET_ALL_ASSOCIATION_CONFERENCE,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      console.log(error.message);
      dispatch({ type: CLEAR_LOADING });
    }
  };

export const getAllAssociationMeetings =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.browseAssociationMeetings(data);
      if (result) {
        dispatch({
          type: GET_ALL_ASSOCIATION_CME,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getMyAssociationLiveSurgery =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.myAssociationLiveSurgery(data);
      if (result) {
        dispatch({ type: CLEAR_LOADING });
        dispatch({
          type: MY_ASSOCIATION_LIVESURGERYS,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
  };

export const getMyAssociationVideo = (data) => async (dispatch) => {
  try {
    const result = await api.myAssociationVideos(data);

    dispatch({ type: MY_ASSOCIATION_VIDEO, payload: result.data.data.docs });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const getMyAssociationTutorial = (data) => async (dispatch) => {
  try {
    const result = await api.myAssociationTutorial(data);

    dispatch({ type: MY_ASSOCIATION_TUTORIAL, payload: result.data.data.docs });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const getMyAssociationCME =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      console.log(data);
      const result = await api.myAssociationCme(data);
      if (result) {
        dispatch({
          type: MY_ASSOCIATION_CME,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const myAssociationConference = (data) => async (dispatch) => {
  try {
    const result = await api.myAssociationConference(data);
    console.log(result);
    dispatch({
      type: MY_ASSOCIATION_CONFERENCE,
      payload: result.data.data.docs,
    });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const getAssociationUpdate =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAssociationUpdate(data);
      if (result) {
        dispatch({
          type: GET_ASSOCIATION_UPDATE,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };
export const addAssociationUpdate = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.postAssociationUpdate(data);
    if (result) {
      dispatch({
        type: ADD_ASSOCIATION_UPDATE,
        payload: result.data.data,
      });
      dispatch({ type: CLEAR_LOADING });
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return "success";
  } catch (error) {
    toast.error("Failed to Add Update", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const editAssociationUpdate = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.editAssociationUpdate(data);
    if (result) {
      dispatch({
        type: EDIT_ASSOCIATION_UPDATE,
        payload: result.data.data,
      });
      dispatch({ type: CLEAR_LOADING });
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return "success";
  } catch (error) {
    toast.error("Failed to Add Update", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const deleteAssociationUpdate = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.deleteAssociationUpdate(data);
    if (result) {
      dispatch({
        type: DELETE_ASSOCIATION_UPDATE,
        payload: data,
      });
      dispatch({ type: CLEAR_LOADING });
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return "success";
  } catch (error) {
    toast.error("Failed to Add Update", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const listAssociationMembers = (data) => async (dispatch) => {
  try {
    const result = await api.listAssociationMembers(data);
    if (result) {
      dispatch({
        type: LIST_ASSOCIATION_MEMBERS,
        payload: result.data.data?.docs,
        hasMore: result.data.data.hasNextPage,
      });
      dispatch({ type: CLEAR_LOADING });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const exitAssociation = (data, navigate) => async (dispatch) => {
  try {
    const result = await api.exitAssociation(data);

    // dispatch({ type: MY_ASSOCIATION_TUTORIAL, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
    toast.success(result.data.status.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (!data.userId) {
      navigate(`/BrowseAssocGroups`);
    }
  } catch (error) {
    toast.error("Failed to exit Association", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const getAssociationDashboard = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.getAssociationDashboard(data);

    dispatch({
      type: GET_ASSOCIATION_DASHBOARD,
      payload: result.data.data.data,
    });
    dispatch({ type: CLEAR_LOADING });
    return result.data.data[0];
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};
