import React, { useRef } from 'react';
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { Card, Col, Row, Button, Container } from 'react-bootstrap';
import logo from "../../../img/logo.svg";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { verifyOTP } from "../../../redux/actions/auth.action";
import { decryptData } from '../../../utils/core';

const Otp = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const otpId = decryptData(id)
  // Define validation schema
  const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]{6}$/, 'OTP must be a 6-digit number')
      .required('Please enter the OTP'),
  });

  // Initial values
  const initialValues = {
    otp: '',
  };

  // Submit handler
  const handleSubmit = (values, { setSubmitting }) => {
    // Do something with the OTP, e.g., send it to the server for validation
    console.log('OTP:', values.otp);
    console.log('OTP:', values.otp);
    const payload = {
        otp: values.otp,
        otpId: otpId
    }
    dispatch(verifyOTP(payload, navigate));
    // action.resetForm();
    setSubmitting(false);
  };

  // Formik hook
  const formik = useFormik({
    initialValues,
    validationSchema: otpValidationSchema,
    onSubmit: handleSubmit,
  });

  // Array of refs to hold references to the input elements
  const inputRefs = useRef(Array(6).fill(null).map(() => React.createRef()));

  // Helper function to handle individual OTP digit changes and move focus
  const handleOtpChange = (index, value) => {
    const newOtp = formik.values.otp.slice(0, index) + value + formik.values.otp.slice(index + 1);
    formik.setFieldValue('otp', newOtp);

    // Move focus to the next input box (if available)
    if (index < inputRefs.current.length - 1 && value !== '') {
      inputRefs.current[index + 1].current.focus();
    }
  };

  return (
    <main className="main pt-0 bg-light">
        <section className="py-4">
            <Container>
                <Row>
                    <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
                        <Card className="bg-white rounded-3 mb-4 shadow-lg">
                            <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                                <Col className="text-center">
                                    <img src={logo} alt="Logo" className="mb-4" />
                                    <div>
                                        <h3 className="mb-1">Register</h3>
                                        <p>OTP will send to your email and mobile number</p>
                                    </div>
                                </Col>
                            </Card.Header>
                            <Card.Body className="pt-2 px-4 pb-4">
                                <form onSubmit={formik.handleSubmit} class="row g-3 g-lg-4">
                                    <div class="col-md-12">
                                        <div className='otp_padding'>
                                            <label for="" class="form-label">Enter OTP<span class="text-danger">*</span></label>
                                            <div className='otp_box'>
                                                {[...Array(6)].map((_, index) => (
                                                    <input
                                                        key={index}
                                                        name={`otp[${index}]`}
                                                        type="text"
                                                        value={formik.values.otp[index] || ''}
                                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                                        onBlur={formik.handleBlur}
                                                        maxLength={1}
                                                        class="form-control"
                                                        ref={inputRefs.current[index]}
                                                    />
                                                ))}
                                            </div>
                                            {formik.touched.otp && formik.errors.otp ? (
                                            <div style={{ color: 'red' }}>{formik.errors.otp}</div>
                                            ) : null}
                                            {/* <div id="" class="form-text text-end">
                                                <a href="password-reset.html">Resend OTP?</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary btn-lg mb-2"disabled={formik.isSubmitting}>Submit <i class="fa-solid fa-arrow-right"></i></button>
                                    </div>
                                </form>
                            </Card.Body>
                            <hr className="mb-0" />
                            <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                                <Col>
                                    <div className="text-center">
                                        <h4>Already have an account?</h4>
                                        <p>
                                            Login now and explore surgery videos, tutorials,
                                            associations, workshops, live surgeries and more.
                                        </p>
                                    </div>
                                </Col>
                            </Card.Header>
                            <Card.Body className="pt-2 px-4 pb-4">
                                <Col className="text-center">
                                    <Button
                                        type="button"
                                        className="btn btn-light btn-lg mb-2"
                                    >
                                        Login <i className="fa-solid fa-arrow-right"></i>
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    </main>
  );
};

export default Otp;