import React, { useEffect } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getAllConferenceByFilter,
  registerToConference,
} from "../../../redux/actions/conference.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import { getAllAssociationConference } from "../../../redux/actions/association.action";

const AssociationConference = () => {
  const { associationId } = useParams();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const conferenceList = useSelector(
    (state) => state.association.conferenceList
  );
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    if (Object.keys(selectedFilter).length > 0) {
      dispatch(
        getAllAssociationConference(
          { ...selectedFilter, page: 1, assocId: associationId },
          true
        )
      );
    } else {
      dispatch(
        getAllAssociationConference({
          ...selectedFilter,
          page: pageNo,
          assocId: associationId,
        })
      );
    }
  }, [selectedFilter]);

  const fetchConference = () => {
    dispatch(
      getAllAssociationConference({
        ...selectedFilter,
        page: pageNo,
        assocId: associationId,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  const registerNow = (id) => {
    console.log("register clicked", id);
    dispatch(registerToConference({ id: id }));
  };
  console.log(conferenceList);
  return (
    <Root>
      <Breadcrumb
        title={"Conference / Workshop"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Browse Conference / Workshop",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Conference / Workshop</h4>
              <p>
                Browse Conference, Workshop and Hands on Training in your field
              </p>
            </div>
            <div className="d-none d-lg-block">
              <Button
                title="Post Conference Workshop & Hands On Training"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate(`/postConfWorkshop/${associationId}`);
                }}
              >
                Post Conference Workshop & Hands On Training{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>
          <Filters title="Filter Conference" module="conference" />
        </div>
        <Row>
          {conferenceList.length > 0 ? (
            conferenceList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-workshop"
                  badgeTitle="WORKSHOP"
                  feeAmount={`Rs. ${val.fees}`}
                  feeText="Starting from"
                  cardTitle={val.title}
                  duration={`${moment(val.startDate).format(
                    "D MMMM YYYY"
                  )} to ${moment(val.endDate).format("D MMMM YYYY")}`}
                  location={`${val.country.state.city.cityName}, ${val.country.state.stateName}, ${val.country.countryName}`}
                  doctorName={val.user_details.firstName}
                  doctorAddress={val.user_details.institution.instituteName}
                  buttonTitle="RegisterNtow"
                  buttonAction={registerNow}
                  id={val._id}
                  isHost={false}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchConference}
          globalState={conferenceList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default AssociationConference;
