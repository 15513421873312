import React, { useState, useEffect } from "react";
import Root from "../../../components/Root";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Details from "../../../components/Details/Details";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../../layouts/ContainerWrapper";
import { formatDate } from "../../../../utils/core";
 
import { getVideoDetails } from "../../../../redux/actions/recorded.conference.action";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const VideoDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const videoData = useSelector((state) => state.recordedConference.videoDetails);

  useEffect(() => {
    dispatch(getVideoDetails({ id: id }));
  }, []);

  return (
    <Root>
      {/* <Breadcrumb
        title={"Conference Details"}
        fieldOne="Dashboard"
        fieldTwo="Recorded Conference"
        fieldThree="Conference Details"
      /> */}
      <Breadcrumb
        title={"Recorded Conference"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${id}`,
          },
          {
            name: "Conference Details",
          },
        ]}
      />
      <ContainerWrapper>
        {videoData ? (
          <Details
            tag={"Conference"}
            title={videoData.title}
            comments={videoData.comments}
            createDate={formatDate(videoData.conferenceDate)}
            likes={videoData.likes}
            totalComments={videoData.totalComments}
            totalLikes={videoData.totalLikes}
            totalviews={videoData.views}
            userDetails={videoData.user_details}
            url={videoData.url}
            urlType={"video"}
            videoId={videoData._id}
          />
        ) : (
          <NoDataFound />
        )}
      </ContainerWrapper>
    </Root>
  );
};

export default VideoDetails;
