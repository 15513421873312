import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addObserverShip, updateObservership } from "../../../redux/actions/observership.action";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { dateformatter, formatDate, getDate } from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const PostObserverCourse = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const observershipById = useSelector(
    (state) => state.observership.observershipById
  );
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    if (observershipById) {
      dispatch(
        getSubSpeciality({ speciality_id: observershipById.specialityId })
      );
      dispatch(getStates({ country_id: observershipById.facultyCountryId }));
      dispatch(getCities({ state_id: observershipById.facultyStateId }));
      dispatch(getInstitute({ city_id: observershipById.facultyCityId }));
    }
  }, [observershipById]);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    }
  };

  const handleSubmit = (values, { setFieldValue, resetForm }) => {
    const requestData = new FormData();
    requestData.append("facultyCountry", values.country);
    requestData.append("facultyState", values.state);
    requestData.append("facultyCity", values.city);
    requestData.append("facultyInstitute", values.institute);
    requestData.append("speciality", values.speciality);
    if (values.subSpeciality && values.subSpeciality != "") {
      requestData.append("subSpeciality", values.subSpeciality);
    }
    requestData.append("facultyName", values.facultyName);
    requestData.append("observershipName", values.courseName);
    requestData.append("startDate", values.startDate);
    requestData.append("endDate", values.endDate);
    requestData.append("observershipFee", values.fee);
    if(file) {
      requestData.append("attachmentFile", file);
    }
    requestData.append("availableSlots", values.availableSlots);
    requestData.append("observershipDetail", values.courseDescription);

    if (id && observershipById) {
      requestData.append("observershipId", id)
      dispatch(updateObservership(requestData, navigate))
    } else {
      dispatch(addObserverShip(requestData, navigate));
    }

    // Reset the form after submission
    setFile(null);
    resetForm();
  };
  let initialState = id && observershipById
    ? {
      speciality: observershipById.specialityId,
      subSpeciality: observershipById.subSpecialityId,
      country: observershipById.facultyCountryId,
      state: observershipById.facultyStateId,
      city: observershipById.facultyCityId,
      institute: observershipById.facultyInstituteId,
      facultyName: observershipById.facultyName,
      courseName: observershipById.observershipName,
      courseDescription: observershipById.observershipDetail,
      startDate: dateformatter(observershipById.startDate),
      endDate: dateformatter(observershipById.endDate),
      attachment: "",
      availableSlots: observershipById.availableSlots,
      fee: observershipById.observershipFee,
      agreement: true,
    }
    : {
      speciality: "",
      subSpeciality: "",
      country: "",
      state: "",
      city: "",
      institute: "",
      facultyName: "",
      courseName: "",
      courseDescription: "",
      startDate: "",
      endDate: "",
      attachment: "",
      availableSlots: "",
      fee: "",
      agreement: false,
    };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    // subSpeciality: Yup.string(),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    institute: Yup.string().required("Institute/Hospital is required"),
    facultyName: Yup.string().required("Faculty's Name is required"),
    courseName: Yup.string().required("Observership Course Name is required"),
    courseDescription: Yup.string(),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    attachment: Yup.mixed(),
    availableSlots: Yup.string(),
    fee: Yup.string().required("Registration Fee is required"),
    agreement: Yup.boolean().oneOf([true], "You must confirm the data"),
  });

  return (
    <Root>
      <Breadcrumb
        title={"Observership"}
        fieldOne="Dashboard"
        fieldTwo="Post Observership Course"
      />
      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Post Observership Course</h2>
                    <p>
                      Fill in the below details and post your observership
                      course. Fields with <span className="text-danger">*</span>{" "}
                      are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Subspeciality
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="subSpeciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {subSpecialityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.subSpecialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="subSpeciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's Country
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {countryList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.countryName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's State
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="state"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {stateList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.stateName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's City
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="city"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {cityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.cityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Institute/Hospital
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="institute"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {instituteList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.instituteName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="institute"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Faculty's Name
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="facultyName"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="facultyName"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Observership Course Name
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="courseName"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="courseName"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Observership Course Description
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="courseDescription"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="courseDescription"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Start Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="startDate"
                          min={getDate(new Date())}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="startDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          End Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="endDate"
                          min={getDate(new Date())}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="endDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Attach Agenda/Brochure
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="attachment"
                            className="form-control"
                            onChange={handleFileInputChange}
                          />
                        </div>
                        <ErrorMessage
                          name="attachment"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Slots Available
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="availableSlots"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="availableSlots"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Registration Fee
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <div className="input-group">
                          <span className="input-group-text" id="">
                            Rs.
                          </span>
                          <Field
                            type="number"
                            name="fee"
                            className="form-control"
                          />
                        </div>
                        <div id="" className="form-text">
                          Enter the price inclusive of 18% GST & 10% Surgic
                          Share service charge.
                        </div>
                        <ErrorMessage
                          name="fee"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement"
                          >
                            I confirm that all the data given is correct and am
                            authorized to post this course.
                          </label>
                          <ErrorMessage
                            name="agreement"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Post Observership Course{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default PostObserverCourse;
