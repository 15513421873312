import { toast } from "react-toastify";
import { changeRoute } from "../../utils/core";
import * as api from "../../services/api";
import {
  GET_ALL_MEETINGS,
  GET_MEETING_DETAILS,
  ADD_MEETING,
  EDIT_MEETING,
  MY_MEETINGS,
  MEETING_INVITATION,
  ACCEPT_REJECT_MEETING,
  ACCEPT_DECLINE_MEETING,
} from "../constants/meetingActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const getAllMeetings =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllMeetings(data);
      // if(result.data.data.docs.length > 0){
      //   dispatch({ type: GET_ALL_MEETINGS, payload: result.data.data.docs , hasMore : true, reset:reset });
      // }else {
      //   dispatch({ type: GET_ALL_MEETINGS, payload: [] , hasMore : false, reset:reset });
      // }
      if (result) {
        dispatch({
          type: GET_ALL_MEETINGS,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getMeetingDetails = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.getMeetingDetails(data);
    console.log("editable dat", result)
    dispatch({ type: GET_MEETING_DETAILS, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const getMyUploads =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      console.log(data);
      const result = await api.getMyMeetings(data);
      // if(result.data.data.docs.length > 0){
      //   dispatch({ type: MY_MEETINGS, payload: result.data.data.docs , hasMore : true, reset:reset });
      // }else {
      //   dispatch({ type: MY_MEETINGS, payload: [] , hasMore : false, reset:reset });
      // }
      if (result) {
        dispatch({
          type: MY_MEETINGS,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const myInvitations =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getMeetingInvitations(data);
      if (result.data.data.docs.length > 0) {
        dispatch({
          type: MEETING_INVITATION,
          payload: result.data.data.docs,
          hasMore: true,
          reset: true,
        });
      } else {
        dispatch({
          type: MEETING_INVITATION,
          payload: [],
          hasMore: false,
          reset: reset,
        });
      }
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const createMeeting = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    let result = {};
    if (data.get("associationId")) {
      result = await api.postAssociationCme(data);
    } else {
      result = await api.addMeeting(data);
    }

    if(result.data.status.success) {
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_MEETING, payload: result.data.data });
      dispatch({ type: CLEAR_LOADING });
      if (data.get("associationId")) {
        navigate(`/myAssociationCmeRtm/${data.get("associationId")}`)
      } else {
        navigate('/myCmeRtm')
      }
      return "success";
    } else {
        dispatch({ type: CLEAR_LOADING });
        toast.error(result.data.status.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
    }
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
};

export const editMeeting = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.updateMeeting(data);
    toast.success("CME RTM Updated Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    dispatch({ type: ADD_MEETING, payload: result.data.data });
    if (data.get("associationId")) {
      navigate(`/myAssociationCmeRtm/${data.get("associationId")}`)
    } else {
      navigate('/myCmeRtm')
    }
    return "success";
  } catch (error) {
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });

    return false;
  }
};

export const handleAcceptDecline = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.acceptMeeting(data);
    toast.success(
      data.status === 1 ? "Invitation Accepted" : "Invitation Declined",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    dispatch({ type: ACCEPT_DECLINE_MEETING, payload: data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};


export const joinMeeting = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.joinMeeting(data);

    if(result.data.success) {
      // window.open(`https://sdk.surgicshare.com?token=${result.data.data.sdk_token}&id=${result.data.data.sdk_id}&isHost=${result.data.data.is_host}&redirect=${data.id}`, "_self");
      window.open(`http://localhost:3001?token=${result.data.data.sdk_token}&id=${result.data.data.sdk_id}&isHost=${result.data.data.is_host}&redirect=${data.id}`, "_blank");

    } else {
      navigate(`/meeting-request?id=${data.id}&message=${result.data.message}`)
    }
    
    
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const checkUserInZoom = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.checkUserInZoom(data);

    if(result.data.status.success) {
      if(data.associationId) {
        navigate(`/scheduleLiveCmeRtm/${data.associationId}`)
      } else {

        navigate(`/scheduleLiveCmeRtm`)
      }
    } else {
      navigate(`/follow-steps`)
    }
    
    dispatch({ type: CLEAR_LOADING });
    return true;
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    return false;
  }

};


export const addEvent = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING })
  try {
    const response = await api.acceptMeeting(data);
    if(response.data.data.status.success) {
       console.log("success");
    } else {
       console.log("error");
    }
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING }); 
    console.log(error.message)
  }
}
