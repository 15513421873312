import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import {
  getAllAssociation,
  joinAssociation,
} from "../../../redux/actions/association.action";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import AssociationLogo from "../../../img/association-default.png";

const BrowseAssocGroups = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const associationList = useSelector(
    (state) => state.association.associationList
  );
  const pageNo = useSelector((state) => state.association.page);
  const hasMore = useSelector((state) => state.association.hasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const [groupType, setGroupType] = useState(1);

  const headerColumns = [
    {
      name: "Name",
      key: "association_name",
    },
    {
      name: "Speciality",
      key: "speciality",
    },
    {
      name: "Sub Speciality",
      key: "subSpecialityData",
    },
    {
      name: "Level",
      key: "associationLevel",
    },
    {
      name: "Location",
      key: "location",
    },
  ];

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (isInitialMount.current) {
      dispatch(getAllAssociation({ ...selectedFilter,search: searchParams, page: pageNo, groupType: groupType }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllAssociation({ ...selectedFilter,search: searchParams, page: 1, groupType: groupType }, true));
    }
  }, [selectedFilter]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  const fetchAssociation = () => {
    dispatch(getAllAssociation({ ...selectedFilter,search: searchParams, page: pageNo, groupType: groupType }));
  };

  const handleSearch = () => {
    dispatch(
      getAllAssociation(
        { ...selectedFilter, search: searchParams, page: 1, groupType: groupType },
        true
      )
    );
  };

  const joinAssociationGroup = (data) => {
    dispatch(joinAssociation({ associationId: data._id }));
  };

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  const [searchParams, setSearchParams] = useState("");

  const changeHandler = async (e) => {
    await setGroupType(e.target.value)
    dispatch(getAllAssociation({ ...selectedFilter,search: searchParams, page: 1, groupType: e.target.value }, true));
  }

  return (
    <Root>
      <Breadcrumb
        title={"Associations and Groups"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Browse Associations and Groups",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Associations and Groups</h4>
              <p>Browse national, state associations and groups.</p>

              <Form.Floating>
                <Form.Select
                  onChange={(e) => changeHandler(e)}
                  className="form-select mt-2 me-2 mb-4"
                  id=""
                  aria-label=""
                  name="subspeciality"
                  value={groupType}
                >
                  <option value={1} selected>Open Group</option>
                  <option value={2} selected>Closed Group</option>
                  
                </Form.Select>
                <label for="">Filter by Association Type</label>
              </Form.Floating>
            </div>
            <div className="d-none d-lg-block">
              <Button
                title="Organize Associations and Groups"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/createAssocGroups");
                }}
              >
                Create Association or Group{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>

             
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>

          <Filters title={"Browse Associations and Groups"} />
        </div>
        <Row>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={searchParams}
              onChange={(e) => setSearchParams(e.target.value)}
              placeholder="Search Association and Groups"
            />
            <Button onClick={()=>handleSearch()} type="button">
              <i className="fa-solid fa-search fa-lg" /> Search
            </Button>
          </InputGroup>
        </Row>
        <Row>
          <Col>
            {associationList && associationList.length > 0 ? (
              <Card className="bg-white card rounded-3 mb-4">
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <tr>
                        {headerColumns.map((val, index) => (
                          <th key={val.key}>{val.name}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </tr>
                      <tbody>
                        {associationList && associationList.map((val, index) => (
                          <tr key={index}>
                            {headerColumns.map((headerValue, headerIdex) =>
                              headerValue.name === "Name" ? (
                                <td className="fw-bold">
                                  <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                    {headerValue.name}:
                                  </p>
                                  <div className="author d-flex align-items-center">
                                    <span className="user-image-wrap me-2">
                                      {/* <img
                                        src={require("../../../img/association-logo.jpg")}
                                        alt="Surgeon Name"
                                        width="44"
                                      /> */}
                                      {val?.group_image ? (
                                          <img
                                            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.group_image}`}
                                            alt=""
                                            title="Association Logo"
                                            className="img-fluid mb-3"
                                          />
                                        ) : (
                                          <img
                                            src={AssociationLogo}
                                            alt=""
                                            title="Association Logo"
                                            className="img-fluid mb-3"
                                          />
                                        )}
                                    </span>
                                    <span className="author-details">
                                      <p className="mb-0">
                                        {val[headerValue.key]}
                                      </p>
                                    </span>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                    {headerValue.name}:
                                  </p>
                                  <p className="mb-0">
                                    {" "}
                                    {val[headerValue.key]}
                                  </p>
                                </td>
                              )
                            )}
                            <td className="text-left text-lg-end">
                              {val.members.some(
                                (member) => member.userId === currentUser && member.isActive ===1
                              ) ? (
                                <a
                                  href="javascript:void(0)"
                                  class="mb-2 btn btn-link btn-sm"
                                  onClick={() =>
                                    navigate(`/AssociationDetails/${val._id}`)
                                  }
                                >
                                  View <i class="fa-solid fa-arrow-right"></i>
                                </a>
                              ) : (
                                <Button
                                  className=" btn btn-primary mb-2 btn-sm me-1"
                                  onClick={() => joinAssociationGroup(val)}
                                >
                                  Join{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchAssociation}
          globalState={associationList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseAssocGroups;
