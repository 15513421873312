import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Cancellation = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Cancellation and Refund Policy"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Cancellation and Refund Policy",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 col-lg-10 order-2 order-lg-1">
              <p className="title-meta mt-0">
                Cancellation and Refund Policy <span className="line"></span>
              </p>
              <p>
                <strong>Non-Refundable Fees:</strong> All payments made for
                services are generally non-refundable. Exceptions are made only
                if the service is not provided at all by the faculty or
                organizer due to unforeseen circumstances such as natural
                disasters, technical failures that cannot be resolved, or other
                significant events beyond control.
              </p>
              <p>
                <strong>Refunds:</strong> In the case of a full cancellation of
                service by the organizer or faculty, participants will be
                eligible for a refund. The refund process will begin within
                seven (7) working days, and the amount will be credited to the
                original payment method.
              </p>
              <p>
                <strong>Partial Service:</strong> If a service has begun or is
                partially delivered but cannot be completed, refunds are not
                guaranteed. The decision to refund partially delivered services
                is at the discretion of the organizers and will be assessed on a
                case-by-case basis.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  );
};

export default Cancellation;
