import React, { useState, useEffect } from "react";
import YouTubePlayer from "../../components/VideoPlayers/YouTubePlayer";
import { useDispatch, useSelector } from "react-redux";
import { timeAgo } from "../../../utils/core";
import { useNavigate } from "react-router-dom";
import {
  addLike,
  unLike,
  addViews,
  postComment,
} from "../../../redux/actions/video.action";

import {
  addLikeConference,
  unLikeConference,
  addViewsConference,
  postCommentConference,
} from "../../../redux/actions/recorded.conference.action";

import {
  addLikeTutorial,
  disLikeTutorial,
  addViewsTutorial,
  postCommentTutorial,
} from "../../../redux/actions/tutorial.action";
import PDFViewer from "../PdfViewer/PdfViewer";
import IframeRender from "../VideoPlayers/IframeRender";
import { Link } from "react-router-dom"

const Details = (video) => {
  const dispatch = useDispatch();
    let navigate = useNavigate();
  const [isLike, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(video?.totalLikes);
  const [commentText, setCommentText] = useState("");
  const [commentsList, setCommentsList] = useState(video?.comments);
  const userlike = useSelector((state) => state.video.like);
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  useEffect(() => {
    if (video?.tag == "Tutorial") {
      dispatch(addViewsTutorial({ id: video?.videoId }));
    } else if(video?.tag == "Conference"){
      dispatch(addViewsConference({ id: video?.videoId }));
    } else {
      dispatch(addViews({ id: video?.videoId }));
    }
  }, []);

  useEffect(() => {
    if (video?.likes.includes(currentUser)) {
      setLike(true);
    }
  }, [video?.likes]);

  const like = () => {
    if (video?.tag == "Tutorial") {
      dispatch(addLikeTutorial({ id: video?.videoId }));
    } else  if(video?.tag == "Conference"){
      dispatch(addLikeConference({ id: video?.videoId }));
    } else {
      dispatch(addLike({ id: video?.videoId }));
    }
    setLikeCount(likeCount + 1);
    setLike(true);
  };

  const disLike = () => {
    if (video?.tag == "Tutorial") {
      dispatch(disLikeTutorial({ id: video?.videoId }));
    } else if(video?.tag == "Conference"){
      dispatch(unLikeConference({ id: video?.videoId }));
    } else {
      dispatch(unLike({ id: video?.videoId }));
    }
    setLikeCount(likeCount - 1);
    setLike(false);
  };

  const handleChange = (event) => {
    setCommentText(event.target.value);
  };

  const postComments = () => {
    if (commentText == "") {
      alert("Please add your comment!");
      return;
    }
    if (video?.tag == "Tutorial") {
      dispatch(
        postCommentTutorial({ comment: commentText, id: video?.videoId })
      );
    } else  if(video?.tag == "Conference"){
      dispatch(postCommentConference({ comment: commentText, id: video?.videoId }));
    } else {
      dispatch(postComment({ comment: commentText, id: video?.videoId }));
    }
    setCommentText("");
  };

  useEffect(() => {
    setCommentsList(video?.comments);
  }, [video?.comments]);

  const renderPlayer = () => {

    if (video?.url && video?.urlType) {
      if (video?.urlType === "video") {
        return (
          // <IframeRender
          //   videoUrl={`https://player.vimeo.com/video/${video?.url}`}
          // />
          <iframe
            width={"100%"}
            height={"500px"}
            src={`https://player.vimeo.com/video/${video.url}`}
            title="Surgicshare video player"
            frameborder="0"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        );
      } else if (video?.urlType === "url") {
        var urlData = video.url;
        const urlArray = urlData.split("?");
        if(urlArray.length >= 2) {
          const urlId = urlArray[1].split("=");
          const modifiedUrl = `https://www.youtube.com/embed/${urlId[1]}`;
          return <iframe width={"100%"} height={"500px"} allow="fullscreen;" src={modifiedUrl}></iframe>;
        } else {
          return <iframe width={"100%"} height={"500px"} allow="fullscreen;" src={video.url}></iframe>;
        }
        // return <YouTubePlayer videoUrl={video?.url} />;
      } else if (
        video?.urlType === "ppt" ||
        video?.urlType === "pdf" ||
        video?.urlType === "document"
      ) {
        return (
          <iframe
            width={"100%"}
            height={"500px"}
            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${video?.url}`}
            title="Surgicshare document player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          // <IframeRender
          //   videoUrl={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${video?.url}`}
          // />
        );
      } else {
        return (
          <img
            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${video?.url}`}
            className="card-img-top"
            alt=""
          />
        );
      }
    } else {
      return (
        <img
          src="https://dummyimage.com/16:9x1080"
          className="card-img-top"
          alt=""
        />
      );
    }
  };

  return (
    <div class="row mt-2">
      <div class="col-12 col-xl-9">
        <div class="bg-white card rounded-3 mb-4 overflow-hidden">
          <div class="card-thumb-wrap card-thumb-lg bg-dark">
            {renderPlayer()}
          </div>
          <div class="card-header bg-white p-4 pb-0 border-0">
            <div class="col-12">
              <div>
                <span class="badge bg-video mb-2">{video?.tag}</span>
                <h2 class="h4 mb-3">{video?.title}</h2>
                <div class="d-lg-flex justify-content-lg-between">
                  <div class="author d-flex ">
                    <span class="user-image-wrap me-2">
                          {video?.userDetails?.profilePicture ? (
                            <img
                              src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${video?.userDetails?.profilePicture}`}
                              alt=""
                              title="Surgeon Profile"
                            />
                          ) : (
                            <img
                              src={require("../../../img/user.png")}
                              alt=""
                              title="Surgeon Profile"
                            />
                          )}
                    </span>
                    <span class="author-details">
                      <p class="fw-bold mb-0">
                        <Link to={`/surgeon-profile/${video.userDetails?._id}`}>
                          Dr.{" "}
                          {video?.userDetails?.firstName +
                            " " +
                            video?.userDetails?.lastName}
                        </Link>
                      </p>
                      <p class="text-muted mb-2">
                        {video?.userDetails?.institution?.instituteName +
                          ", " +
                          video?.userDetails?.country?.state?.city?.cityName}
                      </p>
                    </span>
                  </div>
                  <div class="like-comment-wrap">
                    <div class="btn-group">
                      {isLike ? (
                        <button
                          onClick={() => {
                            disLike();
                          }}
                          class="btn btn-danger"
                        >
                          <i class="fa-solid fa-heart me-1"></i>{" "}
                          {Number(likeCount)}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            like();
                          }}
                          class="btn btn-light"
                        >
                          <i class="fa-solid fa-heart me-1"></i>{" "}
                          {video?.totalLikes}
                        </button>
                      )}

                      <span class="btn btn-light">
                        <i class="fa-solid fa-message me-1"></i>{" "}
                        {video?.totalComments}
                      </span>
                      <span class="btn btn-light">
                        <i class="fa-solid fa-eye me-1"></i> {video?.totalviews}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body px-4 py-4">
            <p class="card-text">
              {video?.totalviews} views{" "}
              <i class="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
              {video?.createDate}
            </p>
            <p>
              <strong>{video?.discriptionName}:</strong> {video?.patientDetails}
            </p>

            <hr class="my-4" />
            <h5>Post your comment</h5>
            <textarea
              onChange={handleChange}
              value={commentText}
              name=""
              id=""
              cols=""
              rows="4"
              class="form-control mb-3"
              placeholder="Add your comment"
            ></textarea>
            <button
              title="Post Comment"
              class="btn btn-primary mb-2"
              onClick={() => {
                postComments();
              }}
            >
              {" "}
              <i class="fa-solid fa-check me-1"></i> Post Comment
            </button>
            <hr class="my-4" />
            <h5>{video?.totalComments} Comments</h5>

            {commentsList.length > 0 ? (
              commentsList.map((val, index) => (
                <div class="author comment-author d-flex mt-3">
                  <span class="user-image-wrap me-2">
                    {val?.profilePicture ? (
                      <img
                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.profilePicture}`}
                        alt=""
                        title="Surgeon Profile"
                      />
                    ) : (
                      <img
                        src={require("../../../img/user.png")}
                        alt=""
                        title="Surgeon Profile"
                      />
                    )}
                  </span>
                  <span class="author-details">
                    <p class="author-name mb-1">
                      <Link to={`/surgeon-profile/${val._id}`} class="fw-bold">
                        Dr. {val.userName}
                      </Link>{" "}
                      <i class="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
                      {timeAgo(val.dateTime)}
                    </p>
                    <p>{val.message}</p>
                  </span>
                </div>
              ))
            ) : (
              <p>No Comments Found!</p>
            )}
          </div>
        </div>
      </div>
 
      <div class="col-xl-3">
        {video?.tag == "Video" || video?.tag == "Tutorial" ? (
          <div class="d-grid gap-2 mb-4">
            <button class="btn btn-primary" onClick={()=> {navigate(`/requestTelementorship/${video?.userDetails?._id}`)}}>
              Request paid Telementorship, Mentorship & Online classes{" "}
              <i class="fa-solid fa-arrow-right"></i>
            </button>
            <Link to={`/support`} class="btn btn-light">
              Report Queries
              <i class="fa-solid fa-arrow-right"></i>
            </Link>
            {/* <a href="#request" class="btn btn-light">
              Request paid personalised training or observership{" "}
              <i class="fa-solid fa-arrow-right"></i>
            </a> */}
          </div>
          // null
        ) : (
          null
        )}

        {/* <div class="bg-white card rounded-3 mb-4">
          <div class="card-body">
            <h5>Share this {video?.tag}</h5>
            <p>Share this video with your friends and collegues.</p>
            <ul class="list-unstyled list-inline socials-list mb-0">
              <li class="list-inline-item">
                <a
                  href="https://facebook.com/surgicshare"
                  target="_blank"
                  title="Facebook"
                  class="text-primary fs-5"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://instagram.com/surgicshare"
                  target="_blank"
                  title="Instagram"
                  class="text-primary fs-5"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/surgic-share/"
                  target="_blank"
                  title="Linkedin"
                  class="text-primary fs-5"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://twitter.com/SurgicShare"
                  target="_blank"
                  title="Twitter"
                  class="text-primary fs-5"
                >
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Details;
