import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import {
  getRecordedConference,
  registerLiveEvents,
} from "../../../redux/actions/conference.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import {
  formatDate,
  formattTime,
  timeAgo,
  getStatusFromDate,
  isMeetingEnd,
} from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import {
  CLEAR_LOADING,
  SET_LOADING,
} from "../../../redux/constants/globalActionTypes";

// import Brochure from "../../../img/live_events/HSICON-2024-SCHEDULE.pdf";

const RecordedConference = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const [conferenceList, setConferenceList] = useState([]);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    const getConference = async () => {
      dispatch(getRecordedConference({ page: 1 }, true)).then((response) => {
        if (response.data.status.success) {
          const uniqueData = response.data.data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.event_id === item.event_id)
          );

          setConferenceList(uniqueData);
        }
      });
    };

    getConference();
  }, []);

  const handleOpenPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank"); // Opens the PDF in a new tab
  };

  return (
    <Root>
      <Breadcrumb
        title={"Recorded Conference"}
        fieldOne="Dashboard"
        fieldTwo="Recorded Conference"
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Recorded Conference</h2>
              <p>Total {conferenceList.length} Conference</p>
            </div>
            {/* <div className=" d-lg-block">
              <button
                title="Recording of Conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myCmeRtm");
                }}
              >
                Recording of Conference{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {conferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th className="">Actions</th>
                      </thead>
                      <tbody>
                        {conferenceList.map((conference, index) => (
                          <>
                            <tr>
                              <td className="fw-bold">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Topic:
                                </p>
                                <Link
                                  to={`/recorded-conference-workshop-details/${conference.event_id}`}
                                >
                                  <h6 className="mb-0">
                                    {conference?.main_title}
                                  </h6>
                                </Link>
                              </td>

                              <td className="text-left">
                                <button
                                  rel="noopener noreferrer"
                                  className="btn btn-primary mb-2 m-2 btn-sm"
                                  onClick={() =>
                                    handleOpenPdf(conference.brochure)
                                  }
                                >
                                  Brochure{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                                <>
                                  <Link
                                    to={`/recorded-conference-workshop-details/${conference.event_id}`}
                                    className="btn btn-primary m-2 btn-sm"
                                  >
                                    Details{" "}
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </Link>
                                </>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>

                    {/* Watch all */}
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default RecordedConference;
