import * as api from "../../services/api";
import { GET_ALL_GALLERY } from "../constants/homeActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";


export const getAllGalleries = (data, reset = false) => async (dispatch) => {

    dispatch({ type: SET_LOADING });

    try {
      const result = await api.getGallery(data);
      dispatch({
        type: GET_ALL_GALLERY,
        payload: result.data.data.docs
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
};
