import jwt_decode from "jwt-decode";
import { getCookie, clearCookie } from "../utils/core";

export const isLogin = () => {
  var token = JSON.parse(localStorage.getItem("token"));
  //  const token = getCookie("token");

  try {
    var decodedToken = jwt_decode(token, { complete: true });
    var dateNow = new Date().toISOString();
    var exp = new Date(decodedToken.exp * 1000).toISOString();

    if (exp > dateNow) {
      return true;
    } else {
      // clearCookie("token");
      localStorage.clear();
      return false;
    }
  } catch (error) {
    localStorage.clear();
    
    return false;
  }
};

export const logOut = (navigate) => {
  //    const token = getCookie("token");
  // clearCookie("token");
  localStorage.clear();
  navigate("/login");
};
