import React, { useEffect, useState } from "react";
import logo from "../../../img/logo.svg";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getDesignation,
  getYearOfEperience,
  getCountryCode
} from "../../../redux/actions/filter.action";
import { signUp } from "../../../redux/actions/auth.action";
import ContainerWrapper from "../../layouts/ContainerWrapper";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const specialityList = useSelector((state) => state.filters.speciality);
  const countryList = useSelector((state) => state.filters.countries);
  const countryCodes = useSelector((state) => state.filters.contryCodes);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const experienceList = useSelector((state) => state.filters.yearOfExperience);
  const designationList = useSelector((state) => state.filters.designation);

  useEffect(() => {
    dispatch(getCountryCode({}));
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
    // if (designationList.length === 0) {
    //   dispatch(getDesignation());
    // }
    // if (experienceList.length === 0) {
    //   dispatch(getYearOfEperience());
    // }
  }, []);

  const validation = Yup.object({
    firstname: Yup.string().min(3).max(25).required("Enter your Full Name"),
    // lastname: Yup.string().min(3).max(25).required("Enter your Last Name"),
    email: Yup.string().email().required("Enter your email"),
    mobile: Yup.string().required("Enter your mobile number"),
    speciality: Yup.string().required("Speciality is required"),
    // designation: Yup.string().required("Designation is required"),
    // yearExperience: Yup.string().required("Year of Experience is required"),
    // country: Yup.string().required("Country is required"),
    country_code: Yup.string().required("Phone Code is required"),
    // state: Yup.string().required("State is required"),
    // city: Yup.string().required("City is required"),
    // institution: Yup.string().required("Institution is required"),
    password: Yup.string().min(6).required("Enter Password"),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Password must match"),
    surgicshareConditions: Yup.boolean()
      .oneOf([true], "Please accept the Terms of Service")
      .required("Please accept the Terms of Service"),
    termsAndConditions: Yup.boolean()
      .oneOf([true], "Please accept the Terms of Service")
      .required("Please accept the Terms of Service"),
  });

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    speciality: "",
    // designation: "",
    // yearExperience: "",
    // country: "",
    country_code: "",
    // state: "",
    // city: "",
    // institution: "",
    password: "",
    confirm_password: "",
    termsAndConditions: false,
    surgicshareConditions: false,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validation,
      onSubmit: (values, action) => {
        let payload = {
          firstName: values.firstname,
          // lastName: values.lastname,
          speciality: values.speciality,
          // designation: values.designation,
          // yearsOfExperience: values.yearExperience,
          // country: values.country,
          country_code: values.country_code,
          // state: values.state,
          // city: values.city,
          // institution: values.institution,
          mobile: values.mobile,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirm_password,
        };
        if(values.lastname != "") {
          payload.lastName = values.lastname;
        }
        dispatch(signUp(payload, navigate)).then((res) => {
          if (res === "success") {
            action.resetForm();
          }
        });
        action.setSubmitting(false);
      },
    });

  // const onchangeCountry = (e) => {
  //   const id = e.target.value;
  //   dispatch(getStates({ country_id: id }));
  // };

  // const onchangeState = (e) => {
  //   const id = e.target.value;
  //   dispatch(getCities({ state_id: id }));
  // };

  // const onchangeCity = (e) => {
  //   const id = e.target.value;
  //   dispatch(getInstitute({ city_id: id }));
  // };


  return (
    <main className="main pt-0 bg-light">
      <ContainerWrapper>
        <Row>
          <Col xl={8} className="offset-xl-2">
            <Card className="bg-white rounded-3 mb-4 shadow-lg">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div className="text-center">
                    <img src={logo} alt="Logo" className="mb-4" />
                    <h4>Already a member?</h4>
                    <p>
                      Login now to explore all of our features and offerings.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="card-body pt-2 px-4 pb-4">
                <Col className="text-center">
                  <Button
                    onClick={() => navigate("/login")}
                    type="button"
                    className="btn btn-light btn-lg mb-2"
                  >
                    Login <i className="fa-solid fa-arrow-right"></i>
                  </Button>
                </Col>
              </Card.Body>
              <hr className="mb-0" />
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col className="text-center">
                  <div>
                    <h3 className="mb-1">Sign Up</h3>
                    <p>Fill the below form to sign up to Surgic Share.</p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Form className="row g-3 g-lg-4" onSubmit={handleSubmit}>
                  <Col md={6}>
                    <Form.Label for="">
                      Full Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.firstname && touched.firstname ? (
                      <p className="form-error text-danger">{errors.firstname}</p>
                    ) : null}
                  </Col>
                  {/* <Col md={6}>
                    <Form.Label for="">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.lastname && touched.lastname ? (
                      <p className="form-error text-danger">{errors.lastname}</p>
                    ) : null}
                  </Col> */}
                  <Col md={6}>
                    <Form.Label for="">
                      Email ID<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className="form-error text-danger">{errors.email}</p>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <Form.Label for="">
                      Phone Code<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="country_code"
                      id="country_code"
                      value={values.country_code}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {countryCodes.map((val, index) => (
                        <option key={index} value={val.phoneCode}>
                          {`+${val.phoneCode}`}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.country_code && touched.country_code ? (
                      <p className="form-error text-danger">{errors.country_code}</p>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <Form.Label for="">
                      Mobile No.<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="phone"
                      autoComplete="off"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.mobile && touched.mobile ? (
                      <p className="form-error text-danger">{errors.mobile}</p>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <Form.Label for="">
                      Speciality<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="speciality"
                      id="speciality"
                      value={values.speciality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {specialityList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.specialityName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.speciality && touched.speciality ? (
                      <p className="form-error text-danger">{errors.speciality}</p>
                    ) : null}
                  </Col>
                  {/* <Col md={4}>
                    <Form.Label for="">
                      Designation<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="designation"
                      id="designation"
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {designationList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.designationName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.designation && touched.designation ? (
                      <p className="form-error text-danger">{errors.designation}</p>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <Form.Label for="">
                      Years of experience
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="yearExperience"
                      id="yearExperience"
                      value={values.yearExperience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {experienceList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.yearsOfExperience}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.yearExperience && touched.yearExperience ? (
                      <p className="form-error text-danger">{errors.yearExperience}</p>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <Form.Label for="">
                      Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="country"
                      id="country"
                      value={values.country}
                      onChange={(event) => {
                        handleChange(event);
                        onchangeCountry(event);
                      }}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {countryList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.countryName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.country && touched.country ? (
                      <p className="form-error text-danger">{errors.country}</p>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <Form.Label for="">
                      State<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="state"
                      id="state"
                      value={values.state}
                      onChange={(event) => {
                        handleChange(event);
                        onchangeState(event);
                      }}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {stateList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.stateName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.state && touched.state ? (
                      <p className="form-error text-danger">{errors.state}</p>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <Form.Label for="">
                      City<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="city"
                      id="city"
                      value={values.city}
                      onChange={(event) => {
                        handleChange(event);
                        onchangeCity(event);
                      }}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {cityList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.cityName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.city && touched.city ? (
                      <p className="form-error text-danger">{errors.city}</p>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <Form.Label for="">
                      Hospital / Medical College/ Institute Name
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="institution"
                      id="institution"
                      value={values.institution}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Select</option>
                      {instituteList.map((val, index) => (
                        <option key={index} value={val._id}>
                          {val.instituteName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.institution && touched.institution ? (
                      <p className="form-error text-danger">{errors.institution}</p>
                    ) : null}
                  </Col> */}
                  <Col md={6}>
                    <Form.Label for="">
                      Password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <p className="form-error text-danger">{errors.password}</p>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <Form.Label for="">
                      Confirm Password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirm_password && touched.confirm_password ? (
                      <p className="form-error text-danger">{errors.confirm_password}</p>
                    ) : null}
                  </Col>

                  <Col md={12}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="surgicshareConditions"
                        value={values.surgicshareConditions} // You can use the value from the form state here
                        id="flexCheckChecked"
                        onChange={handleChange} // Add an onChange handler to update the form state
                      />
                      <Form.Label
                        className="form-check-label"
                        htmlFor="flexCheckChecked" // Use htmlFor instead of for for Form.Label
                      >
                        Surgic Share is digital platform for learning and sharing surgical skills only. This platform can't be used for any other purpose.
                      </Form.Label>
                    </div>
                    {errors.surgicshareConditions && touched.surgicshareConditions ? (
                      <p className="form-error text-danger">{errors.surgicshareConditions}</p>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="termsAndConditions"
                        value={values.termsAndConditions} // You can use the value from the form state here
                        id="flexCheckChecked"
                        onChange={handleChange} // Add an onChange handler to update the form state
                      />
                      <Form.Label
                        className="form-check-label"
                        htmlFor="flexCheckChecked" // Use htmlFor instead of for for Form.Label
                      >
                        I confirm that all my information is correct and I
                        accept{" "}
                        <a href="#" title="Terms of Service">
                          Terms of Service
                        </a>
                        .
                      </Form.Label>
                    </div>
                    {errors.termsAndConditions && touched.termsAndConditions ? (
                      <p className="form-error text-danger">{errors.termsAndConditions}</p>
                    ) : null}
                  </Col>
                  <Col className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-primary btn-lg mb-2"
                    >
                      Sign Up <i className="fa-solid fa-arrow-right"></i>
                    </Button>
                  </Col>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </main>
  );
};

export default Register;
