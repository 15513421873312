import React, { useEffect } from "react";
import logo from "../../../img/logo.svg";
import { Button, Card, CardFooter, Col, Container, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/TextField/TextField";
import { signIn } from "../../../redux/actions/auth.action";
import ContainerWrapper from "../../layouts/ContainerWrapper";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = Yup.object({
    email: Yup.string()
      .min(11, "Must have minimun 11 characters.")
      .required("email is required"),
    password: Yup.string()
      .min(3, "Must have minimun 6 characters")
      .required("Password field required"),
  });

  // useEffect(() => {
  //   const handlePreventNavigation = (event) => {
  //     event.preventDefault();
  //     window.history.pushState(null, null, window.location.href); // Push the current URL again to prevent navigation
  //   };

  //   const handleDisableRefresh = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("popstate", handlePreventNavigation);

  //   window.addEventListener("beforeunload", handleDisableRefresh);

  //   return () => {
  //     window.removeEventListener("popstate", handlePreventNavigation);
  //     window.removeEventListener("beforeunload", handleDisableRefresh);
  //   };
  // }, []);


  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if(token) {
      navigate('/dashboard')
    }
  }, [])

  return (
    <main className="main pt-0 bg-light">
      <ContainerWrapper>
        <Row>
          <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
            <Card className="bg-white rounded-3 mb-4 shadow-lg">

              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div className="text-center">
                       <img src={logo} alt="Logo" className="mb-4" />
                    <h4>New to Surgic Share?</h4>
                    <p>
                      Register now and explore surgery videos, tutorials,
                      associations, workshops, live surgeries and more.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Col className="text-center">
                  
                  <Button
                    onClick={() => navigate("/register")}
                    type="button"
                    className="btn btn-light btn-lg mb-2"
                  >
                    Register <i className="fa-solid fa-arrow-right"></i>
                  </Button>
                </Col>
              </Card.Body>
<hr className="mb-0" />
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col className="text-center">
                  <div>
                    <h3 className="mb-1">Login</h3>
                    <p>Enter your Email ID and Password to login.</p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validate}
                  onSubmit={async (values, { setSubmitting }) => {
                    await dispatch(signIn(values, navigate)).then((res) => {
                      if (res === "error") {
                        setSubmitting(false);
                      }
                    });
                  }}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={12}>
                        <label for="" className="form-label">
                          Email ID<span className="text-danger">*</span>
                        </label>
                        <TextField
                          type="text"
                          id="email"
                          name="email"
                          placeholder=""
                        />
                      </Col>
                      <Col md={12}>
                        <label for="" className="form-label">
                          Password<span className="text-danger">*</span>
                        </label>
                        <TextField
                          type="password"
                          id="password"
                          name="password"
                          placeholder=""
                          aria-describedby="password"
                        />
                        <div id="" className="form-text text-end">
                          <Link to="/forgot-password">
                            <>Forgot Password?</>
                          </Link>
                        </div>
                      </Col>
                      <Col className="text-center">
                        <Button
                          type="submit"
                          className="btn btn-primary btn-lg mb-2"
                          disabled={formik.isSubmitting}
                        >
                          Login <i className="fa-solid fa-arrow-right"></i>
                        </Button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
              <Card.Footer>
                <Col className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-primary btn-lg mb-2"
                      onClick={() => {navigate('/otp-login')}}
                    >
                      Login using OTP <i className="fa-solid fa-arrow-right"></i>
                    </Button>
                </Col>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </main>
  );
};

export default Login;
