import React, { useEffect, useState, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import {
  addAssociationUpdate,
  deleteAssociationUpdate,
  editAssociationUpdate,
  getAllAssociation,
  getAssociationById,
  getAssociationUpdate,
  joinAssociation,
} from "../../../redux/actions/association.action";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import logo from "../../../img/logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AssociationUpdates = () => {
  const { associationId } = useParams();
  const fileInputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });
  const [showPopup, setShowPopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingUpdate, setEditingUpdate] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const associationUpdate = useSelector(
    (state) => state.association.associationUpdate
  );
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const associationById = useSelector(
    (state) => state.association.associationById
  );

  const headerColumns = [
    {
      name: "Title",
      key: "title",
    },
    {
      name: "Description",
      key: "description",
    },
    {
      name: "Attachment",
      key: "attachment",
    },
  ];

  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(
      getAssociationUpdate({ page: pageNo, associationId: associationId })
    );
    dispatch(getAssociationById({ associationId: associationId })).then(
      (res) => {
        if (res.createdBy === currentUser) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  const handleEditClick = (update) => {
    setEditingUpdate(update);
    setShowPopup(true);
  };

  const handleDeleteClick = (data) => {
    dispatch(deleteAssociationUpdate({ associationUpdateId: data._id }));
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    // let requestData = {
    //   title: values.title,
    //   description: values.description,
    //   associationId: associationId,
    //   attachment: file
    // };

    const requestData = new FormData();

    requestData.append("title", values.title);
    requestData.append("description", values.description);
    requestData.append("associationId", associationId);
    requestData.append("attachment", file);

    if (editingUpdate) {
      requestData.append("associationUpdateId", editingUpdate._id);
      // dispatch(editAssociationUpdate({ ...requestData, associationUpdateId: editingUpdate._id }))
      dispatch(editAssociationUpdate(requestData))
        .then((res) => {
          if (res === "success") {
            resetForm();
          }
          setSubmitting(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setShowPopup(false);
          setSubmitting(false);
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
    } else {
      dispatch(addAssociationUpdate(requestData))
        .then((res) => {
          if (res === "success") {
            resetForm();
          }
          setSubmitting(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setShowPopup(false);
          setSubmitting(false);
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
    }
  };

  const fetchAssociation = () => {
    dispatch(
      getAssociationUpdate({ page: pageNo, associationId: associationId })
    );
  };

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  return (
    <Root>
      <Breadcrumb
        title={"Associations and Groups"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Latest News and Updates",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Latest News and Updates</h4>
              <p>Browse latest news and updates..</p>
            </div>
            <div className="d-lg-block">
              {isAdmin ? (
                <Button
                  title="Post Updates"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  Post Updates <i className="fa-solid fa-arrow-right"></i>
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {associationUpdate.length > 0 ? (
              <Card className="bg-white card rounded-3 mb-4">
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead>
                        {headerColumns.map((val, index) => (
                          <th key={val.key}>{val.name}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {associationUpdate.map((val, index) => (
                          <tr key={index}>
                              <td className="fw-bold">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Title:
                                </p>
                                <h6 className="mb-0">{val.title}</h6>
                              </td>
                              <td className="fw-bold">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Description:
                                </p>
                                <h6 className="mb-0">{val.description}</h6>
                              </td>
                              { val.attachment && val.attachment != "null" && val.attachment != "" ? (
                                 <td className="fw-bold">
                                    <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                      Attachment:
                                    </p>
                                    <a href={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.attachment}`} target="_blank">
                                      <h6 className="mb-0">View Attachment</h6>
                                    </a>
                                  </td>
                              ) : (<td> - </td>)}
                             
                            {isAdmin ? (
                              <td className="text-left text-lg-end">
                                <div>
                                  <Button
                                    onClick={() => handleEditClick(val)}
                                    className=" btn btn-primary mb-2 btn-sm me-1"
                                  >
                                    {" "}
                                    <i className="fa-solid fa-pencil"></i>
                                  </Button>
                                  <Button
                                    onClick={() => handleDeleteClick(val)}
                                    className=" btn btn-danger mb-2 btn-sm me-1"
                                  >
                                    {" "}
                                    <i className="fa-solid fa-trash"></i>
                                  </Button>
                                </div>
                              </td>
                            ) : (
                              null
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchAssociation}
          globalState={associationUpdate}
          hasMore={hasMore}
        />
      </ContainerWrapper>
      <Modal
        size="lg"
        show={showPopup}
        onHide={() => {
          setEditingUpdate(null);
          setShowPopup(false);
        }}
      >
        <Modal.Header className="complete-profile-modal-header">
          <img src={logo} alt="Logo" className="modal-logo" />
          <Modal.Title className="text-light h6">POST UPDATE</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            title: editingUpdate ? editingUpdate.title : "",
            description: editingUpdate ? editingUpdate.description : "",
            attachment: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger text-small"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group mt-3">
                      <label htmlFor="description">Description</label>
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger text-small"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group">
                      <label htmlFor="attachment">Attachment</label>
                      <input
                        type="file"
                        id="attachment"
                        name="attachment"
                        className="form-control"
                        onChange={handleFileInputChange}
                        ref={fileInputRef}
                      />
                      {/* <Field
                        type="file"
                        // id="attachment"
                        name="attachment"
                        className="form-control"
                        onChange={handleFileInputChange}
                        // ref={fileInputRef}
                      /> */}
                     
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="">
                <Button
                  type="submit"
                  className="complete-profile-button"
                  variant="primary"
                >
                  {editingUpdate ? "Update" : "Post Update"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Root>
  );
};

export default AssociationUpdates;
