import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { button, Card, Col, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate, useParams, Link } from "react-router-dom";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  exitAssociation,
  getAssociationDashboard,
} from "../../../redux/actions/association.action";
import { formatDate, formattTime, timeAgo, getStatusFromDate, getTimeFromDate } from "../../../utils/core";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import { registerToSurgery } from "../../../redux/actions/livesyrgery.action";
import moment from "moment";

import CryptoJS from 'crypto-js';

const AssociationGroupDetails = () => {
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  let { associationId } = useParams();
  localStorage.setItem("associationId", associationId)
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.association.dashboardData[0]);
  const associationUser = useSelector((state) => state.association.dashboardData[1]);

  const encrypted = CryptoJS.SHA1(process.env.REACT_APP_ASSOCIATION_SECRET).toString();


  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  useEffect(() => {
    dispatch(getAssociationDashboard({ associationId: associationId }));
  }, []);

  const handleExit = () => {
    dispatch(exitAssociation({ associationId: associationId }, navigate));
  };

  const registerLiveSurgery = (id) => {
    console.log("register clicked", id);
    dispatch(registerToSurgery({ id: id }));
  };

  const registerToConference = (id) => {
    dispatch(registerToConference({ id: id }));
  };


  return (
    <Root>
      <Breadcrumb
        title={"Associations and Groups"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Explore Associations and Groups",
          },
        ]}
      />
      <ContainerWrapper>
        <Row>
          <div className="col-12 mt-2">
            {/* <h2 className="h4 mb-1">{dashboardData?.association?.length > 0 ? (<>{dashboardData?.association[0]?.association_name}</>):("Explore the Association or Group")}</h2> */}
            <h2 className="h4 mb-1">{dashboardData?.associationName ? (<>{dashboardData?.associationName}</>):("Explore the Association or Group")}</h2>
            <p>
              Browse Association or Groups surgery videos, tutorials, attend
              live surgeries, CME and more.
            </p>
          </div>
        </Row>
        <Row className="d-flex align-items-stretch">
           <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Schedule or Attend CME on Zoom"
              className="d-flex align-items-stretch w-100"
              onClick={() => navigate(`/AssociationMeeting/${associationId}`)}
            > 
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/cme-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/cme.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>Organize/ Attend CME on Zoom</h6>
                {/* <button className="p-0 btn btn-link text-left">
                  Attend CME <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Schedule or Attend Live Surgery"
              className="d-flex align-items-stretch w-100"
              onClick={() =>
                navigate(`/AssociationLiveSurgery/${associationId}`)
              }
            >
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-livesurgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/live-surgery-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/live-surgery.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>Organize & Attend Live Surgery</h6>
                {/* <button className="p-0 btn btn-link text-left">
                  Live Surgery <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Watch or Post Surgery Videos"
              className="d-flex align-items-stretch w-100"
              onClick={() => navigate(`/AssociationVideos/${associationId}`)}
            >
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/videos-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/videos.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>Post/ Browse Surgery Videos</h6>
                {/* <button className="p-0 btn btn-link text-left">
                  Watch Videos <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Watch or Post Tutorials and Materials"
              className="d-flex align-items-stretch w-100"
              onClick={() => navigate(`/AssociationTutorial/${associationId}`)}
            >
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-livesurgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/tutorials-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/tutorials.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>
                  Post/ Browse Tutorials{" "}
                </h6>
                {/* <button className="p-0 btn btn-link text-left">
                  Watch Tutorials <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
         
          <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Recordings of Conference and Workshop"
              className="d-flex align-items-stretch w-100"
              onClick={() =>
                navigate(`/browse-recorded-conference/${associationId}`)
              }
            >
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/conference-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/conference.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>
                  Post/ Browse Recording of Live Surgery / Web CME
                </h6>
                {/* <button className="p-0 btn btn-link text-left">
                  View Recordings <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4} xl={2} className="col-6 d-flex align-items-stretch">
            <a
              href="javascript:void(0)"
              title="Association or Group Updates"
              className="d-flex align-items-stretch w-100"
              onClick={() => navigate(`/myAssocUpdate/${associationId}`)}
            >
              <div className="icon-box icon-box-dashboard icon-box-association icon-box-livesurgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-lg-4 w-100">
                <div className="showcas_flex">
                <img src={require("../../../img/updates-white.png")} alt="CME" width="44" className="d-none d-sm-inline-block"/>
                <img src={require("../../../img/updates.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/>
                <h6>
                  Association Update{" "}
                </h6>
                {/* <button className="p-0 btn btn-link text-left">
                  View Updates <i className="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
              </div>
            </a>
          </Col>
          <div className="col-12 mb-4">
            <div className="text-center">
              <a
                href="javascript:void(0)"
                title="View more"
                className="btn btn-light mb-2 me-2"
                onClick={() => navigate(`/associationMembers/${associationId}`)}
              >
                View Members <i className="fa-solid fa-arrow-right"></i>
              </a>
              {!associationUser?.isAdmin ? (
                <a
                  href="javascript:void(0)"
                  title="View more"
                  className="btn btn-danger mb-2"
                  onClick={() => handleExit()}
                >
                  <i className="fa-solid fa-right-from-bracket me-1"></i> Leave
                  Association/ Group
                </a>
              ) : (
                null
              )}
              
            </div>
          </div>
        </Row>
        <hr />
      </ContainerWrapper>

      {/* <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Latest Association or Group Videos</h2>
              <p>
                Latest surgery videos and tutorials of the Association or Group.
              </p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch">
            <Col
              sm={6}
              lg={6}
              xl={3}
              className="col-12 d-flex align-items-stretch"
            >
              <Card className="video-card  bg-white border w-100 mb-4">
                <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                  <div className="video-thumb-wrap">
                    <i className="fa-solid fa-play"></i>
                    <img
                      src="https://dummyimage.com/16:9x1080"
                      className="card-img-top"
                      alt="Laparoscopic Splenectomy"
                    />
                  </div>
                </a>
                <Card.Body className="px-3 px-lg-4 pt-3">
                  <span className="badge bg-video mb-2">Video</span>
                  <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                    <h6 className="card-title mb-1">
                      Laparoscopic Splenectomy
                    </h6>
                  </a>
                  <p className="card-text">
                    200 views{" "}
                    <i className="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
                    2 days ago
                  </p>
                  <div className="author d-flex ">
                    <span className="user-image-wrap me-2">
                      <img
                        src="assets/images/user.jpg"
                        alt="Surgeon Name"
                        width="44"
                      />
                    </span>
                    <span className="author-details">
                      <p className="fw-bold mb-0">
                        <a href="javascript:void(0)">Dr. John Doe</a>
                      </p>
                      <p className="text-muted mb-2">KMC, Manipal</p>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              sm={6}
              lg={6}
              xl={3}
              className="col-12 d-flex align-items-stretch"
            >
              <Card className="video-card  bg-white border w-100 mb-4">
                <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                  <div className="video-thumb-wrap">
                    <i className="fa-solid fa-play"></i>
                    <img
                      src="https://dummyimage.com/16:9x1080"
                      className="card-img-top"
                      alt="Laparoscopic Splenectomy"
                    />
                  </div>
                </a>
                <Card.Body className="px-3 px-lg-4 pt-3">
                  <span className="badge bg-recording mb-2">Recording</span>
                  <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                    <h6 className="card-title mb-1">
                      Laparoscopic Splenectomy
                    </h6>
                  </a>
                  <p className="card-text">
                    200 views{" "}
                    <i className="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
                    2 days ago
                  </p>
                  <div className="author d-flex ">
                    <span className="user-image-wrap me-2">
                      <img
                        src="assets/images/user.jpg"
                        alt="Surgeon Name"
                        width="44"
                      />
                    </span>
                    <span className="author-details">
                      <p className="fw-bold mb-0">
                        <a href="javascript:void(0)">Dr. John Doe</a>
                      </p>
                      <p className="text-muted mb-2">KMC, Manipal</p>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              sm={6}
              lg={6}
              xl={3}
              className="col-12 d-flex align-items-stretch"
            >
              <Card className="video-card  bg-white border w-100 mb-4">
                <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                  <div className="video-thumb-wrap">
                    <i className="fa-solid fa-play"></i>
                    <img
                      src="https://dummyimage.com/16:9x1080"
                      className="card-img-top"
                      alt="Laparoscopic Splenectomy"
                    />
                  </div>
                </a>
                <Card.Body className="px-3 px-lg-4 pt-3">
                  <span className="badge bg-video mb-2">Video</span>
                  <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                    <h6 className="card-title mb-1">
                      Laparoscopic Splenectomy second line of the video title
                    </h6>
                  </a>
                  <p className="card-text">
                    200 views{" "}
                    <i className="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
                    2 days ago
                  </p>
                  <div className="author d-inline-flex ">
                    <span className="user-image-wrap me-2">
                      <img
                        src="assets/images/user.jpg"
                        alt="Surgeon Name"
                        width="44"
                      />
                    </span>
                    <span className="author-details">
                      <p className="fw-bold mb-0">
                        <a href="javascript:void(0)">Dr. John Doe</a>
                      </p>
                      <p className="text-muted mb-2">KMC, Manipal</p>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              sm={6}
              lg={6}
              xl={3}
              className="col-12 d-flex align-items-stretch"
            >
              <div className="card video-card  bg-white-lg border w-100 mb-4">
                <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                  <div className="video-thumb-wrap">
                    <i className="fa-solid fa-play"></i>
                    <img
                      src="https://dummyimage.com/16:9x1080"
                      className="card-img-top"
                      alt="Laparoscopic Splenectomy"
                    />
                  </div>
                </a>
                <Card.Body className="px-3 px-lg-4 pt-3">
                  <span className="badge bg-tutorial mb-2">Tutorial</span>
                  <a href="javascript:void(0)" title="Laparoscopic Splenectomy">
                    <h6 className="card-title mb-1">
                      Laparoscopic Splenectomy
                    </h6>
                  </a>
                  <p className="card-text">
                    200 views{" "}
                    <i className="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
                    2 days ago
                  </p>
                  <div className="author d-flex ">
                    <span className="user-image-wrap me-2">
                      <img
                        src="assets/images/user.jpg"
                        alt="Surgeon Name"
                        width="44"
                      />
                    </span>
                    <span className="author-details">
                      <p className="fw-bold mb-0">
                        <a href="javascript:void(0)">Dr. John Doe</a>
                      </p>
                      <p className="text-muted mb-2">KMC, Manipal</p>
                    </span>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <div className="col-12 mb-4">
              <div className="text-center">
                <a
                  href="javascript:void(0)"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Videos <i className="fa-solid fa-arrow-right"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  title="View more"
                  className="btn btn-link mb-2"
                >
                  View More Tutorials{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section> */}



      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Upcoming Group CME &amp; RTM</h2>
              
              {dashboardData?.meetings?.length > 0 ? ( 
                <p>Attend Association or Group live CME &amp; RTM.</p>
              ) :(
                <p>No CME uploaded yet to this association/ group yet.</p>
              )}
            </div>
          </Row>
          <Row className="d-flex align-items-stretch">
            {dashboardData?.meetings?.length > 0 ? (
              <div className="col-12">
                <div className="bg-white px-4 py-3 rounded-3 border mb-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        <th>Status</th>
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {dashboardData.meetings.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.meetingDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  {val?.profilePicture ? (
                                      <img
                                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.profilePicture}`}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../img/user.png")}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    )}
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <Link to={`/surgeon-profile/${val.user_Id}`}>
                                      {`Dr. ${val.fullName}`}
                                    </Link>
                                  </p>
                                  <p className="text-muted mb-0">{`${val.institution}, ${val.city}`}</p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                            </td>
                            <td className="text-left text-lg-end">
                              {currentUser == val.user_details?._id ? (
                                val.startUrl != null ? (
                                  <a
                                    href={val.startUrl}
                                    target="_blank"
                                    className=" btn btn-primary mb-2 btn-sm"
                                  >
                                    Start{" "}
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                ) : (
                                  <span></span>
                                )
                              ) : val.joinUrl != null ? (
                                <a
                                  href={val.joinUrl}
                                  target="_blank"
                                  className=" btn btn-primary mb-2 btn-sm"
                                >
                                  Join{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              ) : (
                                <span></span>
                              )}

                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}?association=${encrypted}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-12 mb-4 mt-3">
              <div className="text-left">
                {/* <a
                  href="javascript:void(0)"
                  title="View more"
                  className="btn btn-primary mb-2"
                  onClick={() =>
                    navigate(`/scheduleLiveCmeRtm/${associationId}`)
                  }
                >
                  Schedule CME <i className="fa-solid fa-arrow-right"></i>
                </a> */}
                <a
                  href="javascript:void(0)"
                  title="View more"
                  className="btn btn-primary mb-2"
                  onClick={() =>
                    navigate(`/AssociationMeeting/${associationId}`)
                  }
                >
                  View More CME <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </Row>
        </div>
      </section>


      <section className="pb-4">
          <div className="container">
            <Row>
              <div className="col-12">
                <h2 className="h4 mb-1">Browse Live Surgery</h2>
                
                {dashboardData?.liveSurgery?.length > 0 ? ( 
                  <p>View upcoming Association or Group live surgeries.</p>
                ) :(
                  <p>No Live Surgery uploaded yet to this association/ group yet.</p>
                )}
              </div>
            </Row>
            <Row className="d-flex align-items-stretch">
              {dashboardData?.liveSurgery?.length > 0 ? (
                <>
                  {dashboardData.liveSurgery.map((val, index) => (
                    <Col
                      sm={6}
                      lg={6}
                      xl={3}
                      className=" d-flex align-items-stretch"
                    >
                      <ThumbnailCard
                        badgeClassName="bg-surgery"
                        badgeTitle="Live Surgery"
                        feeAmount={`Rs. ${val.fees}`}
                        cardTitle={val.title}
                        // duration={`${formatDate(val.startDate)} to ${formatDate(
                        //   val.endDate
                        // )}`}
                        duration={`${moment(val.startDate).format(
                          "D MMMM YYYY"
                        )} at ${getTimeFromDate(val.startDate)} to ${getTimeFromDate(val.endDate)}`}
                        location={`${val.liveSurgeryCity}, ${val.liveSurgeryState}, ${val.liveSurgeryCountry}`}
                        doctorName={`Dr. ${val.userName}`}
                        doctorAddress={`${val.userInstitution}, ${val.userCity}`}
                        buttonTitle={"Register Now"}
                        buttonAction={registerLiveSurgery}
                        id={val._id}
                        mediaType={val.urlType}
                        url={val.url}
                        doctorId={val.user_Id}
                        profilePicture={val.profilePicture}
                        detailsLink={`/surgeryDetails/${val._id}`}
                        isHost={false}
                        enroll={true}
                      />
                    </Col>
                  ))}
                </>
              ) : null}
              <div className="col-12 mb-4 mt-3">
                <div className="text-left">
                  <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-primary mb-2"
                    onClick={() =>
                      navigate(`/AssociationLiveSurgery/${associationId}`)
                    }
                  >
                    View More Live Surgeries{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                  {/* <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-link mb-2"
                    onClick={() =>
                      navigate(`/organizeLiveSurgery/${associationId}`)
                    }
                  >
                    Organize Live Surgery{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a> */}
                </div>
              </div>
            </Row>
            <hr />
          </div>
        </section>
     

     
        <section className="pb-4">
          <div className="container">
            <Row>
              <div className="col-12">
                <h4 className="mb-1">Browse Surgery Videos</h4>
                {dashboardData?.videos?.length > 0 ? ( 
                  <p>Browse Surgery Videos from top surgeons of your field.</p> 
                ) :(
                  <p>No videos uploaded yet to this association/ group yet.</p>
                )}
              </div>
            </Row>
          
                <Row className="d-flex align-items-stretch">
                  {dashboardData?.videos?.length > 0 ? (
                    <>
                      {dashboardData.videos.map((val, index) => (
                        <Col
                          sm={6}
                          lg={6}
                          xl={3}
                          className=" d-flex align-items-stretch"
                        >
                          <ThumbnailCard
                            badgeClassName="bg-video"
                            badgeTitle="Video"
                            cardTitle={val.title}
                            detailsLink={`/video-details/${val._id}`}
                            cardId={val._id}
                            doctorName={`Dr. ${val.fullName}`}
                            doctorAddress={`${val.institution}, ${val.city}`}
                            doctorId={val.user_Id}
                            viewsCount={`${val.views} Views`}
                            noOfDays={timeAgo(val.createdAt)}
                            mediaType={val.urlType}
                            profilePicture={val.profilePicture}
                            url={val.url}
                            isHost={false}
                          />
                        </Col>
                      ))}
                    </>
                   ) : null}

                  <div className="col-12 mb-4 mt-3">
                    <div className="text-left">
                      <a
                        href="javascript:void(0)"
                        title="View more"
                        className="btn btn-primary mb-2"
                        onClick={() =>
                          navigate(`/AssociationVideos/${associationId}`)
                        }
                      >
                        View More Surgery Videos{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </a>
                      {/* <a
                        href="javascript:void(0)"
                        title="View more"
                        className="btn btn-link mb-2"
                        onClick={() =>
                          navigate(`/uploadSurgeryVideos/${associationId}`)
                        }
                      >
                        Upload Surgery Video{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </a> */}
                    </div>
                  </div>
                </Row>
              
            <hr />
          </div>
        </section>
   

     
        <section className="pb-4">
          <div className="container">
            <Row>
              <div className="col-12">
                <h4 className="mb-1">Browse Tutorials</h4>
                {dashboardData?.tutorials?.length > 0 ? ( 
                  <p>Browse tutorials from top surgeons of your field.</p>
                ) :(
                  <p>No materials uploaded yet to this association/ group yet.</p>
                )}
                
              </div>
            </Row>
            <Row className="d-flex align-items-stretch">
              {dashboardData?.tutorials?.length > 0 ? (
                <>
                  {dashboardData.tutorials.map((val, index) => (
                    <Col
                      sm={6}
                      lg={6}
                      xl={3}
                      className=" d-flex align-items-stretch"
                    >
                      <ThumbnailCard
                        badgeClassName="bg-video"
                        badgeTitle="Tutorial"
                        cardTitle={val.title}
                        detailsLink={`/tutorial-details/${val._id}`}
                        cardId={val._id}
                        doctorName={`Dr. ${val.fullName}`}
                        doctorAddress={`${val.institution}, ${val.city}`}
                        doctorId={val.user_Id}
                        viewsCount={`${val.views} Views`}
                        noOfDays={timeAgo(val.createdAt)}
                        mediaType={val.urlType}
                        url={val.url}
                        profilePicture={val.profilePicture}
                        isHost={false}
                      />
                    </Col>
                  ))}
                </>
              ) : null}
              <div className="col-12 mb-4 mt-3">
                <div className="text-left">
                  <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-primary mb-2"
                    onClick={() =>
                      navigate(`/AssociationTutorial/${associationId}`)
                    }
                  >
                    View More Tutorial{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                  {/* <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-link mb-2"
                    onClick={() =>
                      navigate(`/uploadTutorials/${associationId}`)
                    }
                  >
                    Upload Tutorial <i className="fa-solid fa-arrow-right"></i>
                  </a> */}
                </div>
              </div>
            </Row>
            <hr />
          </div>
        </section>
 

   
        <section className="pb-4">
          <div className="container">
            <Row>
              <div className="col-12">
                <h4 className="mb-1">Browse Conference</h4>
                {dashboardData?.conferences?.length > 0 ? ( 
                  <p>Browse Conference, Workshop and Hands on Training in your field</p>
                ) :(
                  <p>No Conference uploaded yet to this association/ group yet.</p>
                )}
                
              </div>
            </Row>
            <Row className="d-flex align-items-stretch">
              {dashboardData?.conferences?.length > 0 ? (
                <>
                  {dashboardData.conferences.map((val, index) => (
                    <Col
                      sm={6}
                      lg={6}
                      xl={3}
                      className=" d-flex align-items-stretch"
                    >
                      <ThumbnailCard
                        badgeClassName="bg-workshop"
                        badgeTitle="WORKSHOP"
                        detailsLink={`/recorded-conference/${val._id}`}
                        feeAmount={`Rs. ${val.fees}`}
                        feeText="Starting from"
                        cardTitle={val.title}
                        duration={`${moment(val.conferenceDate).format(
                          "D MMMM YYYY"
                        )}`}
                        // location={`${val.country?.state.city.cityName}, ${val.country?.state.stateName}, ${val.country?.countryName}`}
                        location={`${val?.userCountry}, ${val?.userState}, ${val?.userCity}`}
                        doctorName={val?.userName}
                        doctorAddress={val?.userInstitution}
                        doctorId={val?.user_Id}
                        buttonTitle="RegisterNtow"
                        buttonAction={registerToConference}
                        id={val?._id}
                        profilePicture={val?.profilePicture}
                        isHost={false}
                      />
                    </Col>
                  ))}
                </>
              ) : null}

              <div className="col-12 mb-4 mt-3">
                <div className="text-left">
                  <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-primary mb-2"
                    onClick={() =>
                      navigate(`/browse-recorded-conference/${associationId}`)
                    }
                  >
                    View More Conference and Workshop{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                  {/* <a
                    href="javascript:void(0)"
                    title="View more"
                    className="btn btn-link mb-2"
                    onClick={() =>
                      navigate(`/postConfWorkshop/${associationId}`)
                    }
                  >
                    Post Conference <i className="fa-solid fa-arrow-right"></i>
                  </a> */}
                </div>
              </div>
            </Row>
            <hr />
          </div>
        </section>
   

   
    </Root>
  );
};

export default AssociationGroupDetails;
