import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  uploadTutorial,
  getTutorialTypes,
  getTutorialDetails,
  updateTutorial,
} from "../../../redux/actions/tutorial.action";
import {
  getSpeciality,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const UploadTutorial = () => {
  const fileInputRef = useRef(null);

  let { associationId } = useParams();
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const tutorialTypesList = useSelector(
    (state) => state.tutorial.tutorialTypes
  );

  const [isMeterialType, setMeterialType] = useState(true);
  const [isFileVisible, setFileVisible] = useState(true);
  const [isUrlVisible, setUrlVisible] = useState(false);
  const [fileError, setFileError] = useState("");
  const [isfileError, setisFileError] = useState(false);

  const tutorialById = useSelector((state) => state.tutorial.tutorialDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { id } = useParams();

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    dispatch(getTutorialTypes({}));
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getTutorialDetails({ id: id }));
    }
  }, []);

  useEffect(() => {
    if (tutorialById) {
      dispatch(
        getSubSpeciality({ speciality_id: tutorialById.speciality?._id })
      );
      if (tutorialById.tutorial_type == "648aeb5c8aed8c7861e92e3b") {
        setMeterialType(true);
        setFileVisible(false);
        setUrlVisible(false);
      }
      if (tutorialById.material_type == "2") {
        setFileVisible(false);
        setUrlVisible(true);
      } else {
        setFileVisible(true);
        setUrlVisible(false);
      }
    }
  }, [tutorialById]);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    }
  };

  const handleSubmit = (values, { setFieldValue, resetForm, setSubmitting }) => {
    const requestData = new FormData();

    requestData.append("speciality", values.speciality);

    if (values.sub_speciality && values.sub_speciality != "") {
      requestData.append("sub_speciality", values.sub_speciality);
    }


    requestData.append("title", values.title);
    requestData.append("description", values.description);
    requestData.append("tutorial_type", "648aeb5c8aed8c7861e92e3b");
    requestData.append("url", values.url);

    if (isFileVisible) {

      if (file == null && !tutorialById) {
        setisFileError(true);
        setFileError("File is required");
        return;
      } else if (!isMeterialType) {
        if (file?.type) {
          const fileType = file.type;
          const dataArray = fileType.split("/");
          if (dataArray[0] != "video") {
            setisFileError(true);
            setFileError("Choose only video file");
            return;
          } else {
            requestData.append("fileType", "VIDEO");
          }
        }

        // requestData.append("material_type", "");
      }


      if (isMeterialType && file) {
        const fileType = file.type;
        const dataArray = fileType.split("/");
        if (dataArray[0] != "video") {
          requestData.append("fileType", "OTHER");
        } else {
          requestData.append("fileType", "VIDEO");
        }
        requestData.append("material_type", values.meterialType);
      }

      requestData.append("file", file);
    } else {
      requestData.append("fileType", "OTHER");
      requestData.append("material_type", values.meterialType);
    }
    if (associationId) {
      requestData.append("assocId", associationId);
    }

    if (id && tutorialById) {
      requestData.append("tutorialId", id);
      dispatch(updateTutorial(requestData, navigate)).then((res) => {
        setSubmitting(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      });
    } else {
      dispatch(uploadTutorial(requestData, navigate)).then((res) => {
        setSubmitting(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      });
    }

    // Reset the form after submission
    setFile(null);
    resetForm();
  };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    // sub_speciality: Yup.string(),
    title: Yup.string().trim().required("Video title is required"),
    patient_details: Yup.string("Patient profile is required"),
    paid_telementorship: Yup.number(),
    paid_training: Yup.number(),
    attachment: Yup.mixed(),
    agreement: Yup.boolean().oneOf([true], "You must confirm the data"),
    // agreement2: Yup.boolean().oneOf([true], "You must confirm the data"),
  });

  const initialState = id && tutorialById
    ? {
      speciality: tutorialById.speciality?._id,
      sub_speciality: tutorialById.speciality.subSpeciality?._id,
      title: tutorialById.title,
      description: tutorialById.description,
      tutorialType: tutorialById.tutorial_type,
      url: tutorialById.url,
      meterialType: tutorialById.material_type,
      attachment: "",
      agreement: true,
    }
    : {
      speciality: "",
      sub_speciality: "",
      title: "",
      description: "",
      tutorialType: "648aeb5c8aed8c7861e92e3b",
      url: "",
      meterialType: "",
      attachment: "",
      agreement: false,
    };

  const onChangeTutorial = (event) => {
    const value = event.target.value;
    if (value == "648aeb5c8aed8c7861e92e3b") {
      setMeterialType(true);
      setFileVisible(false);
      setUrlVisible(false);
    } else {
      setMeterialType(false);
      setFileVisible(true);
      setUrlVisible(false);
    }
  };
  const onChangeMeterialType = (event) => {
    const value = event.target.value;
    if (value == "2") {
      setFileVisible(false);
      setUrlVisible(true);
    } else {
      setFileVisible(true);
      setUrlVisible(false);
    }
  };

  return (
    <Root>

      {associationId ? (
        <Breadcrumb
          title={"Tutorials"}
          items={[
            {
              name: "Association Dashboard", routeTo: `/AssociationDetails/${associationId}`,
            },
            {
              name: "Upload Tutorial",
            },
          ]}
        />
      ) : (
        <Breadcrumb
          title={"Tutorial"}
          fieldOne="Dashboard"
          fieldTwo="Upload Tutorial"
        />
      )}
      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Upload Tutorial</h2>
                    <p>
                      Fill in the below details and upload tutorial. Fields with{" "}
                      <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Subspeciality
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="sub_speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {subSpecialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.subSpecialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="sub_speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      {/* <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Tutorial Type<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="tutorialType"
                          className="form-select"
                          onChange={(e) => {
                            handleChange(e, formik.setFieldValue, dispatch);
                            onChangeTutorial(e);
                          }}
                        >
                          <option value="">Select</option>
                          {tutorialTypesList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.tutorialType}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="tutorialType"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col> */}

                      {isMeterialType ? (
                        <Col md={12}>
                          <ReactForm.Label className="form-label">
                            Tutorial Format<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="meterialType"
                            className="form-select"
                            onChange={(e) => {
                              handleChange(e, formik.setFieldValue, dispatch);
                              onChangeMeterialType(e);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="1">Video / PDF / Image </option>
                            <option value="2">Url</option>
                          </Field>
                          <ErrorMessage
                            name="meterialType"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                      ) : null}

                      {isUrlVisible ? (
                        <Col md={12}>
                          <ReactForm.Label className="form-label">
                            Url
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            type="text"
                            name="url"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="url"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                      ) : null}

                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Tutorial Title
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Description
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="description"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      {isFileVisible ? (
                        <Col md={12}>
                          <ReactForm.Label for="">
                            Select File<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <div className="input-group">
                            <input
                              type="file"
                              name="attachment"
                              className="form-control"
                              onChange={handleFileInputChange}
                              ref={fileInputRef}

                            />
                          </div>
                          {/* <Form.Text id="" className="form-text">
                            Supported files MP4, MOV, WMV, AVI, and FLV.
                          </Form.Text> */}
                          {isfileError ? (
                            <p className="text-danger text-small">
                              {fileError}
                            </p>
                          ) : null}
                        </Col>
                      ) : null}

                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement"
                          >
                            I take accountability of authenticity of the
                            tutorial video. Tutorial video is for academic
                            purpose only.
                          </label>
                          <ErrorMessage
                            name="agreement"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        Note: Tutorial uploaded will be published on website
                        within two working days, post basic check list.
                      </Col>
                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Upload Tutorial{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default UploadTutorial;
