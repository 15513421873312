import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { pdfjs } from 'react-pdf';
// Import App Component
import App from './App';

// Import Reducer
import { reducers } from './redux/reducers';
import { composeWithDevTools } from '@redux-devtools/extension';


const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

window.store = store;

ReactDOM.render (
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);
