import React, {useEffect} from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";


import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";
import { getAllGalleries } from "../../../redux/actions/home.action";
import { useDispatch, useSelector } from "react-redux";

const Gallery = () => {


  const dispatch = useDispatch();
  
  const galleryList = useSelector((state) => state.home.galleryList);

  useEffect(() => {
      dispatch(getAllGalleries({ page: 1 }));
  }, []);


  return (
    <PublicRoot>
      <Breadcrumb
        title={"Gallery"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Gallery",
          },
        ]}
      />

      <section className="bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Carousel>
                  {galleryList.length > 0 ? (
                    galleryList.map((val, index) => (
                      <Carousel.Item key={index}>
                        <div className="d-flex justify-content-center align-items-center">
                          <span
                            data-lightbox="gallery"
                            data-title={val.title}
                            className="mb-4 d-inline-block"
                          >
                            <img
                              src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.photo}`}
                              className="img-fluid rounded-3"
                              style={{ maxHeight: '100vh', maxWidth: '100%' }}
                              alt={val.title}
                            />
                          </span>
                        </div>
                      </Carousel.Item>
                    ))
                  ) : (
                    <Carousel.Item>
                      <div className="d-flex justify-content-center align-items-center">
                        <p>No images to display.</p>
                      </div>
                    </Carousel.Item>
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </section>
      <DownloadAndroidIOS/>
    </PublicRoot>
  );
};

export default Gallery;
