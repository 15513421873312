import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import "@yaireo/tagify/dist/tagify.css";
import { submitFeedback } from "../../../redux/actions/contact.action";

const ContactUs = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const initialValues = {
    title: "",
    description: "",
    attachment: null,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("attachment", values.attachment);

    dispatch(submitFeedback(formData, navigate)).then((res) => {
      if (res === "success") {
        resetForm();
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    });
    setSubmitting(false);
  };

  return (
    <Root>
      <Breadcrumb
        title={"Support"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Support",
          },
        ]}
      />

      <ContainerWrapper>
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">
                      Contact admin for feedback / suggestion
                    </h2>
                    <p>
                      Please submit your feedback/ Suggestion or Call us at:{" "}
                      <a href="tel:919971721564" title="Call Surgic Share">
                        {" "}
                        +91 99717 21564 or admin@surgicshare.com
                      </a>
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          id="title"
                          name="title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger text-small"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="textarea"
                          id="description"
                          name="description"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger text-small"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="attachment" className="form-label">
                          Attachment
                        </label>
                        <input
                          type="file"
                          id="attachment"
                          name="attachment"
                          onChange={(event) => {
                            setFieldValue(
                              "attachment",
                              event.currentTarget.files[0]
                            );
                          }}
                          className="form-control"
                          ref={fileInputRef}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        {" "}
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Send Enquiry
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default ContactUs;
