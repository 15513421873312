import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"

import {
  getAllLiveSurgery,
  registerToSurgery,
} from "../../../redux/actions/livesyrgery.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo, formatDate, getTimeFromDate } from "../../../utils/core";
import { RESET_LIVESURGERY } from "../../../redux/constants/livesurgeryActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import { getAllLiveSurgeryForAssociation } from "../../../redux/actions/association.action";

const AssociationLiveSurgery = () => {
  const { associationId } = useParams();

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const surgeryList = useSelector((state) => state.association.surgeryList);
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userData")
  );

  const isInitialMount = useRef(true);

  useEffect(() => {
    return async () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (associationId) {
      if (isInitialMount.current) {
        dispatch(
          getAllLiveSurgeryForAssociation({
            ...selectedFilter,
            page: pageNo,
            assocId: associationId,
          })
        );
        isInitialMount.current = false;
      } else {
        dispatch(
          getAllLiveSurgeryForAssociation(
            { ...selectedFilter, page: 1, assocId: associationId },
            true
          )
        );
      }
    } else {
      if (isInitialMount.current) {
        dispatch(getAllLiveSurgery({ ...selectedFilter, page: pageNo }));
        isInitialMount.current = false;
      } else {
        dispatch(getAllLiveSurgery({ ...selectedFilter, page: 1 }, true));
      }
    }
  }, [selectedFilter]);

  const fetchData = () => {
    if (associationId) {
      dispatch(
        getAllLiveSurgeryForAssociation({
          ...selectedFilter,
          page: pageNo,
          assocId: associationId,
        })
      );
    } else {
      dispatch(getAllLiveSurgery({ ...selectedFilter, page: pageNo }));
    }
  };

  const registerNow = (id) => {
    console.log("register clicked", id);
    dispatch(registerToSurgery({ id: id }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Live Surgery"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Browse Upcoming Live Surgeries",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">


            <div>
              <h4 className="mb-1">Browse Upcoming Live Surgeries</h4>
              <p>Browse upcoming live surgeries in your field</p>
            </div>
            <div className="d-lg-block">
              <button
                title="Organize Live Surgery"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/organizeLiveSurgery/${associationId}`);
                }}
              >
                Organize Live Surgery{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Attend Live Surgery"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/association-attend-livesurgery/${associationId}`);
                }}
              >
                Attend Live Surgery{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="My Upload Live Surgery"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/MyAssociationLiveSurgery/${associationId}`);
                }}
              >
                My Uploads{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>

          </Col>
        </Row>
        {/* <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>
          <Filters title={"Filter Live Surgeries"} />
        </div> */}
        <Row>
          {surgeryList.length > 0 ? (
            surgeryList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-surgery"
                  badgeTitle="Live Surgery"
                  feeAmount={`Rs. ${val.fees}`}
                  cardTitle={val.title}
                  // duration={`${formatDate(val.startDate)} to ${formatDate(
                  //   val.endDate
                  // )}`}
                  duration={`${moment(val.startDate).format(
                    "D MMMM YYYY"
                  )} at ${getTimeFromDate(val.startDate)} to ${getTimeFromDate(val.endDate)}`}
                  location={ val?.institution_text ? val?.institution_text : `${val.faculty_details?.country.state.city.cityName}, ${val.faculty_details?.country.state.stateName}, ${val.faculty_details?.country.countryName}`}
                  // location={""}
                  doctorName={`Dr. ${val.user_details.firstName} ${val.user_details.lastName}`}
                  doctorAddress={`${val.user_details.institution.instituteName}, ${val.user_details.country.state.city.cityName}`}
                  buttonTitle={"Register Now"}
                  buttonAction={registerNow}
                  id={val._id}
                  doctorId={`${val.user_details._id}`}
                  mediaType={val.urlType}
                  url={val.url}
                  profilePicture={val.user_details.profilePicture}
                  detailsLink={`/surgeryDetails/${val._id}`}
                  isHost={false}
                  enroll={true}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchData}
          globalState={surgeryList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default AssociationLiveSurgery;
