import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import {
  checkUserInZoom
} from "../../../redux/actions/meeting.action";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logo from "../../../img/logo.svg";


const ActivateZoom = () => {

   const dispatch = useDispatch();
   let navigate = useNavigate();

   const isLoading = useSelector((state) => state.globalState.globalLoading);


  const [id, setId] = useState(null);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(true);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams)
    setMessage(urlParams.get('message'));
    setId(urlParams.get('id'));
  }, [])


  const onConfirm = async () => {
     dispatch(checkUserInZoom({}, navigate)).then((result) => {});
  };

  
  return (

        <div style={{backgroundColor: "#000"}}>
          <Modal show={show}>
            <Modal.Header className="complete-profile-modal-header">
              <img src={logo} alt="Logo" className="modal-logo" />
              <Modal.Title className="text-light h6">{""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Steps to Follow</h4>
              <p><strong>Note:</strong> Below steps is followed only once.</p>
              <ol type="1" style={{fontSize: "14px"}}>
                <li>Open inbox of the same email used to login to Surgic Share.</li>
                <li>Open the latest email from Zoom and click "Approve Request".</li>
                <li>Confirm your approval.</li>
                <li>Return to Surgic Share to schedule the meeting.</li>
              </ol>
            </Modal.Body>
            <Modal.Footer>
              <Button  onClick={onConfirm}>
                OK
              </Button>
            </Modal.Footer>
            { isLoading ? <CustomLoader /> : null }
          </Modal>
        </div>
    
  );
};

export default ActivateZoom;
