import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getMyUploads } from "../../../redux/actions/meeting.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo, getStatusFromDate, dateFormatt } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";

const MyCmeRtm = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.meeting.myMeetingLists);
  const pageNo = useSelector((state) => state.meeting.page);
  const hasMore = useSelector((state) => state.meeting.hasMore);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getMyUploads({ page: 1 }, true));
  }, []);

  const fetchMeetings = () => {
    dispatch(getMyUploads({ page: pageNo }));
  };

  return (
    <Root>
      <Breadcrumb title={"CME (Continuing Medical Education)"} fieldOne="Dashboard" fieldTwo="My Uploads" />

      <ContainerWrapper>
        <Row>
          <Col>
            <div>
              <h4 className=" mb-1">My Uploads</h4>
              <p>Total {meetingsList.length} CME (Continuing Medical Education)</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white rounded-3 mb-4">
              <Card.Body className=" px-4 py-3">
                <div className="table-responsive">
                  <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      <th>Topic</th>
                      <th>Date &amp; Time</th>
                      <th>Attendees</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {meetingsList.length > 0 ? (
                        meetingsList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {dateFormatt(val.meetingDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Attendees:
                              </p>
                              <a
                                href="javascript:void(0)"
                                className="mb-0 fw-bold btn btn-link btn-sm text-decoration-underline"
                              >
                                {val.members.length}
                              </a>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                              {/* <span className="badge badge-info">{getStatusFromDate(val.startDate, val.endDate)}</span> */}
                            </td>
                            <td className="text-center text-lg-end">
                              <button
                                href="/edit-cme-rte/:id"
                                onClick={() => {
                                  navigate(`/edit-cme-rte/${val._id}`);
                                }}
                                className=" btn btn-info mb-2 btn-sm"
                              >
                                <i className="fa-solid fa-pencil me-1"></i> Edit
                              </button>
                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                      <InfiniteScroll
                        fetchFunction={fetchMeetings}
                        globalState={meetingsList}
                        hasMore={hasMore}
                      />
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default MyCmeRtm;
