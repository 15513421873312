import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from 'crypto-js';

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import { useNavigate, useParams, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo, getStatusFromDate } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { getAllAssociationMeetings } from "../../../redux/actions/association.action";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";

const AssociationCme = () => {
  const { associationId } = useParams();

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.association.meetingsList);
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );


  const encrypted = CryptoJS.SHA1(process.env.REACT_APP_ASSOCIATION_SECRET).toString();

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(
      getAllAssociationMeetings({ page: 1, assocId: associationId }, true)
    );
  }, []);

  const fetchMeetings = () => {
    dispatch(
      getAllAssociationMeetings({ page: pageNo, assocId: associationId })
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);
  return (
    <Root>
      {/* <Breadcrumb title={"CME (Continuing Medical Education)"} fieldOne="Dashboard" fieldTwo="Upcoming Live CME (Continuing Medical Education)" /> */}
      <Breadcrumb
        title={"CME (Continuing Medical Education)"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Attend CME/ RTM",
          },
        ]}
        
      />
      <ContainerWrapper>
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Upcoming Live CME &amp; RTM</h2>
              <p>Total {meetingsList.length} CME &amp; RTM</p>
            </div>
            <div className=" d-lg-block">
              <button
                title="Invitations"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/association-cme-invitation/${associationId}`);
                }}
              >
                Invitations <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="myuploads"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/myAssociationCmeRtm/${associationId}`);
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </button>
              
              <button
                title="Schedule CME (Continuing Medical Education)"
                className="btn btn-primary m-2 "
                onClick={() => {
                  navigate(`/scheduleLiveCmeRtm/${associationId}`);
                }}
              >
                Schedule CME (Continuing Medical Education) <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {meetingsList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        <th>Status</th>
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {meetingsList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.meetingDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  {val?.user_details?.profilePicture ? (
                                      <img
                                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.user_details?.profilePicture}`}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../img/user.png")}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    )}
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <Link to={`/surgeon-profile/${val.user_details._id}`}>
                                      {`Dr. ${val.user_details.firstName} ${val.user_details.lastName}`}
                                    </Link>
                                  </p>
                                  <p className="text-muted mb-0">{`${val.user_details.institution.instituteName}, ${val.user_details.country.state.city.cityName}`}</p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                            </td>
                            <td className="text-left text-lg-end">
                              {currentUser == val.user_details._id ? (
                                val.startUrl != null ? (
                                  <a
                                    href={val.startUrl}
                                    target="_blank"
                                    className=" btn btn-primary mb-2 btn-sm"
                                  >
                                    Start{" "}
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                ) : (
                                  <span></span>
                                )
                              ) : val.joinUrl != null ? (
                                <a
                                  href={val.joinUrl}
                                  target="_blank"
                                  className=" btn btn-primary mb-2 btn-sm"
                                >
                                  Join{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              ) : (
                                <span></span>
                              )}

                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}?association=${encrypted}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                        <InfiniteScroll
                          fetchFunction={fetchMeetings}
                          globalState={meetingsList}
                          hasMore={hasMore}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default AssociationCme;
