import React, { useEffect, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllTutorials } from "../../../redux/actions/tutorial.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo } from "../../../utils/core";
import { RESET_TUTORIAL } from "../../../redux/constants/tutorialActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { getAllAssociationTutorials } from "../../../redux/actions/association.action";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";

const AssociationTutorial = () => {
  const { associationId } = useParams();
  const isInitialMount = useRef(true);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const videosList = useSelector((state) => state.association.tutorialsList);
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    if (associationId) {
      if (isInitialMount.current) {
        dispatch(
          getAllAssociationTutorials({
            ...selectedFilter,
            page: pageNo,
            assocId: associationId,
          })
        );
        isInitialMount.current = false;
      } else {
        dispatch(
          getAllAssociationTutorials(
            { ...selectedFilter, page: 1, assocId: associationId },
            true
          )
        );
      }
    }
  }, [selectedFilter]);

  const fetchVideos = () => {
    dispatch(
      getAllAssociationTutorials({
        ...selectedFilter,
        page: pageNo,
        assocId: associationId,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  return (
    <Root>
      {/* <Breadcrumb
        title={"Tutorials"}
        fieldOne="Dashboard"
        fieldTwo="Browse Tutorials"
      /> */}

      <Breadcrumb
        title={"Tutorials"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Browse Tutorials",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Tutorials</h4>
              <p>Browse tutorials from top surgeons of your field.</p>
            </div>
            <div className="d-lg-block">
              <button
                title="Organize Live Surgery"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/uploadTutorials/${associationId}`);
                }}
              >
                Upload Tutorial <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="my uploads"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate(`/myAssociationTutorials/${associationId}`);
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>

          <Filters title="Filter Tutorials" module="observership" />
        </div>
        <Row>
          {videosList.length > 0 ? (
            videosList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-video"
                  badgeTitle="Tutorial"
                  cardTitle={val.title}
                  detailsLink={`/tutorial-details/${val._id}`}
                  cardId={val._id}
                  doctorName={`Dr. ${val?.user_details?.firstName} ${val?.user_details?.lastName}`}
                  doctorAddress={`${val?.user_details?.institution?.instituteName}, ${val?.user_details?.country?.state?.city?.cityName}`}
                  viewsCount={`${val.views} Views`}
                  noOfDays={timeAgo(val.createdAt)}
                  mediaType={val.urlType}
                  url={val.url}
                  isHost={false}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchVideos}
          globalState={videosList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default AssociationTutorial;
