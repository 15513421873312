import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {addNewPatient} from "../../../redux/actions/registry.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate, Link } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const AddPatient = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

  }, []);

  const handleChange = (e, setFieldValue, dispatch, resetForm) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSubmit = (values,{ setFieldValue, resetForm, setSubmitting }) => {
    const requestData = new FormData();
    requestData.append("full_name", values.patientName);
    requestData.append("age", values.patientAge);
    requestData.append("gender", values.patientGender);
    requestData.append("mobile", values.patientMobile);
    requestData.append("email", values.patientEmail);

    dispatch(addNewPatient(requestData, navigate)).then((res) => {
      if (res === "success") {
        resetForm();
      }
    });

    setSubmitting(false);
  };
  const initialState = {
    patientName: "",
    patientAge: "",
    patientGender: "",
    patientMobile: "",
    patientEmail: ""
  };

  const validationSchema = Yup.object({
    patientName: Yup.string().required("Name is required"),
    patientAge: Yup.string().required("Age is required"),
    patientGender: Yup.string().required("Gender is required"),
    patientMobile: Yup.string().required("Mobile is required"),
    patientEmail: Yup.string().required("Email is required"),

  });

  return (
    <Root>
      <Breadcrumb
        title={"Patient Registry"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Add New Patient",
          },
        ]}
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <div class="row mt-2">
                    <div class="col-12 d-lg-flex justify-content-lg-between">
                        <div>
                            <h2 class="h4 mb-1">Add New Patient</h2>
                            <p>Fill in the below details. Fields with <span class="text-danger">*</span> are mandatory.</p>
                        </div>
                        {/* <div class="d-none d-lg-block"> */}
                        <div class="">
                          <Link to="/view-patient" title="View Old Patients" class="btn btn-primary mt-2">View Old Patients <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={4}>
                        <ReactForm.Label for="">
                          Patient Name<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="patientName"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientName"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={4}>
                        <ReactForm.Label for="">
                          Age<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="number"
                          name="patientAge"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientAge"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Gender<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="patientGender"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                          
                        </Field>
                        <ErrorMessage
                          name="patientGender"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>


                      <Col md={6}>
                        <ReactForm.Label for="">
                          Mobile No.<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="number"
                          name="patientMobile"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientMobile"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>


                      <Col md={6}>
                        <ReactForm.Label for="">
                          Email ID.<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="email"
                          name="patientEmail"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientEmail"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      

                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          // disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save Data{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default AddPatient;
