import { GET_ALL_VIDEOS, ADD_VIDEO, SET_PERCENTAGE, MY_UPLOADS, GET_VIDEO_DETAILS, LIKE, DISLIKE, EDIT_VIDEO, RESET_VIDEOS } from "../constants/videoActionTypes";
import { removeDuplicate } from "../../utils/core";

const initialState = {
  videosList: [],
  myVideosList: [],
  videoDetails: "",
  like: 0,
  progress: 0,
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (video = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIDEOS:
      if (action.reset) {
        return {
          ...video,
          videosList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        const updatedVideosList = [
          ...video.videosList,
          ...action.payload,
        ];

        return {
          ...video,
          videosList: updatedVideosList,
          page: video.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_VIDEO:
      return {
        ...video,
        videosList: action.payload,
      };
    case SET_PERCENTAGE:
      return {
        ...video,
        progress: action.payload,
      };
    case MY_UPLOADS:
      return {
        ...video,
        myVideosList: action.payload,
        page: 1,
        hasMore: action.hasMore,
      };
    case GET_VIDEO_DETAILS:
      return {
        ...video,
        videoDetails: action.payload
      };
    case LIKE:
      if (action.payload.success) {
        return {
          ...video,
          like: 1
        };
      }
    case DISLIKE:
      if (action.payload.success) {
        return {
          ...video,
          like: -1
        };
      }
    case RESET_VIDEOS:
      return{
        ...initialState
      }

    default:
      return video;
  }
};
