import React, { useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllObserverShipById,
  registeredObservership,
  registerToObservership
} from "../../../redux/actions/observership.action";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import moment from "moment";
import { formatDate, getStatusFromDateString, isMeetingEnd } from "../../../utils/core";
import DetailsComponent from "../../components/DetailsComponent/DetailsComponent";
import { useParams } from "react-router-dom";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";

const ObservershipDetailScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const observershipById = useSelector(
    (state) => state.observership.observershipById
  );

  const [infoArray, setInfoArray] = useState([]);
  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getAllObserverShipById({ observershipId: id }));
  }, []);

  useEffect(() => {
    if (observershipById) {
      setInfoArray([
        { name: "Speciality", value: observershipById?.speciality },
        { name: "Subspeciality", value: observershipById?.subSpeciality },
        { name: "Slots Available", value: observershipById?.availableSlots },
        {
          name: "Date",
          value: `${formatDate(observershipById?.startDate)} to ${formatDate(
            observershipById?.endDate
          )}`,
        },
      ]);
    }
  }, [observershipById]);


   const [currentUser, setCurrentUser] = useState(localStorage.getItem('userId'));


   
  const renderFooterButton = () => {
    if(!isMeetingEnd(observershipById?.endDate)){
      if (currentUser == observershipById?.user_details?._id) {
           return <span class={`badge bg-cme mb-2`}>Host</span>;
      } else {
        let member = observershipById?.members?.find(
          (member) => member.user_id === currentUser
        );
        if (member?.transaction_status === "COMPLETED") {
          return <span class={`badge bg-cme mb-2`}>Registered</span>;
        } else {
          return (
            <>
              <button
                className=" btn btn-success mb-2 me-1"
                onClick={() => registerNow(observershipById?._id)}
              >
                <i className="fa-solid fa-check me-1"></i> Register Now
              </button>
            </>
          );
        }
      }
    } else {
      return <span class={`badge bg-cme mb-2`}>Ended</span>;
    }
  };


  const registerNow = (id) => {
    dispatch(registerToObservership({ id: id }));
  };


  return (
    <Root>
      <Breadcrumb
        title={"Attend Observership Courses"}
        fieldOne="Dashboard"
        fieldTwo="Observership Courses"
        fieldThree="Attend Observership Courses"
      />
      <ContainerWrapper>
        <DetailsComponent
          hideThumbNail={true}
          hideShareField={true}
          title={observershipById?.observershipName}
          infoArray={infoArray}
          module="Observership"
          badgeClassName="bg-observership"
          detailsTitle="Observership course details"
          detailsDescription={observershipById?.observershipDetail}
          facultyTitle={"Faculty"}
          facultyName={observershipById?.facultyName}
          facultyInstitution={observershipById?.facultyInstitute}
          fee={observershipById?.observershipFee}
          fileUrl={observershipById?.attachmentFile}
          buttonText="Register Now"
          buttonAction={() => {}}
          profilePicture={observershipById?.user_details?.profilePicture}
          facultyId={observershipById?.user_details?._id}
          transactionStatus={observershipById?.transaction_status}
          isHost={ observershipById?.user_details?._id == currentUser ? true : false}
          footerButton={renderFooterButton()}
        />

      </ContainerWrapper>
    </Root>
  );
};

export default ObservershipDetailScreen;
