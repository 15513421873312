export const GET_ALL_TUTORIAL = "GET_ALL_TUTORIAL";
export const GET_TUTORIAL_DETAILS = "GET_TUTORIAL_DETAILS";
export const ADD_TUTORIAL = "ADD_TUTORIAL";
export const EDIT_TUTORIAL = "EDIT_TUTORIAL";
export const MY_UPLOADS_TUTORIAL = "MY_UPLOADS_TUTORIAL";
export const VIEWS_TUTORIAL = "VIEWS_TUTORIAL";
export const LIKE_TUTORIAL = "LIKE_TUTORIAL";
export const DISLIKE_TUTORIAL = "DISLIKE_TUTORIAL";
export const COMMENT_TUTORIAL = "COMMENT_TUTORIAL";
export const SET_PERCENTAGE = "SET_PERCENTAGE";
export const GET_TYPES = "GET_TYPES";
export const RESET_TUTORIAL = "RESET_TUTORIAL";
