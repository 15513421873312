import React from 'react';
import './404Page.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="custom-container">
      <div className="custom-content">
        <div className="custom-text">
          <h1 className="custom-title">404</h1>
          <h2 className="custom-subtitle">Oops!</h2>
          <p className="custom-message">This is not the page you're looking for. The page might have been abducted by aliens or vanished into thin air.</p>
          <Button className="" onClick={()=>navigate("/dashboard")}>Go back to Safety</Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;