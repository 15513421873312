import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getAllObserverShipByFilter,
  registerToObservership,
} from "../../../redux/actions/observership.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { RESET_OBSERVERSHIP } from "../../../redux/constants/observershipActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { Link } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const BrowseObserverCourse = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const observershipList = useSelector(
    (state) => state.observership.observershipList
  );
  const pageNo = useSelector((state) => state.observership.page);
  const hasMore = useSelector((state) => state.observership.hasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const isInitialMount = useRef(true);

  const [currentUser, setCurrentUser] = useState(localStorage.getItem('userId'))


  useEffect(() => {
    return () => {
      dispatch({ type: RESET_OBSERVERSHIP });
    };
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (isInitialMount.current) {
      dispatch(getAllObserverShipByFilter({ ...selectedFilter, page: pageNo }));
      isInitialMount.current = false;
    } else {
      dispatch(
        getAllObserverShipByFilter({ ...selectedFilter, page: 1 }, true)
      );
    }
  }, [selectedFilter]);

  const fetchObservership = () => {
    dispatch(getAllObserverShipByFilter({ ...selectedFilter, page: pageNo }));
  };

  const registerNow = (id) => {
    dispatch(registerToObservership({ id: id }));
  };


  return (
    <Root>
      <Breadcrumb
        title={"Observership"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Browse Upcoming Observership",
          },
        ]}
      />

      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Upcoming Observerships</h4>
              <p>
                Browse Upcoming Observership Course in Your Area of Interest.
              </p>
            </div>
            <div className="d-lg-block">
              <Button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/postObservership");
                }}
              >
                Post Observership Course{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>
              <Button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myObservership");
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </Button>
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>

          <Filters title="Filter Observerships" module="observership" />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {observershipList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th WIDTH="30%">Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        <th>Fee</th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        {observershipList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6
                                // onClick={() =>
                                //   navigate(`/observershipDetails/${val._id}`)
                                // }
                                className="mb-0"
                              >
                                {val.observershipName}
                              </h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {moment(val.startDate).format("D MMMM YYYY")}{" "}
                                <br /> to{" "}
                                {moment(val.endDate).format("D MMMM YYYY")}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  {val?.user_details?.profilePicture ? (
                                      <img
                                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.user_details?.profilePicture}`}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../img/user.png")}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    )}
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <Link to={`/surgeon-profile/${val.user_details._id}`}>{`Dr. ${val.facultyName}`}</Link>
                                  </p>
                                  <p className="text-muted mb-0">
                                    {val.facultyInstitute} <br />{" "}
                                    {val.facultyCity}, {val.facultyState},{" "}
                                    {val.facultyCountry}
                                  </p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Fee
                              </p>
                               <h6 className="text-success">{val.observershipFee > 0 ? `Rs.${val.observershipFee}` : "Free"}</h6>

                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                                  {/* {
                                    val.transaction_status === "INIT" && val.user_details._id != currentUser ? (
                                      <Button
                                        className="btn-primary mb-2"
                                        onClick={() => registerNow(val._id)}
                                      >
                                        {val.transaction_status === "COMPLETED"
                                          ? "Registered"
                                          : val.transaction_status === "PROCESSING"
                                          ? "Processing"
                                          : "Register Now"}{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </Button>
                                    ) : ( 
                                      null 
                                    )
                                  } */}

                                  {
                                    val.transaction_status === "COMPLETED" ? (
                                      <span
                                        className={`badge mb-2 bg-success`}
                                      >
                                        Registered
                                      </span>
                                    ) : val.user_details._id == currentUser ?( 
                                      <span
                                        className={`badge mb-2 bg-warning`}
                                      >
                                        Host
                                      </span> 
                                    ) : (
                                      null
                                    )
                                  }

                                  {
                                    val.transaction_status === "PROCESSING" ? (
                                      <span
                                        className={`badge mb-2 bg-info`}
                                      >
                                        Processing...
                                      </span>
                                    ) : ( 
                                      null 
                                    )
                                  }
                                
                            </td>
                            <td>
                              {/* <button
                                onClick={() =>
                                  navigate(`/observershipDetails/${val._id}`)
                                }
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button> */}
                              <Button
                                className="btn-primary mb-2"
                                style={{width: "90px"}}
                                onClick={() => navigate(`/observershipDetails/${val?._id}`)}
                              >
                                View Details <i className="fa-solid fa-arrow-right"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                        <div className="d-flex">
                          <InfiniteScroll
                            fetchFunction={fetchObservership}
                            globalState={observershipList}
                            hasMore={hasMore}
                          />
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
        {/* <Row>
          {observershipList.length > 0 ? (
            observershipList.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className=" d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-observership "
                  badgeTitle="Observership "
                  feeAmount={`Rs. ${val.observershipFee}`}
                  cardTitle={val.observershipName}
                  duration={`${moment(val.startDate).format(
                    "D MMMM YYYY"
                  )} to ${moment(val.endDate).format("D MMMM YYYY")}`}
                  location={`${val.facultyCity}, ${val.facultyState}, ${val.facultyCountry}`}
                  doctorName={val.facultyName}
                  doctorAddress={val.facultyInstitute}
                  buttonTitle={
                    val.transaction_status === "COMPLETED"
                      ? "Registered"
                      : val.transaction_status === "PROCESSING"
                      ? "Processing"
                      : "Register Now"
                  }
                  buttonAction={registerNow}
                  buttonDisabed={["COMPLETED", "PROCESSING"].includes(
                    val.transaction_status
                  )}
                  buttonClassName={
                    val.transaction_status === "COMPLETED"
                      ? "bg-success"
                      : "bg-infobg-warning"
                  }
                  id={val._id}
                  detailsLink={`/observershipDetails/${val._id}`}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchObservership}
          globalState={observershipList}
          hasMore={hasMore}
        /> */}
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseObserverCourse;
