import { toast } from "react-toastify";
import * as api from "../../services/api";
import { doPayment } from "../../utils/payment";
import {
  ADD_OBSERVERSHIP,
  GET_ALL_OBSERVERSHIP,
  GET_ALL_OBSERVERSHIP_BY_FILTER,
  GET_ALL_OBSERVERSHIP_BY_ID,
  GET_MY_OBSERVERSHIP,
  REGISTERED_OBSERVERSHIP,
} from "../constants/observershipActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const getAllObserverShip = () => async (dispatch) => {
  try {
    const result = await api.getAllObserverShip();

    dispatch({ type: GET_ALL_OBSERVERSHIP, payload: result.data.data.data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllObserverShipByFilter =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllObserverShipByFilter(data);
      dispatch({
        type: GET_ALL_OBSERVERSHIP_BY_FILTER,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getAllObserverShipById = (data) => async (dispatch) => {

  try {
    const result = await api.getAllObserverShipById(data);

    dispatch({ type: GET_ALL_OBSERVERSHIP_BY_ID, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const addObserverShip = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.addObservership(data);
    if (result) {
      dispatch({ type: CLEAR_LOADING });

      toast.success("Observership Created Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_OBSERVERSHIP, payload: data });
      navigate("/myObservership")
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const registerToObservership = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.registerObservership(data);
    if (result) {
      if(result.data.data.isFree) {
          dispatch(getAllObserverShipById({ observershipId: data.id }));
          dispatch({ type: CLEAR_LOADING });
      } else {
        let request = result.data.data;
        request.type = "OBSERVERSHIP";
        await doPayment(request);
      }
      dispatch({ type: CLEAR_LOADING });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const registeredObservership =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.registeredObservership(data);
      if (result) {
        dispatch({
          type: REGISTERED_OBSERVERSHIP,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

export const getMyObservership =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getMyObservership(data);
      if (result) {
        dispatch({
          type: GET_MY_OBSERVERSHIP,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const updateObservership = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.updateObservership(data);
    if (result) {
      toast.success("Observership Updated Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      dispatch({ type: ADD_OBSERVERSHIP, payload: data });
      navigate("/myObservership")
    }
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
    toast.error("Error while Updating data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
