import API from "./axios";

//Auth API
export const authSignin = (data) =>
  API.post("/users/service-api/v1/users/signIn", data);
export const authSignup = (data) =>
  API.post("/users/service-api/v1/users/signUp", data);
export const authVerifyOTP = (data) =>
  API.post("/users/service-api/v1/users/verify-otp", data);
export const forgotPassword = (data) =>
  API.post("/users/service-api/v1/users/forgot-password", data);
export const resetPassword = (data) =>
  API.post("/users/service-api/v1/users/reset-password", data);
export const checkLink = (data) =>
  API.post("/users/service-api/v1/users/check-link", data);
export const changePassword = (data) =>
  API.post("/users/service-api/v1/users/change-password", data);
export const sendLoginOTP = (data) =>
  API.post("/users/service-api/v1/users/send-login-otp", data);
export const verifyLoginOTP = (data) =>
  API.post("/users/service-api/v1/users/verify-login-otp", data);

//--------------------------------------------------------------------------------------//

// Posts API
export const fetchPosts = () => API.get("/departments");
export const fetchCredential = () => API.get("/register/credentials");
//--------------------------------------------------------------------------------------//

//Filter Routes
export const fetchSpeciality = (data) =>
  API.post("/users/service-api/v1/users/filter/specialities", data);
export const fetchSubSpeciality = (data) =>
  API.post("/users/service-api/v1/users/filter/sub-specialities", data);
export const fetchCountries = (data) =>
  API.post("/users/service-api/v1/users/filter/countries", data);
export const fetchStates = (data) =>
  API.post("/users/service-api/v1/users/filter/states", data);
export const fetchCities = (data) =>
  API.post("/users/service-api/v1/users/filter/cities", data);
export const fetchInstitute = (data) =>
  API.post("/users/service-api/v1/users/filter/institutions", data);
export const fetchFaculty = (data) =>
  API.post("/users/service-api/v1/users/find-user-by-institute-id", data);
export const fetchFacultyByInstSpeciality = (data) =>
  API.post(
    "/users/service-api/v1/users/find-user-by-speciality-institute",
    data
  );
export const fetchDesignation = (data) =>
  API.post("/users/service-api/v1/users/all-designations", data);
export const fetchQualification = (data) =>
  API.post("/users/service-api/v1/users/all-qualifications", data);
export const fetchExperience = (data) =>
  API.post("/users/service-api/v1/users/all-years-of-experience", data);
export const fetchRequestType = (data) =>
  API.post("/users/service-api/v1/users/mentorship/list-requests", data);
export const fetchCountryCode = (data) =>
  API.post("/users/service-api/v1/users/location/country-code", data);

//observership
export const getAllObserverShip = (data) =>
  API.get("/users/service-api/v1/users/observership/all-obervership", data);
export const getAllObserverShipByFilter = (data) =>
  API.post(
    "/users/service-api/v1/users/observership/filter-observership",
    data
  );
export const addObservership = (data) =>
  API.post("/users/service-api/v1/users/observership/add-obervership", data);
export const getMyObservership = (data) =>
  API.post("/users/service-api/v1/users/observership/my-observership", data);
export const registerObservership = (data) =>
  API.post("/users/service-api/v1/users/observership/register", data);
export const registeredObservership = (data) =>
  API.post(
    "/users/service-api/v1/users/observership/registered-observership",
    data
  );
export const getAllObserverShipById = (data) =>
  API.post(
    "/users/service-api/v1/users/observership/find-observership-by-id",
    data
  );
export const updateObservership = (data) =>
  API.post("/users/service-api/v1/users/observership/edit-observership", data);

//association and group
export const getAssociationType = (data) =>
  API.post("/users/service-api/v1/users/association/get-group-type", data);
export const getAssociationDropDown = (data) =>
  API.post(
    "/users/service-api/v1/users/association/get-association-level",
    data
  );
export const getAllAssociation = (data) =>
  API.post("/users/service-api/v1/users/association/filter-associations", data);
export const addAssociation = (data) =>
  API.post("/users/service-api/v1/users/association/create-association", data);
export const joinAssociation = (data) =>
  API.post("/users/service-api/v1/users/association/join-association", data);
export const exitAssociation = (data) =>
  API.post("/users/service-api/v1/users/association/exit-association", data);
export const getMyAssociation = (data) =>
  API.post(
    "/users/service-api/v1/users/association/get-user-associations",
    data
  );
export const getAssociationById = (data) =>
  API.post(
    "/users/service-api/v1/users/association/get-association-by-id",
    data
  );
export const updateAssociation = (data) =>
  API.post("/users/service-api/v1/users/association/edit-association", data);
export const browseAssociationLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/association/get-all", data);
export const browseAssociationConference = (data) =>
  API.post("/meetings/service-api/v1/conference/association/get-all", data);
export const browseAssociationMeetings = (data) =>
  API.post("/meetings/service-api/v1/meeting/association/get-all", data);
export const browseAssociationVideo = (data) =>
  API.post("/videos/service-api/v1/videos/association/get-all", data);
export const browseAssociationTutorial = (data) =>
  API.post(
    `/videos/service-api/v1/tutorials/association/get-all?page=${data.page}`,
    data
  );
export const getAssociationDashboard = (data) =>
  API.post(
    "/users/service-api/v1/users/association/association-dashboard",
    data
  );
export const getAllClosedAssociation = (data) =>
  API.post(
    "/users/service-api/v1/users/association/filter-closed-associations",
    data
  );

//post
export const postAssociationLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/association/upload", data);
export const postAssociationConference = (data) =>
  API.post("/meetings/service-api/v1/conference/association/upload", data);
export const postAssociationCme = (data) =>
  API.post("/meetings/service-api/v1/meeting/association/upload", data);
export const postAssociationTutorial = (data) =>
  API.post("/videos/service-api/v1/tutorials/association/upload", data);
export const postAssociationSurgeryVideo = (data) =>
  API.post("/videos/service-api/v1/videos/association/upload", data);

export const myAssociationTutorial = (data) =>
  API.get("/videos/service-api/v1/tutorials/association/my-uploads", data);
export const myAssociationVideos = (data) =>
  API.get("/videos/service-api/v1/videos/association/my-uploads", data);
export const myAssociationLiveSurgery = (data) =>
  API.post(
    "/meetings/service-api/v1/live-surgery/association/my-uploads",
    data
  );
export const myAssociationCme = (data) =>
  API.get("/meetings/service-api/v1/meeting/association/my-uploads", data);
export const myAssociationConference = (data) =>
  API.get("/meetings/service-api/v1/conference/association/my-uploads", data);

export const getAssociationUpdate = (data) =>
  API.post(
    "/users/service-api/v1/users/association/get-association-update",
    data
  );
export const postAssociationUpdate = (data) =>
  API.post(
    "/users/service-api/v1/users/association/add-association-update",
    data
  );
export const editAssociationUpdate = (data) =>
  API.post(
    "/users/service-api/v1/users/association/edit-association-update",
    data
  );
export const deleteAssociationUpdate = (data) =>
  API.post(
    "/users/service-api/v1/users/association/delete-association-update",
    data
  );

export const listAssociationMembers = (data) =>
  API.post(
    "/users/service-api/v1/users/association/get-association-members",
    data
  );

//Videos
export const getAllVideos = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/get-all?page=${data.page}`,
    data
  );
export const uploadVideo = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/upload`,
    data
  );
export const getMyUploads = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/my-uploads`,
    data
  );
export const getVideoDetails = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/get`,
    data
  );
export const addLike = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/like?videoId=${data.id}`
  );
export const disLike = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/dislike?videoId=${data.id}`
  );
export const addViews = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/views?videoId=${data.id}`
  );
export const postComment = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/post-comment?videoId=${data.id}`,
    data
  );
export const editVideo = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/update`,
    data
  );
export const deleteVideo = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/videos/delete`,
    data
  );

//conference
// export const getAllConference = (data) => API.post('/users/service-api/v1/users/conference/get-all',data);
// export const addNewConference = (data) => API.post('/users/service-api/v1/association/get-association-level',data);

//live Surgery
export const getAllLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/get-all", data);
export const addLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/upload", data);
export const updateLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/update", data);
export const getLiveSurgeryById = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/get", data);
export const getMyLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/my-uploads", data);
export const getMyRegisteredLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/my-registered", data);
export const registerNow = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/register", data);
export const joinLiveSurgery = (data) =>
  API.post("/meetings/service-api/v1/live-surgery/join", data);

//patient referral
export const getAllSeniorSurgeons = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/referral/surgeons",
    data
  );
export const patientReferralMakePayment = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/profile/make-payment",
    data
  );
export const patientReferralUpdateSchedule = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/profile/update-schedule",
    data
  );

export const patientReferralProfile = (data) =>
  API.post("/users/service-api/v1/users/patient-referrals/profile", data);
export const updatePublicProfile = (data) =>
  API.post("/users/service-api/v1/users/public-profile", data);
export const updatePersonalProfile = (data) =>
  API.post("/users/service-api/v1/users/personal-profile", data);
export const getSurgeonsLocation = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/referral/surgeon-location",
    data
  );
export const createPatientInfo = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/referral/refer-my-patient",
    data
  );
export const getSurgeonsProfile = (data) =>
  API.post("/users/service-api/v1/users/profile/public", data);
export const getPatientReffered = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/referral/reffered",
    data
  );
export const getPatientRefferalReceived = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-referrals/referral/referral-received",
    data
  );

//Tutorial
export const getAllTutorials = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/get-all?page=${data.page}`,
    data
  );
export const uploadTutorial = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/upload`,
    data
  );
export const getMyUploadTutorial = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/my-uploads`,
    data
  );
export const getTutorialDetails = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/get`,
    data
  );
export const addLikeTutorial = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/like?tutorialId=${data.id}`
  );
export const disLikeTutorial = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/dislike?tutorialId=${data.id}`
  );
export const addViewsTutorial = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/views?tutorialId=${data.id}`
  );
export const postCommentTutorial = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/post-comment?tutorialId=${data.id}`,
    data
  );
export const getTutorialTypes = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/list-tutorial-type`
  );
export const updateTutorial = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/update`,
    data
  );
export const deleteTutorial = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/tutorials/delete`,
    data
  );

//CME RTM
export const getAllMeetings = (data) =>
  API.post("/meetings/service-api/v1/meeting/get-all", data);
export const addMeeting = (data) =>
  API.post("/meetings/service-api/v1/meeting/upload", data);
export const updateMeeting = (data) =>
  API.post("/meetings/service-api/v1/meeting/update", data);
export const getMeetingDetails = (data) =>
  API.post("/meetings/service-api/v1/meeting/get", data);
export const getMyMeetings = (data) =>
  API.post("/meetings/service-api/v1/meeting/my-uploads", data);
export const getMeetingInvitations = (data) =>
  API.post("/meetings/service-api/v1/meeting/my-invitations", data);
export const acceptMeeting = (data) =>
  API.post("/meetings/service-api/v1/meeting/accept-reject", data);
export const joinMeeting = (data) =>
  API.post("/meetings/service-api/v1/meeting/join", data);
export const checkUserInZoom = (data) =>
  API.post("/meetings/service-api/v1/meeting/get-zoom-user", data);

// Conference & Workshop
export const getAllConference = (data) =>
  API.post("/meetings/service-api/v1/conference/get-all", data);
export const getAllConferenceByFilter = (data) =>
  API.post("/meetings/service-api/v1/conference/get-all", data);
export const registerConterence = (data) =>
  API.post("/meetings/service-api/v1/conference/register", data);
export const attendConference = (data) =>
  API.post("/meetings/service-api/v1/conference/my-registered", data);
export const myConference = (data) =>
  API.post("/meetings/service-api/v1/conference/my-uploads", data);
export const addConference = (data) =>
  API.post("/meetings/service-api/v1/conference/upload", data);
export const updateConference = (data) =>
  API.post("/meetings/service-api/v1/conference/update", data);
export const getConferenceById = (data) =>
  API.post("/meetings/service-api/v1/conference/get", data);
export const SedBulkInvitation = (data) =>
  API.post("/meetings/service-api/v1/conference/invite-to-conference", data);

//Recorded Conference
export const getAllRecordedConference = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/get-all`,
    data
  );
export const uploadRecordedConference = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/upload`,
    data
  );
export const getMyRecordedConference = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/my-uploads`,
    data
  );
export const getRecordedConferenceDetails = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/get`,
    data
  );
export const addLikeRecordedConference = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/like?conferenceId=${data.id}`
  );
export const disLikeRecordedConference = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/dislike?conferenceId=${data.id}`
  );
export const addViewsRecordedConference = (data) =>
  API.get(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/views?conferenceId=${data.id}`
  );
export const postCommentRecordedConference = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/post-comment?conferenceId=${data.id}`,
    data
  );
export const editRecordedConference = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/update`,
    data
  );
export const deleteRecordedConference = (data) =>
  API.post(
    `${process.env.REACT_APP_API_URL_MAIN}/videos/service-api/v1/association/group-conference/delete`,
    data
  );

// Dashboard
export const getAllDashboard = (data) =>
  API.post("/users/service-api/v1/users/dashboard", data);
export const getAssociationMeeting = (data) =>
  API.post("/users/service-api/v1/users/association-meetings", data);

//noification
export const getNotification = (data) =>
  API.post("/notifications/service-api/v1/notification", data);
export const getAllNotification = (data) =>
  API.post("/notifications/service-api/v1/notification/view-all", data);
export const markAsReadNotification = (data) =>
  API.post("/notifications/service-api/v1/notification/mark-as-read", data);

// mentorship
export const requestMentorship = (data) =>
  API.post("/users/service-api/v1/users/mentorship/add-mentorship", data);
export const getMentorshipById = (data) =>
  API.post("/users/service-api/v1/users/mentorship/get-mentorship-by-id", data);
export const getRequestedMentorship = (data) =>
  API.post(
    "/users/service-api/v1/users/mentorship/get-requested-mentorship",
    data
  );
export const getUserById = (data) =>
  API.post("/users/service-api/v1/users/find-user-by-id", data);

// Patient Registry
export const addNewPatient = (data) =>
  API.post("/users/service-api/v1/users/patient-registry/add-patient", data);
export const addPatientProcedure = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-registry/add-surgical-procedure",
    data
  );
export const getAllPatients = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-registry/list-all-patients",
    data
  );
export const getPatientProcedure = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-registry/list-all-surgical-procedure",
    data
  );
export const getProcedureList = (data) =>
  API.post(
    "/users/service-api/v1/users/patient-registry/list-patient-surgical-procedure",
    data
  );
export const makePayment = (data) =>
  API.post("/users/service-api/v1/users/patient-registry/make-payment", data);

//contact
export const addFeedBack = (data) =>
  API.post("/users/service-api/v1/users/contact/add-message", data);
export const listFeedBack = (data) =>
  API.post("/users/service-api/v1/users/contact/list-message", data);

//Gallery
export const getGallery = (data) =>
  API.post("/users/service-api/v1/users/home/view-gallery", data);

//Live Events
export const getLiveEvents = (data) =>
  API.post("/users/service-api/v1/users/live-events/list-all", data);
export const registerLiveEvents = (data) =>
  API.post("/users/service-api/v1/users/live-events/register", data);
export const getRecordedConference = (data) =>
  API.post("/users/service-api/v1/users/live-events/get-event-recording", data);

//Complete Profile
export const checkProfileComplete = (data) =>
  API.post("/users/service-api/v1/users/profile-completion", data);
