import React from 'react';

const LayoutPage = ({ children }) => {
  return (
    <main class="main">
      <div className="layout-page">
        {children}
      </div>
    </main>
  )
}

export default LayoutPage;