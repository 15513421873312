export const GET_FILTER_SPECIALITY = "GET_FILTER_SPECIALITY";
export const GET_FILTER_SUB_SPECIALITY = "GET_FILTER_SUB_SPECIALITY";
export const GET_FILTER_COUNTRY = "GET_FILTER_COUNTRY";
export const GET_FILTER_STATES = "GET_FILTER_STATES";
export const GET_FILTER_CITIES = "GET_FILTER_CITIES";
export const GET_FILTER_INSTITUTE = "GET_FILTER_INSTITUTE";

export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const GET_FACULTY = "GET_FACULTY";
export const GET_FACULTY_SPECIALITY = "GET_FACULTY_SPECIALITY";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const GET_YEAR_OF_EXPERIENCE = "GET_YEAR_OF_EXPERIENCE";
export const GET_QUALIFICATION = "GET_QUALIFICATION";
export const GET_REQUEST_TYPE = "GET_REQUEST_TYPE";
export const GET_COUNTRY_CODE = "GET_COUNTRY_CODE";


