import { combineReducers } from 'redux';

import auth from './auth.reducer';
import posts from './posts.reducer';
import credentials from './credentials.reducer';
import filters from './filter.reducer';
import observership from './observership.reducer';
import association from './association.reducer';
import video from './video.reducer';
import tutorial from './tutorial.reducer';
import meeting from './meeting.reducer';
import patientReferral from './patientReferral.reducer';
import conference from './conference.reducer';
import livesurgery from './livesurgery.reducer';
import globalState from './globalReducer';
import dashboard from './dashboard.reducer';
import notification  from './notificationReducer';
import mentorship  from './mentorship.reducer';
import registry  from './registry.reducer';
import home  from './home.reducer';
import recordedConference  from './recorded.conference.reducer';





export const reducers = combineReducers({ 
    posts,
    credentials,
    auth,
    filters,
    observership,
    association,
    video,
    tutorial,
    meeting,
    patientReferral,
    conference,
    livesurgery,
    globalState,
    dashboard,
    notification,
    mentorship,
    registry,
    home,
    recordedConference
});