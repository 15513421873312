import { toast } from "react-toastify";
import { SET_PERCENTAGE } from "../../redux/constants/videoActionTypes";
import axios from "axios";
import { CLEAR_LOADING } from "../../redux/constants/globalActionTypes";

export const UploadVideo = async (file,dispatch) => {
  try {
    return new Promise((resolve) => {
      const body = {
        upload: {
          approach: "tus",
          size: file.size,
        },
        name: file.name,
        // privacy: {
        //   view: "nobody",
        // },
      };
      axios
        .post("https://api.vimeo.com/me/videos/", body, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
            Authorization: "bearer a2d366c49f8b7aa51f0704611fd2756c",
          },
        })
        .then((response) => {
          file.upload_link = response.data.upload.upload_link;
          resolve(response.data.uri);
          axios
            .patch(file.upload_link, file, {
              headers: {
                "Tus-Resumable": "1.0.0",
                "Upload-Offset": 0,
                "Content-Type": "application/offset+octet-stream",
                Accept: "application/vnd.vimeo.*+json;version=3.4",
              },
              onUploadProgress: (progressEvent) => {
                const progress =
                  (progressEvent.loaded / progressEvent.total) * 100;
                console.log(progress.toFixed(2));
                // dispatch({ type: SET_PERCENTAGE, payload: progress.toFixed(2) });
                // console.log(`Upload progress: ${progress.toFixed(2)}%`);
              },
            })
            .then((res) => {
              console.log(res.data.uri);
            });
        })
        .catch((err) => {
          dispatch({ type: CLEAR_LOADING });

          toast.error("Failed to upload Video", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error("..Failed to upload video:", err);
        });
    });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    toast.error("Failed to upload Video", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.error(".Failed to upload video:", error);
  }
};
