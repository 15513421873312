import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Import Utils
import { setTitle } from '../utils/core';

// Import Middleware
import { isLogin } from '../middleware/auth';

// Import public and private routes
import authRoutes from './authRouter';
import publicRoutes from './publicRouter';
import privateRoutes from './privateRouter';

// Import Components
import NotFound404 from '../views/pages/NotFound/NotFound404';


const getAuthRoutes = () => {
  
  return authRoutes.map((route) => {
    setTitle(route);
    return <Route key={route.path} exact path={route.path} element={route.component} />
  });

}

const getPublicRoutes = () => {

  return publicRoutes.map((route) => {
    setTitle(route);
  return <Route key={route.path} exact path={route.path} element={route.component} />
  });
  
};

const getPrivateRoutes = () => {

  return privateRoutes.map((route) => {
    setTitle(route);
    return <Route key={route.path} exact path={route.path} element={isLogin()===true ? route.component : <Navigate to="/login" /> } />
  });

};

const Approutes = ({isLoggedIn}) => { 
  return (
    <BrowserRouter>
      <Routes>
        { getAuthRoutes() }
        { getPublicRoutes() }
        {isLoggedIn && getPrivateRoutes() }
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Approutes;