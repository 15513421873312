import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Table, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getMyRegistered } from "../../../redux/actions/livesyrgery.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo, formatDate, formattTime, getStatusFromDate } from "../../../utils/core";
import { RESET_LIVESURGERY } from "../../../redux/constants/livesurgeryActionTypes";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const MyLiveSurgery = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const surgeryList = useSelector((state) => state.livesurgery.myRegistered);
  const pageNo = useSelector((state) => state.livesurgery.page);

  useEffect(() => {
    dispatch(getMyRegistered({ page: 1 }, true));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_LIVESURGERY });
    }
  }, [])

  const [headerLabel, setHeaderLable] = useState([
    { title: "Topic", key: "topic" },
    { title: "Speciality", key: "speciality" },
    { title: "Date & Time", key: "dateTime" },
    { title: "Attendees", key: "attendees" },
    { title: "Fee", key: "fee" },
    { title: "Status", key: "status" },
  ]);
  return (
    <Root>
      <Breadcrumb
        title={"Live Surgery"}
        fieldOne="Dashboard"
        fieldTwo="Attend Live Surgery"
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row className=" mt-2">

          <Col className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Attend Live Surgery</h4>
              <p>Total {surgeryList.length} Live Surgeries</p>
            </div>
            <div className=" d-lg-block">
              <button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/organizeLiveSurgery");
                }}
              >
                Organize Live Surgery{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Browse Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/browseLiveSurgery");
                }}
              >
                Browse Live Surgery <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="My Uploads Live Surgery"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myLiveSurgery");
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {surgeryList.length > 0 ? <Card className="bg-white rounded-3 mb-4">
              <Card.Body className="px-4 py-3">
                <div className="table-responsive">
                  <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      {headerLabel.map((val, index) => (
                        <th className="p-2">{val.title}</th>
                      ))}
                      <th className="text-end">&nbsp;</th>
                    </thead>
                    <tbody>
                      {surgeryList.map((val, index) => (
                        <tr>
                          <td className="fw-bold">
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Topic:
                            </p>
                            <h6 className="mb-0">{val.title}</h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Speciality:
                            </p>
                            <p className="mb-0">
                              {val.speciality.specialityName}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Date &amp; Time:
                            </p>
                            <p className="mb-0">
                              {formatDate(val.surgeryDate)}
                              <br />
                              {`${formattTime(
                                val.startTime
                              )} to ${formattTime(val.endTime)}`}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Attendees:
                            </p>
                            <p className="mb-0 fw-bold btn btn-link btn-sm text-decoration-none">
                              {val.members.length}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Fees:
                            </p>
                            <h6 className="mb-0 fw-bold text-success">{ val.fees > 0 ? `Rs. ${val.fees}`: `Free` }</h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Status:
                            </p>
                            {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}
                            {/* <span className="badge badge-info">{getStatusFromDate(val.startDate, val.endDate)}</span> */}
                          </td>
                          <td className="text-left text-lg-end">
                            {/* <Button
                              onClick={() => {
                                navigate(`/edit-livesurgery/${val._id}`);
                              }}
                              className=" btn btn-info mb-2 btn-sm me-1"
                            >
                              <i className="fa-solid fa-pencil me-1"></i> Edit
                            </Button> */}
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                navigate(`/surgeryDetails/${val._id}`);
                              }}
                              className="mb-2 btn btn-link btn-sm"
                            >
                              Details{" "}
                              <i className="fa-solid fa-arrow-right"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card> : <NoDataFound />}

          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default MyLiveSurgery;
