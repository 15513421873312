import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Root from "../../components/Root";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getSurgeonLocation,
  referPatient,
} from "../../../redux/actions/patientReferral.action";

const PatientInfo = () => {
  // Define the validation schema using yup
  const validationSchema = yup.object().shape({
    patientName: yup.string().required("Patient's Name is required"),
    patientAge: yup
      .number()
      .positive()
      .integer()
      .required("Patient's Age is required"),
    patientGender: yup.string().required("Patient's Gender is required"),
    patientContact: yup
      .string()
      .required("Patient's Contact No. is required")
      .matches(/^\d+$/, "Contact No. must contain only numbers"),
    // patientEmail: yup
    //   .string()
    //   .email()
    //   .required("Patient's Email ID is required"),
    patientProfile: yup.string().required("Patient's Profile is required"),
    surgeonLocation: yup.string().required("Surgeon's Location is required"),
  });
  const { surgeonId } = useParams();
  const dispatch = useDispatch();
  const [surgeonOption, setSurgeonOption] = useState([]);

  const onSubmit = (values, { resetForm }) => {
    let requestData = {
      surgeon_id: surgeonId,
      name: values.patientName,
      age: values.patientAge,
      gender: values.patientGender,
      contact_number: values.patientContact,
      institute: values.surgeonLocation,
      email: values.patientEmail,
      profile: values.patientProfile,
    };
    dispatch(referPatient(requestData)).then((res) => {
      if (res === "success") {
        resetForm();
      }
    });
  };

  useEffect(() => {
    if (surgeonId) {
      dispatch(getSurgeonLocation({ surgeon_id: surgeonId })).then((res) => {
        setSurgeonOption(res);
      });
    }
  }, [surgeonId]);
  console.log("surgeonOption", surgeonOption);
  return (
    <Root>
      <Breadcrumb
        title={"Submit Patient Info"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Patient Referral",
          },
          {
            name: "Surgeon Referral Profile",
          },
          {
            name: "Submit Patient Info",
          },
        ]}
      />
      <ContainerWrapper>
        <Row>
          <Col xl={10} className="col-12 offset-xl-1">
            <Card className="bg-white overflow-hidden rounded-3 mb-4">
              <Card.Header className="card-header bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h4 className=" mb-1">Refer Your Patient</h4>
                    <p>
                      Fill in the below details about the patient and refer
                      them. Fields with <span className="text-danger">*</span>{" "}
                      are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={{
                    patientName: "",
                    patientAge: "",
                    patientGender: "",
                    patientContact: "",
                    patientEmail: "",
                    patientProfile: "",
                    surgeonLocation: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {() => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <label htmlFor="patientName" className="form-label">
                          Patient's Name<span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          name="patientName"
                          className="form-control"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientName"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6}>
                        <label htmlFor="patientAge" className="form-label">
                          Patient's Age<span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          name="patientAge"
                          className="form-control"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientAge"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6}>
                        <label htmlFor="patientGender" className="form-label">
                          Patient's Gender<span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          name="patientGender"
                          className="form-control"
                          placeholder=" "
                        >
                          <option value={""}>Select</option>
                          <option value={"MALE"}>Male</option>
                          <option value={"FEMALE"}>Female</option>
                          <option value={"OTHER"}>Other</option>
                        </Field>
                        <ErrorMessage
                          name="patientGender"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6}>
                        <label htmlFor="patientContact" className="form-label">
                          Patient's Contact No.
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          name="patientContact"
                          className="form-control"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientContact"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6}>
                        <label htmlFor="surgeonLocation" className="form-label">
                          Surgeon's Location
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          name="surgeonLocation"
                          className="form-select"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {surgeonOption.map((val) => (
                            <option value={val?._id}>{val.instituteName}</option>
                          ))}
                          {/* Add your options here */}
                        </Field>
                        <ErrorMessage
                          name="surgeonLocation"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6}>
                        <label htmlFor="patientEmail" className="form-label">
                          Patient's Email ID
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Field
                          type="text"
                          name="patientEmail"
                          className="form-control"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="patientEmail"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={12}>
                        <label htmlFor="patientProfile" className="form-label">
                          Patient's Profile
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="textarea"
                          name="patientProfile"
                          className="form-control"
                          placeholder=" "
                          rows="4"
                        />
                        <ErrorMessage
                          name="patientProfile"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2 btn-lg"
                        >
                          Refer My Patient{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default PatientInfo;
