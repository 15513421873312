import * as payment from "../services/razorpay";
import { toast } from "react-toastify";

export const doPayment = async (data) => {
    switch (data.members.transaction_status) {
        case "PROCESSING":
          toast.error("Your old payment is still processing...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          break;
        case "COMPLETED":
          toast.error("Your payment already completed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          break;
        default:
          await payment.makePayment(data);
    }
}

