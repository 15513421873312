import { toast } from "react-toastify";
import * as api from "../../services/api";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const submitFeedback = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    let result = await api.addFeedBack(data);
    toast.success("Feedback Submitted Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });
    return "success";
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while Submitting feedback", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
};