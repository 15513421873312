import React, { useState, useEffect } from "react";
import Root from "../../components/Root";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { getMyUploads, doDelete } from "../../../redux/actions/video.action";
import { RESET_VIDEOS } from "../../../redux/constants/videoActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import ConfirmationPopup from "../../components/Popup/ConfirmationPopup";

const MySurgeryVideo = () => {
  let navigate = useNavigate();
  const [headerLabel, setHeaderLable] = useState([
    { title: "Title", key: "title" },
    { title: "Speciality", key: "speciality" },
    { title: "Views", key: "views" },
    { title: "Likes", key: "likes" },
    { title: "Comments", key: "comments" },
  ]);

  const dispatch = useDispatch();
  const videosList = useSelector((state) => state.video.myVideosList);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(getMyUploads({}));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_VIDEOS });
    };
  }, []);

  const deleteVideo = (id) => {
    if(id == null) {
      return;
    }

    dispatch(doDelete({ videoId: id }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Surgery Videos"}
        fieldOne="Dashboard"
        fieldTwo="My Uploads"
      />

      <ContainerWrapper>
        <Row>
          <Col>
            <div>
              <h4 className=" mb-1">My Uploads</h4>
              <p>Total {videosList.length} Surgery Videos</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-white rounded-3 mb-4">
              <Card.Body className=" px-4 py-3">
                <div className="table-responsive">
                  <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      {headerLabel.map((val, index) => (
                        <th>{val.title}</th>
                      ))}
                      <th className="text-end">&nbsp;</th>
                    </thead>
                    <tbody>
                      {videosList.map((val, index) => (
                        <tr>
                          <td className="fw-bold w-50">
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Title:
                            </p>
                            <h6 className="mb-0">
                              <a href="javascript:void(0)">{val.title}</a>
                            </h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Speciality:
                            </p>
                            <p className="mb-0">
                              {val.speciality.specialityName}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Views:
                            </p>
                            <p className="mb-0 fw-bold">
                              <i className="fa-solid fa-eye me-1 "></i>{" "}
                              {val.views}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Likes:
                            </p>
                            <p className="mb-0 fw-bold">
                              <i className="fa-solid fa-heart me-1"></i>{" "}
                              {val.totalLikes}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Comments:
                            </p>
                            <p className="mb-0 fw-bold">
                              <i className="fa-solid fa-message me-1"></i>{" "}
                              {val.totalComments}
                            </p>
                          </td>
                          <td className="text-left text-lg-end">
                           
                            {/* <button
                              className=" btn btn-info mb-2 btn-sm me-1"
                              onClick={() => {
                                navigate(`/editSurgeryVideos/${val._id}`);
                              }}
                            >
                              <i className="fa-solid fa-pencil me-1"></i>
                            </button> */}
                            <button
                              className=" btn btn-info mb-2 btn-sm me-1"
                              onClick={() => {
                                setDeleteId(val._id);
                                setShowPopup(true);
                              }}
                            >
                              <span style={{ color: "red" }}><i className="fa-solid fa-trash me-1"></i></span>
                            </button>
                            <button
                              onClick={() => {
                                navigate(`/video-details/${val._id}`);
                              }}
                              className="mb-2 btn btn-link btn-sm"
                            >
                              Details{" "}
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ConfirmationPopup
          show={showPopup}
          message="Are you sure you want to delete this Video?"
          header="Confirm Delete ?"
          onConfirm={() => {
            deleteVideo(deleteId);
            setShowPopup(false);
          }}
          onCancel={() => {
            setShowPopup(false);
          }}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default MySurgeryVideo;
