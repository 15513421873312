import {
  CLEAR_PATIENT_DATA,
  GET_PATIENT_REFFERAL_RECEIVED,
  GET_PATIENT_REFFERED,
  GET_SENIOR_SURGEON_DETAILS,
  GET_SURGEON_PROFILE,
  GET_SURGEON_PROFILE_INFO,
  MAKE_PAYMENT,
  SET_SUBSCRIPTION_STATUS,
  UPDATE_PATIENT_SCHEDULE,
} from "../constants/patientReferralActionTypes";

const initialState = {
  seniorSurgeonList: [],
  surgeonProfile: {},
  page: 1,
  hasMore: true,
  patientReferralProfile: {},
  paymentInfo: {},
  patientReffered: [],
  refferalReceived: [],
};

export default (patientReferral = initialState, action) => {
  switch (action.type) {
    case GET_SENIOR_SURGEON_DETAILS:
      if (action.reset) {
        return {
          ...patientReferral,
          seniorSurgeonList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...patientReferral,
          seniorSurgeonList: [
            ...patientReferral.seniorSurgeonList,
            ...action.payload,
          ],
          page: patientReferral.page + 1,
          hasMore: action.hasMore,
        };
      }
    case GET_SURGEON_PROFILE_INFO:
      return {
        ...patientReferral,
        patientReferralProfile: action.payload,
      };
    case MAKE_PAYMENT:
      return {
        ...patientReferral,
        paymentInfo: action.payload,
      };
    case CLEAR_PATIENT_DATA:
      return {
        ...patientReferral,
        patientReferralProfile: {},
        patientReffered: [],
        refferalReceived: [],
        seniorSurgeonList: [],
        page: 1,
        hasMore: true,
      };
    case UPDATE_PATIENT_SCHEDULE:
      return {
        ...patientReferral,
      };
    case SET_SUBSCRIPTION_STATUS:
      return {
        ...patientReferral,
        patientReferralProfile: {
          subscription_status: true,
        },
      };

    case GET_SURGEON_PROFILE:
      return {
        ...patientReferral,
        surgeonProfile: action.payload,
      };

    case GET_PATIENT_REFFERED:
      if (action.reset) {
        return {
          ...patientReferral,
          patientReffered: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...patientReferral,
          patientReffered: [
            ...patientReferral.patientReffered,
            ...action.payload,
          ],
          page: patientReferral.page + 1,
          hasMore: action.hasMore,
        };
      }
    case GET_PATIENT_REFFERAL_RECEIVED:
      if (action.reset) {
        return {
          ...patientReferral,
          refferalReceived: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...patientReferral,
          refferalReceived: [
            ...patientReferral.refferalReceived,
            ...action.payload,
          ],
          page: patientReferral.page + 1,
          hasMore: action.hasMore,
        };
      }

    default:
      return patientReferral;
  }
};
