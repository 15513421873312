import React from 'react';

const Layout = ({children}) => {

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {children}
            </div>
        </div>
    )
}

export default Layout;