
import { GET_ALL_GALLERY } from "../constants/homeActionTypes";

const initialState = {
  galleryList: []
};

export default (home = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GALLERY:
      return {
        ...home,
        galleryList: action.payload
      };
    default:
      return home;
  }
};
