import { toast } from "react-toastify";
import * as api from "../../services/api";

import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";
import { GET_MENTORSHIP_BY_ID, GET_REQUESTED_MENTORSHIP, REQUEST_MENTORSHIP, GET_USER_BY_ID } from "../constants/mentorshipActionTypes";



export const requestTelementorship = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.requestMentorship(data);
    if (result) {
      dispatch({ type: CLEAR_LOADING });

      toast.success("Mentorship Requested Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: REQUEST_MENTORSHIP, payload: data });
      // navigate("/myObservership")
      return "success"
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    toast.error("Error while Requesting Mentorship", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "error"

  }
};



export const getMentorshipById = (data) => async (dispatch) => {
  try {
    const result = await api.getMentorshipById(data);

    dispatch({ type: GET_MENTORSHIP_BY_ID, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};


export const getRegisteredMentorship = (data) => async (dispatch) => {
  try {
    const result = await api.getRequestedMentorship(data);

    dispatch({ type: GET_REQUESTED_MENTORSHIP, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};


export const getDoctorInfo = (data) => async (dispatch) => {
   dispatch({ type: SET_LOADING });
  try {
    const result = await api.getUserById(data);

    dispatch({ type: GET_USER_BY_ID, payload: result.data.data[0] });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};
