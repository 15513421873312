import React from "react";
import PublicRoot from "../../components/PublicRoot";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Specialities = () => {
  let navigate = useNavigate();

  return (
    <PublicRoot>
      <Breadcrumb
        title={"Specialities"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Specialities",
          },
        ]}
      />
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-2">
              <p className="title-meta mt-0 mb-0">
                Specialities <span className="line"></span>
              </p>
              <h2 className="h3 mb-2">Our Communities</h2>
              <p>Explore specialities and communities we cater to.</p>
            </div>
          </div>
          <div className="row d-flex align-items-stretch ">
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/general-surgery.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">General Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/obg.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">OBG </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/ortho.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Orthopedics </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/neuro.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Neuro Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/cardiac.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Cardiac Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/urology.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Urology </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/paediactric.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Paediatric Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/gastro.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Gastro Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/onco.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">ONCO Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/plastic.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Plastic Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/ent.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">ENT </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/dental.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Dental Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch offset-xl-2">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/ophthalmology.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Ophthalmology </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/maxillofacial.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Maxillofacial Surgery </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/anesthesiology.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Anesthesiology </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                  <img
                    src={require("../../../img/radiology.png")}
                    alt=""
                    width="44"
                  />
                  <h6 className="mt-3 mb-0">Radiology </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="text-center">
              <a
                onClick={() => navigate("/register")}
                href="signup.html"
                title="Sign Up"
                className="btn btn-primary"
              >
                Join Surgic Share <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS/>
    </PublicRoot>
  );
};

export default Specialities;
