import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import {
  joinMeeting
} from "../../../redux/actions/meeting.action";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logo from "../../../img/logo.svg";


const MeetingError = () => {

   const dispatch = useDispatch();
   let navigate = useNavigate();


  const [id, setId] = useState(null);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(true);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams)
    setMessage(urlParams.get('message'));
    setId(urlParams.get('id'));
  }, [])


  const onConfirm = async () => {
     dispatch(joinMeeting({ id: id }, navigate));
  };

  
  const onCancel = () => {
      navigate(`/cme-rte/${id}`)
  }

  
  return (
    <div style={{backgroundColor: "#000"}}>
        <Modal show={show} onHide={onCancel}>
          <Modal.Header className="complete-profile-modal-header">
            <img src={logo} alt="Logo" className="modal-logo" />
            <Modal.Title className="text-light h6">{""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button  onClick={onConfirm}>
              Retry
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default MeetingError;
