import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Services = () => {
  let navigate = useNavigate();
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Services"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Services",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="title-meta mt-0 mb-0">
                What We Provide <span className="line"></span>
              </p>
              <h2 className="h3 mb-2">Our Services</h2>
              <p>Explore our services and offerings.</p>
            </div>
          </div>
          <div className="row d-flex align-items-stretch">
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-live-surgery.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">
                    Schedule/ Attend uninterrupted CME on Zoom{" "}
                  </h4>
                  <p className="mb-0">
                    {" "}
                    Platform to organize and attend digital CME with like minded
                    surgeons on Zoom for uninterrupted period.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-meeting.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">Organize/ Attend Live Surgery </h4>
                  <p className="mb-0">
                    {" "}
                    Platform to organize and attend live surgery with like
                    minded surgeons on Zoom for uninterrupted period.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-video.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">
                    Post/ Watch Surgery Videos & Tutorials{" "}
                  </h4>
                  <p className="mb-0">
                    {" "}
                    Post your unique surgery video and tutorials or watch
                    surgery videos and tutorials of surgeons of dream or of
                    dream Institute.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-tutorial.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">Post/ Attend Observership Course </h4>
                  <p className="mb-0">
                    {" "}
                    Post your upcoming Observership course and learn surgical
                    skills by attending Observership course from expert
                    surgeons.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-workshop.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">Post/ Attend Hands on Training </h4>
                  <p className="mb-0">
                    {" "}
                    Post Hands on training workshop and get updated on upcoming
                    hands on training on cadaver/ live animal/ wet & dry
                    tissues.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-users.png")}
                    alt=""
                    width="44"
                  />
                  <h4 className="mt-3 h5">
                    Patient Referral Profile & Refer Your Patient{" "}
                  </h4>
                  <p className="mb-0">
                    {" "}
                    Crete your patient referral profile to get patient referral
                    from across the specialty and across the geography and have
                    great convenience for your referred patients.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-tutorial.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">My Association My Group </h4>
                  <p className="mb-0">
                    Create Open group/ Closed group of surgeons and post in the
                    group an organize CME/ RTM on Zoom with group member.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
              <div className="d-flex align-items-stretch w-100">
                <div className="bg-white border p-4 rounded-3 mb-4 w-100 text-start">
                  <img
                    src={require("../../../img/icon-meeting.png")}
                    alt=""
                    width=""
                  />
                  <h4 className="mt-3 h5">
                    Telementorship, Mentorship & Online classNamees{" "}
                  </h4>
                  <p className="mb-0">
                    {" "}
                    Request for paid Telementorhip, Mentorship from expert
                    surgeons in difficult case. Request for paid online classNamees
                    from dream surgeons.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
              <a
                href="javascript:void(0)"
                title="Sign Up"
                className="btn btn-primary"
                onClick={() => navigate("/register")}
              >
                Join Surgic Share <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS/>
    </PublicRoot>
  );
};

export default Services;
