import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { attendConference } from "../../../redux/actions/conference.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";

const AttendConfWork = () => {

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const conferenceList = useSelector((state) => state.conference.attendConference);
  const pageNo = useSelector((state) => state.conference.page);
  const [headerLabel, setHeaderLable] = useState([
    { title: "Title", key: "title" },
    { title: "Speciality", key: "speciality" },
    { title: "Date", key: "date" },
    { title: "Faculty", key: "faculty" },
    { title: "Status", key: "Status" },
  ]);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );
  
  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(attendConference({ page: pageNo }));
  }, []);
  console.log(conferenceList);
  return (
    <Root>
      <Breadcrumb
        title={"Attend Conference, Workshop and Hands on Trainings"}
        fieldOne="Dashboard"
        fieldTwo="Attend Conference, Workshop and Hands on Trainings"
      />
      <ContainerWrapper>
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">
                Registered Conference, Workshop and Hands on Trainings
              </h2>
              <p>You have { conferenceList?.length } conference, workshop and hands on training</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              <div className="card-body px-4 py-3">
                <div className="table-responsive">
                  <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      {headerLabel.map((val, index) => (
                        <th className="p-2">{val.title}</th>
                      ))}
                      <th className="text-end">&nbsp;</th>
                    </thead>
                    <tbody>
                      {conferenceList.length > 0 ? (conferenceList.map((val, index) => (
                        <tr>
                          <td className="fw-bold">
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Title:
                            </p>
                            <h6 className="mb-0">
                                 {val.title}
                            </h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Speciality:
                            </p>
                            <p className="mb-0">{val.speciality.specialityName}</p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Date:
                            </p>
                            <p className="mb-0">
                              {formatDate(val.conferenceDate)}<br />
                              {`${formattTime(val.startTime)} to ${formattTime(val.endTime)}`}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                              Hosted by:
                            </p>
                            <div className="author d-flex">
                              <span className="user-image-wrap me-2">
                                <img
                                  src="assets/images/user.jpg"
                                  alt="Surgeon Name"
                                  width="44"
                                />
                              </span>
                              <span className="author-details">
                                <p className="fw-bold mb-0">
                                  {val.user_details.firstName} {val.user_details.lastName}<br/>
                                </p>
                                <p className="text-muted mb-0">{val.user_details.country.state.city.cityName}</p>
                              </span>
                            </div>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Status:
                            </p>
                            <span className="badge badge-info">Upcoming</span>
                          </td>
                          <td className="text-left text-lg-end">
                              {currentUser == val.user_details._id ? (
                                val.startUrl != null ? (
                                  <a
                                    href={val.startUrl}
                                    target="_blank"
                                    className=" btn btn-primary mb-2 btn-sm"
                                  >
                                    Start{" "}
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                ) : (
                                  <span></span>
                                )
                              ) : val.joinUrl != null ? (
                                <a
                                  href={val.joinUrl}
                                  target="_blank"
                                  className=" btn btn-primary mb-2 btn-sm"
                                >
                                  Join{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              ) : (
                                <span></span>
                              )}

                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </td>
                        </tr>
                      ))) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default AttendConfWork;
