import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logo from "../../../img/logo.svg";

const ConfirmationPopup = ({ show, message, onConfirm, onCancel,header }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header className="complete-profile-modal-header">
        <img src={logo} alt="Logo" className="modal-logo" />
        <Modal.Title className="text-light h6">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button  onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationPopup;
