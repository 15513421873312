import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { dateformatter, getDate } from "../../../utils/core";
import {
  addConference,
  getAllConferenceShipById,
  updateConference,
} from "../../../redux/actions/conference.action";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const PostConfWork = () => {
  const { associationId, id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const specialityList = useSelector((state) => state.filters.speciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);

  const ConferenceById = useSelector(
    (state) => state.conference.ConferenceById
  );

  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
  }, []);


  useEffect(() => {
    if (id !== undefined) {
      dispatch(getAllConferenceShipById({ id: id }));
    }
  }, []);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    }
  };

  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const requestData = new FormData();
    requestData.append("speciality", values.speciality);
    requestData.append("country", values.country);
    requestData.append("state", values.state);
    requestData.append("city", values.city);
    if (values.institute && values.institute != "") {
      requestData.append("institution", values.institute);
    }
    requestData.append("title", values.title);
    requestData.append("description", values.description);
    requestData.append("conferenceDate", values.startDate);
    requestData.append("conferenceEndDate", values.endDate);
    requestData.append("startTime", values.startTime);
    requestData.append("endTime", values.endTime);
    // requestData.append("fees", values.fee);
    requestData.append("url", values.conferenceUrl);
    if(file) {
      requestData.append("brochure", file);
    }
    requestData.append("slot", values.availableSlots);
    requestData.append("venue", values.venue);

    if (values.conferenceType && values.conferenceType != "") {
      requestData.append("conferenceType", values.conferenceType);
    }


    if (associationId) {
      requestData.append("associationId", associationId);
    }

    if (id && ConferenceById) {
      requestData.append("id", id);
      dispatch(updateConference(requestData, navigate)).then((res) => {
        setSubmitting(false);
        setFile(null);
        resetForm();
      });
    } else {
      dispatch(addConference(requestData, navigate)).then((res) => {
        setSubmitting(false);
        setFile(null);
        resetForm();
      });
    }
  };
  useEffect(() => {
    if (ConferenceById) {
      dispatch(
        getSubSpeciality({ speciality_id: ConferenceById?.speciality?._id })
      );
      dispatch(getStates({ country_id: ConferenceById?.country?._id }));
      dispatch(getCities({ state_id: ConferenceById?.country.state?._id }));
      dispatch(
        getInstitute({ city_id: ConferenceById?.country.state?.city._id })
      );
    }
  }, [ConferenceById]);

  const initialState = id && ConferenceById
    ? {
      speciality: ConferenceById?.speciality?._id,
      country: ConferenceById?.country?._id,
      state: ConferenceById?.country.state?._id,
      city: ConferenceById?.country.state?.city._id,
      venue: ConferenceById?.venue,
      institute: ConferenceById?.institution?._id,
      title: ConferenceById?.title,
      description: ConferenceById?.description,
      startDate: dateformatter(ConferenceById?.conferenceDate),
      endDate: dateformatter(ConferenceById?.conferenceEndDate),
      startTime: ConferenceById?.startTime,
      endTime: ConferenceById?.endTime,
      attachment: "",
      availableSlots: ConferenceById?.slot,
      // fee: ConferenceById?.fees,
      conferenceUrl: ConferenceById?.url,
      conferenceType: ConferenceById?.conferenceType,
      agreement: true,
    }
    : {
      speciality: "",
      country: "",
      state: "",
      city: "",
      venue: "",
      institute: "",
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      attachment: "",
      availableSlots: "",
      // fee: "",
      conferenceUrl: "",
      conferenceType: "",
      agreement: false,
    };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    venue: Yup.string().required("Venue is required"),
    // institute: Yup.string().required("Institute/Hospital is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    attachment: Yup.mixed(),
    availableSlots: Yup.string(),
    // fee: Yup.string().required("Registration Fee is required"),
    conferenceUrl: Yup.string().required("Conference URL is required"),
    agreement: Yup.boolean().oneOf([true], "You must confirm the data"),
  });
  return (
    <Root>
      <Breadcrumb
        title={"Conference / Workshop"}
        fieldOne="Dashboard"
        fieldTwo="Post Conference, Workshop and Hands on Training"
      />
      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Post your upcoming conference/ workshop/ Hands on training</h2>
                    <p>
                      Post upcoming conference/ workshop/ hands on training of your association / Institute.{" "}
                      <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Subspeciality
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="conferenceType"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {subSpecialityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.subSpecialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="conferenceType"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Country
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {countryList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.countryName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          State
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="state"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {stateList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.stateName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          City
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="city"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {cityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.cityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Venue<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="venue"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="venue"
                          component="div"
                          className="text-danger text-small"
                        />{" "}
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Institute/Hospital
                          {/* <span className="text-danger">*</span> */}
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="institute"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {instituteList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.instituteName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="institute"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Conference/ workshop/ hands on training title
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger text-small"
                        />{" "}
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">Description<span className="text-danger">*</span></ReactForm.Label>
                        <Field
                          type="text"
                          as="textarea"
                          name="description"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Conference Start Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="startDate"
                          min={getDate(new Date())}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="startDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Conference End Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="endDate"
                          min={getDate(new Date())}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="endDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Start Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="startTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="startTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      {/* <Col md={6}>
                        <ReactForm.Label className="form-label">
                          End Date
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="date"
                          name="endDate"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="endDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col> */}
                      <Col md={6}>
                        <ReactForm.Label for="">
                          End Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="endTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="endTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Attach Agenda/Brochure
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="attachment"
                            className="form-control"
                            onChange={handleFileInputChange}
                          />
                        </div>
                        <ErrorMessage
                          name="attachment"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Slots Available
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="availableSlots"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="availableSlots"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      {/* <Col md={6}>
                        <ReactForm.Label for="">
                          Registration Fee
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <div className="input-group">
                          <span className="input-group-text" id="">
                            Rs.
                          </span>
                          <Field
                            type="text"
                            name="fee"
                            className="form-control"
                          />
                        </div>
                        <div id="" className="form-text">
                          Enter the price inclusive of 18% GST & 10% Surgic
                          Share service charge.
                        </div>
                        <ErrorMessage
                          name="fee"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col> */}
                      <Col md={12}>
                        <ReactForm.Label for="">
                          URL of conference
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <div className="input-group">
                          <Field
                            type="text"
                            name="conferenceUrl"
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="conferenceUrl"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement"
                          >
                            I confirm that all the data given is correct and am
                            authorized to post this course.
                          </label>
                          <ErrorMessage
                            name="agreement"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={formik.isSubmitting}
                        >
                          POST{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default PostConfWork;
