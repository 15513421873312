import axios from "axios";
import { useDispatch } from "react-redux";
import { SET_SUBSCRIPTION_STATUS } from "../../redux/constants/patientReferralActionTypes";

export const makePayment = async (params,dispatch) => {
  try {

      const order = await createOrder(params);

      if(!order) {
        // console.log("order not created")
        return false;
      }

      const options = {
        // key: "rzp_test_SwJkixsA4WGkUQ",
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: order.data.amount,
        currency: "INR",
        name: "Surgic Share",
        description: "Registration",
        // image: "https://example.com/your_logo",
        order_id: order.data.id,
        handler: async (response) => {
          // console.log(response);
          if (response.razorpay_payment_id) {
            console.log("Payment successful!");
            dispatch({type:SET_SUBSCRIPTION_STATUS})
          } else {
            console.log("Payment failed or canceled!");
          }
        },
        prefill: {
          // name: "example name",
          // email: "youremail@example.com",
          // contact: "9999999999",
        },
        notes: order.data.notes,
        theme: {
          color: "#0c2c54",
        },
        modal: {
          ondismiss: async () => {
            const cancelResponse = await cancelPayment(params);
            // console.log('Payment modal closed');
          }
        }
      };

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;

      script.onload = () => {
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
            console.log("Filed from Razor pay")
        });
      };
      

      document.body.appendChild(script);

  } catch (error) {
    console.error('Failed', error);
  }
};



const createOrder = async (data) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/payments/service-api/v1/pay-sync/create-order`;
      const token = localStorage.getItem('token');
    
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      const request = {
        payment_type: data.type,
        id: data._id,
        booking_id: data.subscription.booking_id,
      };
      
      const response = await axios.post(apiUrl, request, config);
      return response.data;

    } catch (error) {
      console.error('Error while creating order:', error);
      return error.message
    }
} 


const cancelPayment = async (data) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/payments/service-api/v1/pay-sync/cancel-payment`;
      const token = localStorage.getItem('token');
    
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      const request = {
        payment_type: data.type,
        id: data._id,
        booking_id: data.subscription.booking_id
      };
      
      const response = await axios.post(apiUrl, request, config);
      return response.data;

    } catch (error) {
      console.error('Error while canceling:', error);
      return error.message
    }
} 

