import React, { useEffect, useState, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Row, Col, Card, Form as ReactForm } from "react-bootstrap";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAssociation,
  getAssociationById,
  getAssociationDropDown,
  getAssociationType,
  updateAssociation,
} from "../../../redux/actions/association.action";
import { read, utils } from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

function CreateAssocGroups() {
  const { id } = useParams();
  let navigate = useNavigate();
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const associationType = useSelector(
    (state) => state.association.associationType
  );
  const associationDropdown = useSelector(
    (state) => state.association.associationDropdown
  );
  const associationById = useSelector(
    (state) => state.association.associationById
  );

  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [groupLogo, setGroupLogo] = useState(null);
  const fileInputRef = useRef(null);
  const [fileError, setFileError] = useState(false);

  const [hideCountry, setHideCountry] = useState(false);
  const [hideState, setHideState] = useState(false);
  const [hideCity, setHideCity] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorCity, setErrorCity] = useState(false);


  // ...
  const handleFileInputChange = (event) => {
    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {

      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'csv') {
        setFileError(false);
      } else {
        setFileError(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const workbook = read(fileData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

        // const firstColumnData = jsonData.map((row) => row[0].toString());
        const firstColumnData = jsonData.map((row) => row[0]);
        setFile(firstColumnData);
      };
      reader.readAsBinaryString(selectedFile);
    }
  };
  const hangeGroupLogoChange = (event) => {
    const selectedLogo = event.currentTarget.files[0];
    setGroupLogo(selectedLogo);
  };

  useEffect(() => {
    var input = document.querySelector("input[name=inviteMember]");
    const tagify = new Tagify(input);

    // Add an event listener for the Enter key
    input.addEventListener("keydown", (event) => {
      if (event.key === "Enter" && !tagify.state.dropdown.visible) {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        tagify.addTags(input.value); // Add the tag
        input.value = ""; // Clear the input
      }
    });

    return () => {
      tagify.destroy(); // Clean up Tagify when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (associationById) {
      dispatch(getSubSpeciality({ speciality_id: associationById.speciality }));
      dispatch(getStates({ country_id: associationById.country }));
      dispatch(getCities({ state_id: associationById.state }));
    }
  }, [associationById]);

  useEffect(() => {
    dispatch(getAssociationType());
    dispatch(getAssociationDropDown());
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  const initialValues = associationById
    ? {
      groupsType: associationById.group_type,
      associationLevel: associationById.association_level,
      speciality: associationById.speciality,
      subSpeciality: associationById.subSpeciality,
      country: associationById.country,
      state: associationById.state,
      city: associationById.city,
      associationName: associationById.association_name,
      // inviteMember: associationById.invitation.join(","),
      inviteMember: "",
      inviteMembersBulk: null,
      enableReinitialize: true,
    }
    : {
      groupsType: "",
      associationLevel: "",
      speciality: "",
      subSpeciality: "",
      country: "",
      state: "",
      city: "",
      associationName: "",
      inviteMember: "",
      inviteMembersBulk: null,
      enableReinitialize: true,
    };


  const validationSchema = Yup.object({
    groupsType: Yup.string().required("Type of Group is required"),
    associationLevel: Yup.string().required("Association Level is required"),
    speciality: Yup.string().required("Speciality is required"),
    // subSpeciality: Yup.string(),
    // country: Yup.string().required("Country is required"),
    // state: Yup.string().required("State is required"),
    // city: Yup.string().required("City is required"),
    associationName: Yup.string().required(
      "Association or Group Name is required"
    ),
    // inviteMember: Yup.string().required("Invite Members is required"),
  });

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    }
  };

  const handleLevelChange = (e, setFieldValue) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
    
    if(value === "651d34d0ecabb4143e5d859c") {
      setHideCountry(true);
      setHideState(true);
      setHideCity(true);
    } else if(value === "64968eb94fca351992bbcbc6") {
      setHideCountry(false);
      setHideState(true);
      setHideCity(true);
    } else if(value === "64968ecd4fca351992bbcbca") {
      setHideCountry(false);
      setHideState(false);
      setHideCity(true);
    } else {
      setHideCountry(false);
      setHideState(false);
      setHideCity(false);
    }
   
  };

  const getMemberData = (values) => {
    let memberData = [];

    try {
      memberData = JSON.parse(values.inviteMember);
      // if (!Array.isArray(memberData)) {
      //   memberData = [memberData]; // Wrap the single object in an array
      // }
    } catch (error) {
      // Parsing as JSON failed, treat it as a comma-separated string
      // memberData = values.inviteMember.split(",");
      console.error("Error parsing member data:", error);
    }


     if(!Array.isArray(memberData)) {
        const stringNumber = memberData.toString();
        memberData = stringNumber.split(',');
      }

    let modifiedData = memberData.map((item) => {
      if (typeof item === "object" && item.hasOwnProperty("value")) {
        return item.value.toString();
      } else {
        return item.toString();
      }
    });
    
    const mergedArray = [...modifiedData, ...file];
    const result = mergedArray.filter(res => res !== undefined && res !== null);

    return result;
  };


  const handleSubmit = async (values, { resetForm }) => {

    let memberData = await getMemberData(values);

    const formData = new FormData();

    if (fileError) {
      return;
    }


    formData.append("group_type", values.groupsType);
    formData.append("speciality", values.speciality);
    formData.append("association_level", values.associationLevel);
    
    if(values.country && values.country != "" && values.country != null) {
      formData.append("country", values.country);
    }
    if(values.state && values.state != "" && values.state != null) {
      formData.append("state", values.state);
    }
    if(values.city && values.city != "" && values.city != null) {
      formData.append("city", values.city);
    }
    formData.append("association_name", values.associationName);
    formData.append("invitation", JSON.stringify(memberData));


    // let payload = {
    //   group_type: values.groupsType,
    //   speciality: values.speciality,
    //   // subSpeciality: values.subSpeciality,
    //   association_level: values.associationLevel,
    //   country: values.country,
    //   state: values.state,
    //   city: values.city,
    //   association_name: values.associationName,
    //   // invitation: [
    //   //   ...values.inviteMember.split(",").map((member) => member.trim()),
    //   //   ...file,
    //   // ],
    //   invitation: [...memberData, ...file]
    // };

    if (groupLogo) {
      // payload.group_image = groupLogo;
      formData.append("group_image", groupLogo);
    }


    if (values.subSpeciality && values.subSpeciality != "") {
      // payload.subSpeciality = values.subSpeciality
      formData.append("subSpeciality", values.subSpeciality);
    }

    if (id && associationById) {
      formData.append("associationId", id);
      dispatch(updateAssociation(formData, navigate));
      // dispatch(updateAssociation({ ...payload, associationId: id }, navigate));
    } else {
      dispatch(addNewAssociation(formData, navigate));
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFile([]);
    resetForm();
  };

  // const [groupsType, setGroupType] = useState(null)
  // const [isClosedGroup, setIsClosedGroup] = useState(false)
  // const changeGroupType = (e, setFieldValue) => {
  //    const { name, value } = e.target;
  //   // setGroupType(e.target.value)
  //   setFieldValue(name, value);
  //   if(e.target.value == "64968b8c238e4f50466e9f90") {
  //     setIsClosedGroup(true)
  //   }
  // }

  const starMark = <span className="text-danger">*</span>;

  return (
    <Root>
      <Breadcrumb
        title={associationById ? "My Association or Group" : "Association or Group"}
        fieldOne="Dashboard"
        fieldTwo="Associations and Groups"
        fieldThree={associationById ? "My Association or Group" : "Create Association or Group"}
      />
      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">{associationById ? `${associationById.association_name}` : "Create Association or Group"}</h2>
                    <p>
                      {associationById ? "Invite other surgeons to your Association or group." : <>Fill in the below details and Create Association or Group. Fields with {starMark} are mandatory.</>}
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {({ setFieldValue }) => {
                    return (
                      <Form className="row g-3 g-lg-4">
                        {!associationById ? (
                          <>
                            <Col md={6}>
                              <ReactForm.Label
                                htmlFor="groupsType"
                                className="form-label"
                              >
                                Type of Group<span className="text-danger">*</span>
                              </ReactForm.Label>
                              <Field
                                as="select"
                                name="groupsType"
                                id="groupsType"
                                className="form-select"
                                // onChange={(e)=> {
                                //   changeGroupType(e, setFieldValue)
                                // } }
                              >
                                <option value="">Select</option>
                                {associationType.map((val, index) => (
                                  <option key={index} value={val._id}>
                                    {val.groupType}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="groupsType"
                                component="div"
                                className="text-danger text-small"
                              />
                            </Col>


                            <Col md={6}>
                              <ReactForm.Label
                                htmlFor="associationLevel"
                                className="form-label"
                              >
                                Level of Group<span className="text-danger">*</span>
                              </ReactForm.Label>
                              <Field
                                as="select"
                                name="associationLevel"
                                id="associationLevel"
                                className="form-select"
                                onChange={(e) =>
                                  handleLevelChange(e, setFieldValue)
                                }
                              >
                                <option value="">Select</option>
                                {associationDropdown.map((val, index) => (
                                  <option key={index} value={val._id}>
                                    {val.associationLevel}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="associationLevel"
                                component="div"
                                className="text-danger text-small"
                              />
                            </Col>
                            <Col md={6}>
                              <ReactForm.Label className="form-label">
                                Speciality<span className="text-danger">*</span>
                              </ReactForm.Label>
                              <Field
                                as="select"
                                name="speciality"
                                className="form-select"
                                onChange={(e) =>
                                  handleChange(e, setFieldValue, dispatch)
                                }
                              >
                                <option value="">Select</option>
                                {specialityList.map((val, index) => (
                                  <option key={index} value={val._id}>
                                    {val.specialityName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="speciality"
                                component="div"
                                className="text-danger text-small"
                              />
                            </Col>
                            <Col md={6}>
                              <ReactForm.Label className="form-label">
                                Subspeciality
                              </ReactForm.Label>
                              <Field
                                as="select"
                                name="subSpeciality"
                                className="form-select"
                                onChange={(e) =>
                                  handleChange(e, setFieldValue, dispatch)
                                }
                              >
                                <option value="">Select</option>
                                {subSpecialityList.map((val, index) => (
                                  <option key={index} value={val._id}>
                                    {val.subSpecialityName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="subSpeciality"
                                component="div"
                                className="text-danger text-small"
                              />
                            </Col>
                            { !hideCountry ? (
                              <Col md={4}>
                                <ReactForm.Label className="form-label">
                                  Faculty's Country
                                  <span className="text-danger">*</span>
                                </ReactForm.Label>
                                <Field
                                  as="select"
                                  name="country"
                                  className="form-select"
                                  onChange={(e) =>
                                    handleChange(e, setFieldValue, dispatch)
                                  }
                                >
                                  <option value="">Select</option>
                                  {countryList.map((val, index) => (
                                    <option key={index} value={val._id}>
                                      {val.countryName}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="country"
                                  component="div"
                                  className="text-danger text-small"
                                />
                              </Col>
                            ) : (
                              null
                            )}

                            { !hideState ? (
                              <Col md={4}>
                                <ReactForm.Label className="form-label">
                                  State
                                  <span className="text-danger">*</span>
                                </ReactForm.Label>
                                <Field
                                  as="select"
                                  name="state"
                                  className="form-select"
                                  onChange={(e) =>
                                    handleChange(e, setFieldValue, dispatch)
                                  }
                                >
                                  <option value="">Select</option>
                                  {stateList.map((val, index) => (
                                    <option key={index} value={val._id}>
                                      {val.stateName}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="text-danger text-small"
                                />
                              </Col>
                            ) : (
                              null
                            )}

                            { !hideCity ? (
                              <Col md={4}>
                                <ReactForm.Label className="form-label">
                                  City
                                  <span className="text-danger">*</span>
                                </ReactForm.Label>
                                <Field
                                  as="select"
                                  name="city"
                                  className="form-select"
                                  onChange={(e) =>
                                    handleChange(e, setFieldValue, dispatch)
                                  }
                                >
                                  <option value="">Select</option>
                                  {cityList.map((val, index) => (
                                    <option key={index} value={val._id}>
                                      {val.cityName}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="text-danger text-small"
                                />
                              </Col>
                            ) : (
                              null
                            )}


                            <Col md={12}>
                              <ReactForm.Label
                                htmlFor="associationName"
                                className="form-label"
                              >
                                Association or Group Name
                                <span className="text-danger">*</span>
                              </ReactForm.Label>
                              <Field
                                type="text"
                                name="associationName"
                                id="associationName"
                                className="form-control"
                                placeholder=""
                              />
                              <ErrorMessage
                                name="associationName"
                                component="div"
                                className="text-danger text-small"
                              />
                            </Col>
                            <Col md={12}>
                              <ReactForm.Label
                                htmlFor="group_image"
                                className="form-label"
                              >
                                Group Logo/Image/Pic

                              </ReactForm.Label>
                              <input
                                type="file"
                                name="group_image"
                                className="form-control"
                                onChange={hangeGroupLogoChange}
                                ref={fileInputRef}
                              />

                            </Col>
                          </>
                        ) : (
                          null
                        )}
                        <Col md={12}>
                          <ReactForm.Label htmlFor="inviteMember">
                            Invite Members
                            {/* <span className="text-danger">*</span> */}
                          </ReactForm.Label>
                          <Field
                            type="text"
                            name="inviteMember"
                            id="inviteMember"
                            className="form-control"
                            placeholder=""
                          />
                          <div className="form-text" style={{ color: '#f64e60', fontWeight: '600' }}>
                            Enter valid email IDs or mobile no.(India) followed by ',' or press Enter to invite more members.
                          </div>
                          <ErrorMessage
                            name="inviteMember"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          <ReactForm.Label htmlFor="inviteMembersBulk">
                            Invite Members in Bulk
                          </ReactForm.Label>
                          <div className="input-group">
                            <input
                              type="file"
                              name="inviteMembersBulk"
                              className="form-control"
                              onChange={handleFileInputChange}
                              ref={fileInputRef}
                            />
                          </div>
                          {fileError ? (
                            <p style={{ color: '#f64e60', fontWeight: '600' }}>Only xls, xlsx, csv format is supported</p>
                          ) : (
                            null
                          )}
                          <div className="form-text" style={{ color: '#f64e60', fontWeight: '600' }}>
                            Upload an excel file containing only one column of
                            emails or mobile no.(India) without the plus symbol or
                            spaces, without header row.
                          </div>
                          <ErrorMessage
                            name="inviteMembersBulk"
                            component="div"
                            className="text-danger text-small"
                          />{" "}
                        </Col>

                        {/* <Col md={12}>
                          Note: Meeting should be scheduled at least before 2
                          hours of start of meeting, to check the basic check
                          list.
                        </Col> */}
                        <Col className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2"
                          >
                            Invite members{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Col>
                      </Form>
                    );
                  }}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
}

export default CreateAssocGroups;
