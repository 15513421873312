import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editVideo,
  getVideoDetails,
} from "../../../redux/actions/video.action";
import {
  getSpeciality,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditSurgeryVideo = () => {
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const videoData = useSelector((state) => state.video.videoDetails);
  const navigate = useNavigate();

  const paidStatus = [
    { key: "Yes", value: 1 },
    { key: "No", value: 0 },
  ];

  const dispatch = useDispatch();
  const { id } = useParams();
  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    dispatch(getVideoDetails({ id: id }));
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
  }, []);

  useEffect(() => {
    if (videoData) {
      dispatch(getSubSpeciality({ speciality_id: videoData.speciality?._id }));
    }
  }, [videoData]);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    }
  };

  const handleSubmit = (values, { setFieldValue, resetForm }) => {
    const requestData = new FormData();

    requestData.append("speciality", values.speciality);
    if(values.sub_speciality && values.sub_speciality != "") {
      requestData.append("sub_speciality", values.sub_speciality);
    }
    requestData.append("paid_telementorship", values.paid_telementorship);
    requestData.append("paid_training", values.paid_training);
    requestData.append("title", values.title);
    requestData.append("patient_details", values.patient_details);
    requestData.append("attachmentFile", file);
     requestData.append("videoId", videoData._id);

    dispatch(editVideo(requestData, navigate));

    // Reset the form after submission
    setFile(null);
    resetForm();
  };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    // sub_speciality: Yup.string(),
    title: Yup.string().required("Video title is required"),
    patient_details: Yup.string("Patient profile is required"),
    paid_telementorship: Yup.number(),
    paid_training: Yup.number(),
    attachment: Yup.mixed(),
    agreement1: Yup.boolean().oneOf([true], "You must confirm the data"),
    agreement2: Yup.boolean().oneOf([true], "You must confirm the data"),
  });

  const initialState = videoData
    ? {
      speciality: videoData.speciality?._id,
      sub_speciality: videoData.speciality.subSpeciality?._id,
      title: videoData.title,
      patient_details: videoData.patient_details,
      paid_telementorship: videoData.paid_telementorship,
      paid_training: videoData.paid_training,
      attachment: "",
      agreement1: true,
      agreement2: true,
    }
    : {};

  return (
    <Root>
      <Breadcrumb
        title={"Live Surgery"}
        fieldOne="Dashboard"
        fieldTwo="Upload Your Surgery Video"
      />
      <ContainerWrapper>
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Upload Your Surgery Video</h2>
                    <p>
                      Fill in the below details and upload surgery video. Fields
                      with <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>

              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Subspeciality
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="sub_speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {subSpecialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.subSpecialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="sub_speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Video Title
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Patient Profile (Non Personal)
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="patient_details"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="patient_details"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Availability for Paid Telementoring
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="paid_telementorship"
                          className="form-select"
                        >
                          {paidStatus.map((val, index) => (
                            <option key={index} value={val.value}>
                              {val.key}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="paid_telementorship"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Availability for Training/ Observership/ Expert
                          Opinion
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="paid_training"
                          className="form-select"
                        >
                          {paidStatus.map((val, index) => (
                            <option key={index} value={val.value}>
                              {val.key}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="paid_training"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={12}>
                        <ReactForm.Label for="">
                          Select Video File
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="attachment"
                            className="form-control"
                            onChange={handleFileInputChange}
                          />
                        </div>
                        <ErrorMessage
                          name="attachment"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement1"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement1"
                          >
                            I confirm, patient’s identity has not been revealed
                            directly or indirectly in the video.
                          </label>
                          <ErrorMessage
                            name="agreement1"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement2"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement2"
                          >
                            I confirm, surgery in uploaded surgery video was
                            performed by me and I take accountability of
                            authenticity of video. Surgery video is for academic
                            purpose only.
                          </label>
                          <ErrorMessage
                            name="agreement2"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        Note: Video uploaded will be published on website within
                        two working days, post basic check list.
                      </Col>
                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Post Surgery Video{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default EditSurgeryVideo;
