import React, { useEffect, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Row } from "react-bootstrap";
import Filters from "../../components/Filters/Filters";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSeniorSurgeons } from "../../../redux/actions/patientReferral.action";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";

const ReferYourPatient = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const seniorSurgeonList = useSelector(
    (state) => state.patientReferral.seniorSurgeonList
  );

  const pageNo = useSelector((state) => state.patientReferral.page);
  const hasMore = useSelector((state) => state.patientReferral.hasMore);
  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const headerColumns = [
    {
      name: "Surgeon",
      key: "surgeonName",
    },
    {
      name: "Speciality",
      key: "speciality",
    },
    // {
    //   name: "Sub Speciality",
    //   key: "subSpecialityData",
    // },
    {
      name: "Designation",
      key: "designation",
    },
    {
      name: "Location",
      key: "location",
    },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      dispatch(getAllSeniorSurgeons({ ...selectedFilter, page: pageNo }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllSeniorSurgeons({ ...selectedFilter, page: 1 }, true));
    }
  }, [selectedFilter]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_PATIENT_DATA });
    };
  }, []);

  const fetchSeniorSurgeons = () => {
    dispatch(getAllSeniorSurgeons({ ...selectedFilter, page: pageNo }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Check Availability of Surgeons"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Check Availability of Surgeons",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Senior Surgeons</h4>
              <p>Refer your patients to senior surgeons around the world.</p>
            </div>
            {/* <div className="d-lg-block">
              <a
                href="javascript:void(0)"
                onClick={() => navigate("/createPatientProfile")}
                title="Organize Live Surgery"
                className="btn btn-primary mt-2"
              >
                Create Patient Referral Profile
                <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div> */}
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>
          <Filters title={"Filter Surgeons"} />
        </div>
        <Row>
          <Col>
            {seniorSurgeonList.length > 0 ? (
              <Card className="bg-white card rounded-3 mb-4">
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <tr>
                        {headerColumns.map((val, index) => (
                          <th key={index}>{val.name}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </tr>
                      <tbody>
                        {seniorSurgeonList.map((val) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Surgeon:
                              </p>
                              <div className="author d-flex align-items-center">
                                <span className="user-image-wrap me-2">
                                  <img
                                    src="assets/images/association-logo.jpg"
                                    alt="Surgeon Name"
                                    width="44"
                                  />
                                </span>
                                <span class="author-details">
                                  <p class="fw-bold mb-0">
                                    <a
                                      onClick={() =>
                                        navigate(
                                          `/surgeon-profile/${val.user_id}`
                                        )
                                      }
                                      href="javascript:void(0)"
                                    >
                                      Dr. {val.first_name} {val.last_name}
                                    </a>
                                  </p>
                                  <p class="text-muted mb-0">
                                    {val.institute.instituteName}
                                  </p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Speciality:
                              </p>
                              <p className="mb-0">
                                {val.speciality.specialityName}
                              </p>
                            </td>
                            {/* <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Subspeciality:
                            </p>
                            <p className="mb-0">Gastro Surgery</p>
                          </td> */}
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Designation:
                              </p>
                              <p className="mb-0">
                                {" "}
                                {val.designation.designationName}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Location:
                              </p>
                              <p className="mb-0">{`${val.city.cityName},${val.state.stateName},${val.country.countryName}`}</p>
                            </td>
                            <td class="text-left text-lg-end">
                              <a
                                href="javascript:void(0)"
                                class=" btn btn-primary mb-2 btn-sm me-1"
                                onClick={() =>
                                  navigate(`/patientInfo/${val.user_id}`)
                                }
                              >
                                Refer <i class="fa-solid fa-arrow-right"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                class=" btn btn-link mb-2 btn-sm me-1"
                                onClick={() =>
                                  navigate(`/surgeon-profile/${val.user_id}`)
                                }
                              >
                                View <i class="fa-solid fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchSeniorSurgeons}
          globalState={seniorSurgeonList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default ReferYourPatient;
