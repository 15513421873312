import React, { useEffect, useState, useRef } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Card, Col, Row, Form as ReactForm } from "react-bootstrap";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCities,
  getCountries,
  getInstitute,
  getSpeciality,
  getStates,
  getSubSpeciality,
  getFaculty,
  getFacultySpeciality
} from "../../../redux/actions/filter.action";
import {
  addLivesurgery,
  editLivesurgery,
  getLivesurgery,
} from "../../../redux/actions/livesyrgery.action";
import { dateformatter, getDate } from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";
import {
  getAssociationById,
} from "../../../redux/actions/association.action";

const OrganizeLiveSurgery = () => {
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  // const facultyList = useSelector((state) => state.filters.faculties);
  const facultyList = useSelector((state) => state.filters.specialityfaculties);
  const surgeryById = useSelector(
    (state) => state.livesurgery.getSurgeryDetails
  );

  const associationDetails = useSelector(
    (state) => state.association.associationById
  );

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { id, associationId } = useParams();
  let navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [specialId, setSpecialId] = useState(null);
  const [instId, setInstId] = useState(null);
  const [isClosedGroup, setIsClosedGroup] = useState(false)

  useEffect(() => {
    dispatch(getAssociationById({ associationId: associationId }));
  }, []);


  useEffect(() => {
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }

    if(associationId && associationDetails.group_type == "64968b8c238e4f50466e9f90") {
      setIsClosedGroup(true)
    } else {
      setIsClosedGroup(false)
    }
  }, []);


  useEffect(() => {
    if (id !== undefined) {
      dispatch(getLivesurgery({ id: id }));
    }
  }, []);

  useEffect(() => {
    if (surgeryById) {
      dispatch(getSubSpeciality({ speciality_id: surgeryById.speciality._id }));
      dispatch(getStates({ country_id: surgeryById.user_details.country._id }));
      dispatch(
        getCities({ state_id: surgeryById.user_details.country.state._id })
      );
      dispatch(
        getInstitute({
          city_id: surgeryById.user_details.country.state.city._id,
        })
      );
      dispatch(getFacultySpeciality({ instituteId: surgeryById.user_details.institution._id, speciality: surgeryById.user_details.speciality._id }));
      // dispatch(
      //   getFaculty({ instituteId: surgeryById.user_details.institution._id })

      // );
    }
  }, [surgeryById]);


  const handleFileInputChange = (event) => {
    const selectedFile = event.currentTarget.files[0];
    setFile(selectedFile);
  };

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
      setSpecialId(value)
      if (instId != null) {
        dispatch(getFacultySpeciality({ instituteId: instId, speciality: value }));
      }
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    } else if (name === "institution") {
      // dispatch(getFaculty({ instituteId: value }));
      setInstId(value)
      dispatch(getFacultySpeciality({ instituteId: value, speciality: specialId }));
    }
  };

  const validationSchema = Yup.object({
    // speciality: Yup.string().required("Speciality is required"),
    // subSpeciality: Yup.string(),
    surgeryDate: Yup.string().required("Livesurgery Date is required"),
    topic: Yup.string().required("Livesurgery Topic is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    // description: Yup.string().min(20).required("Description is required"),
    // fee: Yup.string(),
  });

  const handleSubmit = (values, { resetForm }) => {
    const requestData = new FormData();

    if (values.speciality && values.speciality != "") {
      requestData.append("speciality", values.speciality);
    }

    if (values.subSpeciality && values.subSpeciality != "") {
      requestData.append("subSpeciality", values.subSpeciality);
    }
    if (values.country && values.country != "") {
      requestData.append("country", values.country);
    }
    if (values.state && values.state != "") {
      requestData.append("state", values.state);
    }
    if (values.city && values.city != "") {
      requestData.append("city", values.city);
    }
    if (values.faculty && values.faculty != "") {
      requestData.append("faculty", values.faculty);
    }
    if (values.institution && values.institution != "") {
      requestData.append("institution", values.institution);
    }
    if (values.institution_text && values.institution_text != "") {
      requestData.append("institution_text", values.institution_text);
    }
    if (values.faculty_text && values.faculty_text != "") {
      requestData.append("faculty_text", values.faculty_text);
    }

    // requestData.append("country", values.country);
    // requestData.append("state", values.state);
    // requestData.append("city", values.city);
    // requestData.append("institution", values.institution);
    // requestData.append("faculty", values.faculty);
    requestData.append("title", values.topic);
    if(values.description != "") {
      requestData.append("description", values.description);
    }
    requestData.append("surgeryDate", values.surgeryDate);
    requestData.append("startTime", values.startTime);
    requestData.append("endTime", values.endTime);
    // requestData.append("institution_text", values.institution_text);
    // requestData.append("faculty_text", values.faculty_text);
    // requestData.append("fees", values.fee);
    requestData.append("fees", "0");
    if(file) {
      requestData.append("brochure", file);
    }

    if (associationId) {
      requestData.append("associationId", associationId);
    }

    if (id && surgeryById) {
      requestData.append("id", id)
      dispatch(editLivesurgery(requestData, navigate))
      navigate("/myLiveSurgery")
    } else {
      dispatch(addLivesurgery(requestData, navigate));
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFile([]);
    resetForm();
  };

  const initialValues = id && surgeryById
    ? {
      speciality: surgeryById.speciality?._id,
      subSpeciality: surgeryById.speciality.subSpeciality?._id,
      country: surgeryById.user_details.country?._id,
      state: surgeryById.user_details.country?.state?._id,
      city: surgeryById.user_details.country?.state?.city?._id,
      title: surgeryById.title,
      description: surgeryById.description,
      surgeryDate: dateformatter(surgeryById.startDate),
      startTime: surgeryById.startTime,
      endTime: surgeryById.endTime,
      // fee: surgeryById.fees,
      institution: surgeryById.user_details.institution?._id,
      faculty: surgeryById.faculty_details?._id,
      topic: surgeryById?.title,
      institution_text: surgeryById?.institution_text,
      faculty_text: surgeryById?.faculty_text,
    }
    : {
      speciality: "",
      subSpeciality: "",
      country: "",
      state: "",
      city: "",
      title: "",
      description: "",
      surgeryDate: "",
      startTime: "",
      endTime: "",
      // fee: "",
      institution: "",
      faculty: "",
      topic: "",
      brochure: null,
      faculty_text: "",
      institution_text: ""
    };

  return (
    <Root>
      {associationId ? (
        <Breadcrumb
          title={"Live Surgery"}
          items={[
            {
              name: "Association Dashboard", routeTo: `/AssociationDetails/${associationId}`,
            },
            {
              name: "Schedule Live Surgery",
            },
          ]}
        />
      ) : (
        <Breadcrumb
          title={"Live Surgery"}
          fieldOne="Dashboard"
          fieldTwo="Schedule Live Surgery"
        />
      )}

      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Schedule Live Surgery</h2>
                    <p>
                      Fill in the below details and schedule live surgery.
                      Fields with <span className="text-danger">*</span> are
                      mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      {associationId && associationDetails.group_type !== "64968b8c238e4f50466e9f90" ? (
                        <>
                        
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Speciality<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="speciality"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {specialityList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.specialityName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="speciality"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Subspeciality
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="subSpeciality"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {subSpecialityList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.subSpecialityName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="subSpeciality"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={4}>
                          <ReactForm.Label className="form-label">
                            Country
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="country"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {countryList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.countryName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={4}>
                          <ReactForm.Label className="form-label">
                            State
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="state"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {stateList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.stateName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={4}>
                          <ReactForm.Label className="form-label">
                            City
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="city"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {cityList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.cityName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Faculty's Institution
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="institution"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {instituteList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.instituteName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="institution"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Faculty's Name
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="faculty"
                            className="form-select"
                            onChange={(e) => handleChange(e, formik.setFieldValue, dispatch)}
                          >
                            <option value="">Select</option>
                            {facultyList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.fullname}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="faculty"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        </>
                      ) : ( 
                        <>
                        <Col md={12}>
                          <ReactForm.Label for="">
                            Faculty Institution
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="institution_text"
                            placeholder=" "
                          />
                          <ErrorMessage
                            name="institution_text"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>

                        <Col md={12}>
                          <ReactForm.Label for="">
                            Faculty Name
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="faculty_text"
                            placeholder=" "
                          />
                          <ErrorMessage
                            name="faculty_text"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        </>
                      )}
                      
                      <Col md={4}>
                        <ReactForm.Label for="">
                          Live Surgery Date<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="date"
                          name="surgeryDate"
                          min={getDate(new Date())}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="surgeryDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label for="">
                          Start Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="startTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="startTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label for="">
                          End Time<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="time"
                          name="endTime"
                          placeholder=""
                        />
                        <ErrorMessage
                          name="endTime"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Live Surgery Topic
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="topic"
                          placeholder=" "
                        />
                        <ErrorMessage
                          name="topic"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Live Surgery Description
                          {/* <span className="text-danger">*</span> */}
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="description"
                          className="form-control"
                          placeholder=" "
                          rows="4"
                        />
                        {/* <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger text-small"
                        /> */}
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Attach Agenda/ Brochure
                          {/* <span className="text-danger">*</span> */}
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="brochure"
                            className="form-control"
                            onChange={handleFileInputChange}
                            ref={fileInputRef}
                          />
                        </div>
                      </Col>

                      {/* <Col md={6}>
                        <ReactForm.Label for="">
                          Registration Fee<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <div className="input-group">
                          <span className="input-group-text" id="">
                            Rs.
                          </span>
                          <Field
                            className="form-control"
                            type="text"
                            name="fee"
                            placeholder=" "
                          />
                        </div>
                        <div id="" className="form-text">
                          Enter the price inclusive of 18% GST & 10% Surgic
                          Share service charge. Enter 0 for a free event.
                        </div>
                      </Col> */}

                      <Col>
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="btn btn-primary mb-2 btn-lg"
                        >
                          Schedule Live Surgery{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default OrganizeLiveSurgery;
