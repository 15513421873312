import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Contact = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Contact Us"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Contact Us",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex align-items-stretch">
            <div className="col-sm-12 col-lg-4 d-flex align-items-stretch">
              <div className="bg-white border p-3 rounded-3 mb-4 w-100">
                <p className="text-muted title-meta mt-0 mb-1">
                  {" "}
                  Locate Us <span className="line"></span>
                </p>
                <h2 className="h4 mb-2">Our Address</h2>
                <p className="mt-0">
                  D. No- 4-302, Shivalli Village,
                  <br /> Laxmindra Nagar, 5th Cross,
                  <br /> Udupi 576104, Karnataka, India{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 d-flex align-items-stretch">
              <div className="bg-white border p-3 rounded-3 mb-4 w-100">
                <p className="text-muted title-meta mt-0 mb-1">
                  {" "}
                  Email Us <span className="line"></span>
                </p>
                <h2 className="h4 mb-2">Our Email</h2>
                <p className="mb-1">
                  <a href="mailto:surgicshare@gmail.com" title="Email Us">
                    <strong>surgicshare@gmail.com</strong>
                  </a>
                </p>
                <p>
                  <a href="mailto:admin@surgicshare.com" title="Email Us">
                    <strong>admin@surgicshare.com</strong>
                  </a>
                </p>
                <p>
                    <strong>+91 9971721564</strong>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 d-flex align-items-stretch">
              <div className="bg-white border p-3 rounded-3 mb-4 w-100">
                <p className="text-muted title-meta mt-0 mb-1">
                  {" "}
                  Follow Us <span className="line"></span>
                </p>
                <h2 className="h4 mb-3">Our Socials</h2>
                <ul className="contact-social list-unstyled list-inline mt-4">
                  <li className="d-inline-block">
                    <a
                      href="https://facebook.com/surgicshare"
                      target="_blank"
                      title="Like on Facebook"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://twitter.com/SurgicShare"
                      target="_blank"
                      title="Follow on Twitter"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://instagram.com/surgicshare"
                      target="_blank"
                      title="Follow on Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://www.linkedin.com/company/surgic-share/"
                      target="_blank"
                      title="Follow on LinkedIn"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  );
};

export default Contact;
