import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllLiveSurgery,
  registerToSurgery,
} from "../../../redux/actions/livesyrgery.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo, formatDate, formattTime, getStatusFromDate } from "../../../utils/core";
import { RESET_LIVESURGERY } from "../../../redux/constants/livesurgeryActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";


const BrowseLiveSurgery = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const surgeryList = useSelector((state) => state.livesurgery.surgeryList);
  const pageNo = useSelector((state) => state.livesurgery.page);
  const hasMore = useSelector((state) => state.livesurgery.hasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_LIVESURGERY });
    };
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (isInitialMount.current) {
      dispatch(getAllLiveSurgery({ ...selectedFilter, page: pageNo }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllLiveSurgery({ ...selectedFilter, page: 1 }, true));
    }
  }, [selectedFilter]);

  const fetchData = () => {
    dispatch(getAllLiveSurgery({ ...selectedFilter, page: pageNo }));
  };
  

  const registerNow = (id) => {
    dispatch(registerToSurgery({ id: id }, navigate));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Live Surgery"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Browse Upcoming Live Surgeries",
          },
        ]}
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row className="mt-2">
          <Col className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Upcoming Live Surgeries</h4>
              <p>Browse upcoming live surgeries in your field</p>
            </div>
            <div className=" d-lg-block">
              <button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/organizeLiveSurgery");
                }}
              >
                Organize Live Surgery{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  navigate("/attendLiveSurgery");
                }}
              >
                Attend Live Surgery <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Organize Live Surgery"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myLiveSurgery");
                }}
              >
                My Uploads <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </Col>
        </Row>
        <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>
          <Filters title={"Filter Live Surgeries"} />
        </div>
        {/* <Row>
          {surgeryList.length > 0 ? (
            surgeryList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-surgery"
                  badgeTitle="Live Surgery"
                  feeAmount={`Rs. ${val.fees}`}
                  cardTitle={val.title}
                  duration={`${formatDate(val.startDate)} to ${formatDate(
                    val.endDate
                  )}`}
                  location={`${val.faculty_details?.country.state.city.cityName}, ${val.faculty_details?.country.state.stateName}, ${val.faculty_details?.country.countryName}`}
                  doctorName={`Dr. ${val.user_details.firstName} ${val.user_details.lastName}`}
                  doctorAddress={`${val.user_details.institution.instituteName}, ${val.user_details.country.state.city.cityName}`}
                  buttonTitle={"Register Now"}
                  buttonAction={registerNow}
                  id={val._id}
                  mediaType={val.urlType}
                  url={val.url}
                  detailsLink={`/surgeryDetails/${val._id}`}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchData}
          globalState={surgeryList}
          hasMore={hasMore}
        /> */}
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {surgeryList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        {/* <th>Fee</th> */}
                        <th>Status</th>
                      </thead>
                      <tbody>
                        {surgeryList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6
                                // onClick={() =>
                                //   navigate(`/surgeryDetails/${val._id}`)
                                // }
                                className="mb-0"
                              >
                                {val.title}
                              </h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.surgeryDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  {val?.user_details?.profilePicture ? (
                                      <img
                                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.user_details?.profilePicture}`}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../img/user.png")}
                                        alt=""
                                        title="Surgeon Profile"
                                      />
                                    )}
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <a href={`surgeon-profile/${val.user_details._id}`}>Dr. {val.user_details.firstName} {val.user_details.lastName}</a>
                                  </p>
                                  <p className="text-muted mb-0">
                                  {val?.user_details?.institution?.instituteName} <br />{" "}
                                    {val.faculty_details?.country?.state?.city?.cityName}, {val.faculty_details?.country?.state?.stateName},{" "}
                                    {val.faculty_details?.country?.countryName}
                                  </p>
                                </span>
                              </div>
                            </td>
                            {/* <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Fee
                              </p>
                              <h6 className="text-success">
                                {val.fees}
                              </h6>
                            </td> */}
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>

                              {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()}

                            </td>
                            <td>
                              <Button
                                className="btn-primary mb-2"
                                style={{width: "90px"}}
                                onClick={() => navigate(`/surgeryDetails/${val?._id}`)}
                              >
                                View Details <i className="fa-solid fa-arrow-right"></i>
                              </Button>
                              {/* <button
                                onClick={() => {
                                  navigate(`/surgeryDetails/${val._id}`)
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                        <div className="d-flex">
                          <InfiniteScroll
                            fetchFunction={fetchData}
                            globalState={surgeryList}
                            hasMore={hasMore}
                          />
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseLiveSurgery;
