import {
  ADD_NEW_PATIENT, 
  GET_ALL_PATIENTS,
  GET_PATIENTS_PROCEDURE,
  GET_FILTER_PROCEDURE
} from "../constants/registryActionTypes";

const initialState = {
  patientList: [],
  procedureList: [],
  filterProcedure: [],
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (registry = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_PATIENT:
      return {
        ...registry,
        patientList: action.payload,
      };
    case GET_ALL_PATIENTS:
      return {
        ...registry,
        patientList: action.payload,
      };
    case GET_PATIENTS_PROCEDURE:
      return {
        ...registry,
        procedureList: action.payload,
      };
    case GET_FILTER_PROCEDURE:
      return {
        ...registry,
        filterProcedure: action.payload,
      };

    default:
      return registry;
  }
};
