import { toast } from "react-toastify";
import * as api from "../../services/api";
import {
  POST_SIGNIN,
  POST_SIGNUP,
  FORGOT_PASSWORD_SENT,
  RESET_PASSWORD_SUCCESS,
} from "../constants/authActionTypes";
import { changeRoute, encryptData, setCookie } from "../../utils/core";
import CryptoJS from "crypto-js";
import {
  CLEAR_LOADING,
  SET_LOADING,
  SET_USER_PROFILE_STATUS,
} from "../constants/globalActionTypes";

// toast.configure();

export const signIn = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.authSignin(data);

    if (result.data.status.success) {
      window.localStorage.setItem(
        "token",
        JSON.stringify(result.data.data.access_token)
      );
      window.localStorage.setItem("userData", JSON.stringify(result.data.data));
      window.localStorage.setItem("userId", result.data.data._id);
      window.localStorage.setItem(
        "profileComplete",
        result.data.data.profilePercentage
      );

      // setCookie("token", result.data.data.access_token, 30);
      console.log("profile", result.data.data.profilePercentage);
      let profileCompletionStatus =
        result.data.data.profilePercentage > 90 ? true : false;
      dispatch({
        type: SET_USER_PROFILE_STATUS,
        payload: profileCompletionStatus,
      });
      dispatch({ type: POST_SIGNIN, payload: result });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      navigate("/dashboard");
      return "success";
    } else {
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      return "error";
    }
  } catch (error) {
    toast.error(error?.response?.data?.status?.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });
    return "error";
  }
};

export const signUp = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.authSignup(data);
    console.log(result);
    if (result.data.status.success) {
      const encryptedData = encryptData(result.data.data.otpId);
      dispatch({ type: CLEAR_LOADING });
      navigate(`/register-otp/${encodeURIComponent(encryptedData)}`);
      return "success";
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // dispatch({ type: POST_SIGNUP, payload: result });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    if (
      error.response.data.status.code === 409 &&
      error.response.data.status.message
    ) {
      toast.error(error.response.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
};

export const verifyOTP = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.authVerifyOTP(data);
    console.log("otp verify", result);
    if (result.data.status.success) {
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });

      navigate("/login");
    } else {
      dispatch({ type: CLEAR_LOADING });

      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    toast.error("Something went wrong!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const forgotPassword = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.forgotPassword(data);
    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      // toast.success("Reset password link has been sent to registered mobile number and email.", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({ type: FORGOT_PASSWORD_SENT });
      // navigate("/login");
    } else {
      dispatch({ type: CLEAR_LOADING });

      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    toast.error("Something went wrong!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const checkProfile = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.checkProfileComplete(data);

    try {
      window.localStorage.setItem(
        "profileComplete",
        result.data.data.completionPercentage
      );
    } catch (storageError) {
      console.error("LocalStorage error:", storageError);
    }

    let profileCompletionStatus =
      result.data.data.completionPercentage > 90 ? true : false;

    dispatch({
      type: SET_USER_PROFILE_STATUS,
      payload: profileCompletionStatus,
    });

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.error("API call error:", error);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const checkForgotPasswordLink = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.checkLink(data);
    if (!result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      navigate("/link-expired");
    } else {
      dispatch({ type: CLEAR_LOADING });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    navigate("/link-expired");
  }
};

export const resetPassword = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.resetPassword(data);

    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      dispatch({ type: RESET_PASSWORD_SUCCESS });

      // toast.success(result.data.status.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      // navigate("/login");
    } else {
      dispatch({ type: CLEAR_LOADING });

      toast.error(result.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    toast.error("Something went wrong!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const changePassword = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.changePassword(data);
    if (result.data.status.code === 200) {
      dispatch({ type: CLEAR_LOADING });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      dispatch({ type: CLEAR_LOADING });

      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.response);
    let message = error.message;
    if (error.response.data && error.response.data?.status?.message) {
      message = error.response.data.status.message;
    }
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const OTPLogin = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.sendLoginOTP(data);

    if (result.data.status.success) {
      const encryptedData = encryptData(result.data.data.otpId);
      dispatch({ type: CLEAR_LOADING });
      navigate(`/login-otp/${encodeURIComponent(encryptedData)}`);
      return "success";
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    toast.error("Something went wrong!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const verifyLoginOTP = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.verifyLoginOTP(data);

    if (result.data.status.success) {
      window.localStorage.setItem(
        "token",
        JSON.stringify(result.data.data.access_token)
      );
      window.localStorage.setItem("userData", JSON.stringify(result.data.data));
      window.localStorage.setItem("userId", result.data.data._id);
      window.localStorage.setItem(
        "profileComplete",
        result.data.data.profilePercentage
      );

      let profileCompletionStatus =
        result.data.data.profilePercentage > 90 ? true : false;
      dispatch({
        type: SET_USER_PROFILE_STATUS,
        payload: profileCompletionStatus,
      });
      dispatch({ type: POST_SIGNIN, payload: result });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      navigate("/dashboard");
      return "success";
    } else {
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      return "error";
    }
  } catch (error) {
    toast.error(error?.response?.data?.status?.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({ type: CLEAR_LOADING });
    return "error";
  }
};
