import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Footer = () => {
  return (
    <div className="footer-wrap bg-dark py-3">
      <Container>
        <Row>
          <Col lg={6} className="col-12 col-lg-6 text-center text-lg-start order-2 order-lg-1">
            <p className="mb-0 text-light"><small>Copyright © {new Date().getFullYear()} Surgicshare Private Limited. All rights reserved.</small></p>
          </Col>
          <Col lg={6} className="col-12 col-lg-6 text-center text-lg-end mb-3 mb-lg-0 order-1 order-lg-2">
            <ul className="list-unstyled list-inline footer-socials-list mb-0">
              <li className="list-inline-item"><a href="https://facebook.com/surgicshare" target="_blank" title="Facebook" className="text-light"><i className="fa-brands fa-facebook"></i></a></li>
              <li className="list-inline-item"><a href="https://instagram.com/surgicshare" target="_blank" title="Instagram" className="text-light"><i className="fa-brands fa-instagram"></i></a></li>
              <li className="list-inline-item"><a href="https://www.linkedin.com/company/surgic-share/" target="_blank" title="Linkedin" className="text-light"><i className="fa-brands fa-linkedin-in"></i></a></li>
              <li className="list-inline-item"><a href="https://twitter.com/SurgicShare" target="_blank" title="Twitter" className="text-light"><i className="fa-brands fa-twitter"></i></a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer