import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getAssociationById,
  getMyAssociation,
} from "../../../redux/actions/association.action";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import {
  RESET_ASSOCIATION,
  SET_ASSOCIATION_EDIT_ID,
} from "../../../redux/constants/assocGroupActionTypes";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import AssociationLogo from "../../../img/association-default.png";


const MyAssocgroups = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const associationList = useSelector(
    (state) => state.association.myAssociation
  );

  const headerColumns = [
    {
      name: "Name",
      key: "association_name",
    },
    {
      name: "Speciality",
      key: "speciality",
    },
    {
      name: "Sub Speciality",
      key: "subSpecialityData",
    },
    {
      name: "Level",
      key: "associationLevel",
    },
    {
      name: "Location",
      key: "location",
    },
  ];

  useEffect(() => {
    dispatch({ type: SET_LOADING });

    dispatch(getMyAssociation());
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  const handleEdit = (val) => {
    dispatch(getAssociationById({ associationId: val._id }));
    navigate(`/editAssocGroups/${val._id}`);
  };

   const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  return (
    <Root>
      <Breadcrumb
        title={"Associations and Groups"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Associations and Groups",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">My Associations and Groups</h4>
              <p>Total {associationList.length} Associations and Groups.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div className="icon-box-surgery my-3 rounded px-4 px-lg-3 py-3 pointer ">
              <i class="fa-solid fa-user fa-2x my-2 text-white"></i>{" "}
              <h5 className="text-white w-100"> My Association My Group </h5>
            </div>
          </Col>
          <Col lg={4}>
            <Link to={"/BrowseAssocGroups"}>
              <div className="icon-box-livesurgery my-3 rounded px-4 px-lg-3 py-3 ">
                <i class="fa-solid fa-search fa-2x my-2 text-white"></i>{" "}
                <h5 className="text-white w-100">
                  {" "}
                  Browse Association/ Group{" "}
                </h5>
              </div>
            </Link>
          </Col>
          <Col lg={4}>
            <Link to={"/createAssocGroups"}>
              <div className="icon-box-surgery my-3 rounded px-4 px-lg-3 py-3 ">
                <i class="fa-solid fa-plus fa-2x my-2 text-white"></i>{" "}
                <h5 className="text-white w-100">
                  {" "}
                  Create Association/ Group{" "}
                </h5>
              </div>
            </Link>
          </Col>
          <Col md={12}>
            {associationList.length > 0 ? (
              <Card className="bg-white card rounded-3 mb-4">
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <tr>
                        {headerColumns.map((val, index) => (
                          <th key={val.key}>{val.name}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </tr>
                      <tbody>
                        {associationList.map((val, index) => (
                          <tr key={index}>
                            {headerColumns.map((headerValue, headerIdex) =>
                              headerValue.name === "Name" ? (
                                <td className="fw-bold">
                                  <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                    {headerValue.name}:
                                  </p>
                                  <div className="author d-flex align-items-center">
                                    <span className="user-image-wrap me-2">
                                      {/* <img
                                        src={require("../../../img/association-logo.jpg")}
                                        alt="Surgeon Name"
                                        width="44"
                                      /> */}
                                      {val?.group_image ? (
                                          <img
                                            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.group_image}`}
                                            alt=""
                                            title="Association Logo"
                                            className="img-fluid mb-3"
                                          />
                                        ) : (
                                          <img
                                            src={AssociationLogo}
                                            alt=""
                                            title="Association Logo"
                                            className="img-fluid mb-3"
                                          />
                                        )}
                                    </span>
                                    <span className="author-details">
                                      <p className="mb-0">
                                        {val[headerValue.key]}
                                      </p>
                                    </span>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                    {headerValue.name}:
                                  </p>
                                  <p className="mb-0">
                                    {" "}
                                    {val[headerValue.key]}
                                  </p>
                                </td>
                              )
                            )}
                            <td class="text-left text-lg-end">
                              {/* {val.group_type == "64968b6f238e4f50466e9f8e" || val.createdBy == currentUser ? ( */}
                              {/* {val.createdBy == currentUser ? ( */}
                                <Button
                                  class=" btn btn-info mb-2 btn-sm me-1"
                                  onClick={() => handleEdit(val)}
                                >
                                  {/* <i class="fa-solid fa-pencil me-1"></i>  */}
                                  Invite
                                </Button>
                              {/* ) : (null)} */}
                              
                              <a
                                href="javascript:void(0)"
                                class="mb-2 btn btn-link btn-sm"
                                onClick={() =>
                                  navigate(`/AssociationDetails/${val._id}`)
                                }
                              >
                                View <i class="fa-solid fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound description="You dont have any Assosiation Groups" />
            )}
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default MyAssocgroups;
