import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "react-bootstrap";

const MyComponent = ({ globalState, fetchFunction, hasMore }) => {
// const MyComponent = ({ globalState, loadMoreData, hasMore }) => {
  const fetchData = () => {
    fetchFunction();
  };

  return (
    <>
      {globalState.length >= 10 ? (
        <InfiniteScroll
          dataLength={globalState.length}
          next={fetchData}
          hasMore={hasMore}
          loader={<h6 className="w-100 text-center text-muted">Loading...</h6>} 
          endMessage={
            <h6 className="w-100 text-center text-muted">
              No more data to load.
            </h6>
          } 
        />
      ) : null}

      {/* {hasMore && globalState.length >= 10 ? (
          <Button
              className=" btn btn-light mb-2 btn-sm me-1"
              style={{width:"100%"}}
              onClick={loadMoreData}
            >
                Load More Data{" "}
                <i className="fa-solid fa-arrow-right"></i>
          </Button>
      ) : (
        <h6 className="w-100 text-center text-muted">
          No more data to load.
        </h6>
      ) } */}
    </>
  );
};

export default MyComponent;
