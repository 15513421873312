import axios from 'axios';
import { getCookie } from "../../utils/core";

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use(
    (config) => {
      const token = JSON.parse(localStorage.getItem('token'));

      // const token = getCookie("token");

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default API;