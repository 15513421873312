// import React, { useState, useEffect } from 'react';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function PDFViewer({ pdfUrl }) {
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const fetchPdfAsBase64 = async () => {
      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result;
          setPdfData(base64Data);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdfAsBase64();
  }, [pdfUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {pdfData ? (
        <Document
          file={pdfData}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      ) : (
        <div>Loading PDF...</div>
      )}
    </div>
  );
}

export default PDFViewer;
