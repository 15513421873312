import React, { useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { registeredObservership } from "../../../redux/actions/observership.action";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import moment from "moment";
import { getStatusFromDateString } from "../../../utils/core";
import { useNavigate } from "react-router-dom";
import { RESET_OBSERVERSHIP } from "../../../redux/constants/observershipActionTypes";

const AttendObserverCourse = () => {
  let navigate = useNavigate();

  const [headerLabel, setHeaderLable] = useState([
    { title: "Title", key: "title" },
    { title: "Speciality", key: "speciality" },
    { title: "Date", key: "date" },
    { title: "Faculty", key: "faculty" },
    { title: "Status", key: "status" },
  ]);
  const dispatch = useDispatch();
  const registeredObservershipList = useSelector(
    (state) => state.observership.registeredObservership
  );
  const pageNo = useSelector((state) => state.observership.page);
  const hasMore = useSelector((state) => state.observership.hasMore);

  useEffect(() => {
    dispatch(registeredObservership({ page: 1 }, true));
  }, []);

  useEffect(() => {
  
    return () => {
      dispatch({ type: RESET_OBSERVERSHIP });
    }
  }, [])
  

  const fetchObservership = () => {
    dispatch(registeredObservership({ page: pageNo }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Attend Observership Courses"}
        fieldOne="Dashboard"
        fieldTwo="Observership Courses"
        fieldThree="Attend Observership Courses"
      />
      <ContainerWrapper>
        <Row className=" mt-2">
          <Col>
            <div>
              <h2 className="h4 mb-1">Registered Observership Courses</h2>
              <p>
                You have {registeredObservershipList.length} Browse Observership
                Courses
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {registeredObservershipList.length > 0 ? (
              <Card className="bg-white rounded-3 mb-4">
                <Card.Body className="px-4 py-3">
                  <div className="table-responsive">
                    <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        {headerLabel.map((val, index) => (
                          <th className="p-2">{val.title}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {registeredObservershipList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Title:
                              </p>
                              <h6 class="mb-0">{val.observershipName}</h6>
                            </td>
                            <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Speciality:
                              </p>
                              <p class="mb-0">{val.speciality}</p>
                            </td>
                            <td>
                              <p class="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date:
                              </p>
                              <p class="mb-0">
                                {moment(val.startDate).format("D MMMM YYYY")} to{" "}
                                <br />{" "}
                                {moment(val.endDate).format("D MMMM YYYY")}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Faculty:
                              </p>
                              <div className="author d-flex">
                                <span className="user-image-wrap me-2">
                                  <img
                                    src="assets/images/user.jpg"
                                    alt="Surgeon Name"
                                    width="44"
                                  />
                                </span>
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <a href="surgeon-public-profile.html">
                                      {val.facultyName}
                                    </a>
                                  </p>
                                  <p className="text-muted mb-0">
                                    {val.facultyInstitute}
                                  </p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              <span
                                className={`badge ${
                                  getStatusFromDateString(val.endDate) ===
                                  "COMPLETED"
                                    ? "badge-success"
                                    : "badge-info"
                                }`}
                              >
                                {getStatusFromDateString(val.endDate)}
                              </span>
                            </td>
                            <td className="text-left text-lg-end">
                              <a
                                href="javascript:void(0)"
                                className="mb-2 btn btn-link btn-sm"
                                onClick={() => {
                                  navigate(`/observershipDetails/${val._id}`);
                                }}
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchObservership}
          globalState={registeredObservershipList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default AttendObserverCourse;
