import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Card from '../../components/Dashboard/Card';

// Import components
import Root from '../../components/Root';


const Roles = () => {

    return (
        <>
            <Root>
                <Breadcrumb 
                    fieldOne = "Roles & permissions"
                    fieldTwo = "Roles"
                />
                <div class="row">
                    {/* <Card /> */}
                </div>
            </Root>
        </>
    )
}

export default Roles;