import {
  GET_ALL_LIVESURGERYS,
  GET_LIVESURGERY_DETAILS,
  ADD_LIVESURGERY,
  EDIT_LIVESURGERY,
  MY_LIVESURGERYS,
  LIVESURGERY_INVITATION,
  MY_REGISTERED,
  RESET_LIVESURGERY,
} from "../constants/livesurgeryActionTypes";

const initialState = {
  surgeryList: [],
  mysurgeryList: [],
  myRegistered: [],
  getSurgeryDetails: "",
  like: 0,
  progress: 0,
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (livesurgery = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LIVESURGERYS:
      if (action.reset) {
        return {
          ...livesurgery,
          surgeryList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...livesurgery,
          surgeryList: [...livesurgery.surgeryList, ...action.payload],
          page: livesurgery.page + 1,
          hasMore: action.hasMore,
        };
      }
    case MY_REGISTERED:
      if (action.reset) {
        return {
          ...livesurgery,
          myRegistered: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...livesurgery,
          myRegistered: [...livesurgery.myRegistered, ...action.payload],
          page: livesurgery.page + 1,
          hasMore: action.hasMore,
        };
      }
    case MY_LIVESURGERYS:
      if (action.reset) {
        return {
          ...livesurgery,
          mysurgeryList: action.payload,
          page: 1,
          hasMore: action.hasMore,

        };
      } else {
        return {
          ...livesurgery,
          mysurgeryList: [...livesurgery.mysurgeryList, ...action.payload],
          page: livesurgery.page + 1,
          hasMore: action.hasMore,
        };
      }
    case GET_LIVESURGERY_DETAILS:
      return {
        ...livesurgery,
        getSurgeryDetails: action.payload,
      };
    case ADD_LIVESURGERY:
      return {
        ...livesurgery,
        surgeryList: action.payload,
      };
      case RESET_LIVESURGERY:
        return{
          ...initialState
        }
    default:
      return livesurgery;
  }
};
