export const GET_SENIOR_SURGEON_DETAILS = "GET_SENIOR_SURGEON_DETAILS";
export const GET_SURGEON_PROFILE_INFO = "GET_SURGEON_PROFILE_INFO";
export const MAKE_PAYMENT = "MAKE_PAYMENT";
export const CLEAR_PATIENT_DATA = "CLEAR_PATIENT_DATA";
export const UPDATE_PATIENT_SCHEDULE = "UPDATE_PATIENT_SCHEDULE";
export const UPDATE_PUBLIC_PROFILE = "UPDATE_PUBLIC_PROFILE";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";
export const GET_SURGEON_PROFILE = "GET_SURGEON_PROFILE";
export const GET_PATIENT_REFFERED = "GET_PATIENT_REFFERED";
export const GET_PATIENT_REFFERAL_RECEIVED = "GET_PATIENT_REFFERAL_RECEIVED";

