import {
  GET_MENTORSHIP_BY_ID,
  GET_REQUESTED_MENTORSHIP,
  REQUEST_MENTORSHIP,
  GET_USER_BY_ID
} from "../constants/mentorshipActionTypes";

const initialState = {
  mentorshipList: [],
  mentorshipById: {},
  mentorshipDoctor: {},
  requestedMentorship: [],
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (mentorship = initialState, action) => {
  switch (action.type) {
    case REQUEST_MENTORSHIP:
      return {
        ...mentorship,
        mentorshipList: action.payload,
      };
    case GET_MENTORSHIP_BY_ID:
      return {
        ...mentorship,
        mentorshipById: action.payload,
      };

    case GET_REQUESTED_MENTORSHIP:
      return {
        ...mentorship,
        requestedMentorship: action.payload,
      };
    case GET_USER_BY_ID:
      return {
        ...mentorship,
        mentorshipDoctor: action.payload,
      };

    default:
      return mentorship;
  }
};
