import { toast } from "react-toastify";
import * as api from "../../services/api";
import { doPayment } from "../../utils/payment";

import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";
import { ADD_NEW_PATIENT, GET_ALL_PATIENTS, GET_PATIENTS_PROCEDURE, GET_FILTER_PROCEDURE } from "../constants/registryActionTypes";



export const addNewPatient = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.addNewPatient(data);

    // console.log(result)

    if(result.data.success) {
      // console.log(result.data.data.subscription_status)
      if(!result.data.data.subscription_status) {
        let requestBody = {};
        const payResponse = await api.makePayment();   
        if(payResponse.data.status.success) {
          requestBody.user_id = payResponse.data.data.user_id;
          requestBody._id = payResponse.data.data._id;
          requestBody.members = payResponse.data.data.subscription;
          requestBody.type = "PATIENT_REGISTRY";
          requestBody.savedData = data;

          // console.log("requestBody", requestBody)
          await doPayment(requestBody);
          console.log(payResponse)
        }
      }
    }

    if (result.data.status.success) {


      dispatch({ type: CLEAR_LOADING });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_NEW_PATIENT, payload: data });
      return "success"
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return "error"
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while Saving Data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "error"

  }
};



export const getAllPatients = (data) => async (dispatch) => {
  try {
    const result = await api.getAllPatients(data);

    dispatch({ type: GET_ALL_PATIENTS, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};


export const addPatientProcedure = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.addPatientProcedure(data);

    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return "success"
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return "error"
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while Submiting", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "error"

  }
};


export const getProcedure = (data) => async (dispatch) => {
  try {
    const result = await api.getPatientProcedure(data);

    dispatch({ type: GET_PATIENTS_PROCEDURE, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};
export const getProcedureList = (data) => async (dispatch) => {
  try {
    // dispatch({ type: GET_FILTER_PROCEDURE, payload: [] })
    const result = await api.getProcedureList(data);

    dispatch({ type: GET_FILTER_PROCEDURE, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};
