import React from "react";

// Import components
import Layoutwrapper from "../layouts/LayoutWrapper";
import LayoutPage from "../layouts/LayoutPage";
import TopNavBar from "./TopNavBar/TopNavBar";
import Footer from "./Footer/Footer";

const Root = ({ children }) => {
  return (
    <>
      <Layoutwrapper>
        <TopNavBar />
        <LayoutPage>{children}</LayoutPage>
        <Footer/>
      </Layoutwrapper>
    </>
  );
};

export default Root;
 