import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { Form } from 'react-bootstrap';

const TextField = ({ ...props }) => {

    const [field, meta] = useField(props);

    return (
        <>
            <Form.Control 
                {...field} {...props}
                autoComplete="off"
            />
            <small className="text-danger position-absolute"><ErrorMessage name={field.name} /></small>
        </>
        
    )
}

export default TextField;