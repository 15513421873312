export const GET_ALL_ASSOCIATION = "GET_ALL_ASSOCIATION";
export const GET_ALL_ASSOCIATION_BY_FILTER = "GET_ALL_ASSOCIATION_BY_FILTER";
export const GET_ALL_ASSOCIATION_BY_ID = "GET_ALL_ASSOCIATION_BY_ID";
export const ADD_ASSOCIATION = "ADD_ASSOCIATION";
export const GET_ASSOCIATION_TYPE = "GET_ASSOCIATION_TYPE";
export const GET_ASSOCIATION_DROPDOWN = "GET_ASSOCIATION_DROPDOWN";
export const JOIN_ASSOCIATION = "JOIN_ASSOCIATION";
export const EXIT_ASSOCIATION = "EXIT_ASSOCIATION";
export const GET_MY_ASSOCIATION = "GET_MY_ASSOCIATION";
export const SET_ASSOCIATION_EDIT_ID = "SET_ASSOCIATION_EDIT_ID";
export const RESET_ASSOCIATION = "RESET_ASSOCIATION";
export const GET_ALL_ASSOCIATION_LIVESURGERYS = "GET_ALL_ASSOCIATION_LIVESURGERYS";
export const GET_ALL_ASSOCIATION_VIDEOS = "GET_ALL_ASSOCIATION_VIDEOS";
export const GET_ALL_ASSOCIATION_TUTORIAL = "GET_ALL_ASSOCIATION_TUTORIAL";
export const GET_ALL_ASSOCIATION_CONFERENCE = "GET_ALL_ASSOCIATION_CONFERENCE";
export const GET_ALL_ASSOCIATION_CME = "GET_ALL_ASSOCIATION_CME";

export const MY_ASSOCIATION_LIVESURGERYS = "MY_ASSOCIATION_LIVESURGERYS";
export const MY_ASSOCIATION_TUTORIAL = "MY_ASSOCIATION_TUTORIAL";
export const MY_ASSOCIATION_CONFERENCE = "MY_ASSOCIATION_CONFERENCE";
export const MY_ASSOCIATION_VIDEO = "MY_ASSOCIATION_VIDEO";
export const MY_ASSOCIATION_CME = "MY_ASSOCIATION_CME";

export const GET_ASSOCIATION_UPDATE = "GET_ASSOCIATION_UPDATE";
export const ADD_ASSOCIATION_UPDATE = "ADD_ASSOCIATION_UPDATE";
export const DELETE_ASSOCIATION_UPDATE = "DELETE_ASSOCIATION_UPDATE";
export const EDIT_ASSOCIATION_UPDATE = "EDIT_ASSOCIATION_UPDATE";

export const LIST_ASSOCIATION_MEMBERS = "LIST_ASSOCIATION_MEMBERS";
export const GET_ASSOCIATION_DASHBOARD = "GET_ASSOCIATION_DASHBOARD";







