import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";
import {
  GET_ALL_NOTIFICATIONS,
  MARK_READ_NOTIFICATION,
  SET_NEW_NOTIFICATION,
  GET_NOTIFICATIONS,
  NOTIFICATION_COUNT
} from "../constants/notificationTypes";

const initialState = {
  notifications: [],
  notificationList: [],
  page: 1,
  hasMore: true,
  notificationCount: 0,
};

export default (notification = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
      if (action.reset) {
        return {
          ...notification,
          notifications: action.payload,
          page: 1,
          hasMore: action.hasMore,
          // notificationCount: action.notificationCount,
        };
      } else {
        return {
          ...notification,
          notifications: [...notification.notifications, ...action.payload],
          page: notification.page + 1,
          hasMore: action.hasMore,
          // notificationCount: action.notificationCount,
        };
      }

    case SET_NEW_NOTIFICATION:
      return {
        ...notification,
        notifications: [action.payload, ...notification.notifications],
        // notificationCount: notification.notificationCount + 1,
      };

      // case MARK_READ_NOTIFICATION:
      //   return {
      //     ...notification,
      //   }
      case GET_NOTIFICATIONS:
        return {
          ...notification,
          notificationList: action.payload
        };
      case NOTIFICATION_COUNT:
        return {
          ...notification,
          notificationCount: action.payload
        }
    default:
      return notification;
  }
};
