import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getAllNotification,
  markAsRead,
} from "../../../redux/actions/notification.action";
import { timeAgo } from "../../../utils/core";

const Notification = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state) => state.notification.notifications
  );
  const notificationCount = useSelector(
    (state) => state.notification.notificationCount
  );
  const pageNo = useSelector((state) => state.notification.page);
  const hasMore = useSelector((state) => state.notification.hasMore);

  useEffect(() => {
    dispatch(getAllNotification({ page: 1 }, true));
  }, []);

  const fetchNotification = () => {
    dispatch(getAllNotification({ page: pageNo }));
  };

  const handleNotificationClick = (val) => {
    dispatch(markAsRead({ id: val._id }));
    if (val.type === "VIDEOS") {
      navigate(`/video-details/${val.to_id}`);
    } else if (val.type === "TUTORIAL") {
      navigate(`/tutorial-details/${val.to_id}`);
    } else if (val.type === "CONFERENCE") {
    }
  };

  const [headerLabel, setHeaderLable] = useState([
    { title: "Title", key: "title" },
    { title: "Description", key: "description" },
    { title: "Time Ago", key: "createdAt" },
  ]);
  return (
    <Root>
      <Breadcrumb title={"Notifications"} />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">All Notifications</h4>
              <p>Total {notificationCount} Notifications</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {notificationList.length > 0 ? (
              <Card className="bg-white rounded-3 mb-4">
                <Card.Body className="px-4 py-3">
                  <div className="table-responsive">
                    <Table className="table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        {headerLabel.map((val, index) => (
                          <th className="p-2">{val.title}</th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {notificationList.map((val, index) => (
                          <tr
                            onClick={() => handleNotificationClick(val)}
                            key={index}
                          >
                            {headerLabel.map((header, index) =>
                              header.key === "createdAt" ? (
                                <td>{timeAgo(val[header.key])}</td>
                              ) : (
                                <td>{val[header.key]}</td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <InfiniteScroll
          fetchFunction={fetchNotification}
          globalState={notificationList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default Notification;
