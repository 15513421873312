import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Pricing = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Pricing Policy"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Pricing Policy",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 col-lg-10 order-2 order-lg-1">
              <p className="title-meta mt-0">
                Pricing Policy <span className="line"></span>
              </p>
              <p>
                <strong>Fee Determination:</strong> The fees for services such
                as live conferences, online classes, mentorship programs, and
                other customized paid services are determined by the individual
                faculty or event organizers. The fees may vary from event to
                event, depending on the nature of the service, the faculty
                involved, and geographic location.
              </p>
              <p>
                <strong>Fee Changes:</strong> Fees are subject to change at the
                discretion of the organizers or faculty. Any change in fees will
                be communicated to participants before they register for the
                service. Once a participant registers and pays the fee, no
                retroactive fee changes will apply.
              </p>
              <p>
                <strong>Service-Specific Fees:</strong> The pricing for
                customized services may also vary depending on the nature of the
                service, the faculty involved, and the location of the
                participants. Fees may be revised periodically, and participants
                will be notified of any changes before registration.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  );
};

export default Pricing;
