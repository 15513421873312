import React from 'react'
import PublicRoot from '../../components/PublicRoot'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import DownloadAndroidIOS from '../../components/Download/DownloadAndroidIOS'

const About = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"About Us"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "About Us",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <p className="title-meta mt-0">About Us <span className="line"></span></p>
              <h2 className=" mb-3 display-6">Discover the amazing and patented world of <strong>learning</strong> & <strong>sharing surgical skills</strong></h2>
              <p className="lead">Surgic Share is a digital platform for <strong>expert</strong>, <strong>young</strong> &amp; <strong>future surgeons</strong> for <strong>learning</strong> and <strong>sharing surgical skills</strong>.</p>
              <p>Surgic Share provides digital platform of Zoom free of cost, to organize and attend CME / round table meeting with group/ association of surgeons, anytime as per your convenience, for unlimited period.</p>
              <p>Surgic Share provides platform for senior surgeons to post his/ her surgery videos &amp; Tutorials and at the same time it provides opportunity for young surgeons to search for surgery videos &amp; Tutorials of specific surgeons by filtering his/ her country, state, city and Institute of particular surgeons. Junior Surgeons can see surgery videos by filtering specialty, subspecialty or title of surgery.</p>
              <p>Surgic Share provides unique opportunity for senior surgeons to post his/ her upcoming observership course and for young surgeons opportunity to find specific Observership course by filtering Specialty, Subspecialty, Country, State and City. </p>
              <p>On the platform of Surgic Share, senior surgeons can post upcoming hands on training under him and interested surgeons can get details of upcoming hands on training course in his/ her nearest city or as per his/ her convenience.</p>
              <p>On the platform of Surgic Share, Surgeons can create open as well as close group of like minded surgeons and post in the group and at the same time, they can schedule Zoom meeting with the group member.</p>
              <p>On the platform of Surgic Share, young and future surgeons can request to Senior surgeons for Telementorship him/ her during surgery and they can request for online classes as well.</p>
              <p>On the Patient referral cell service of Surgic share, any surgeons can refer any patient to any surgeons of any specialty. Interested surgeons can create his profile to get referral patient from other members of Surgic Share.</p>
              <p>Personalized service like, Online Classes, Observership, Hands on training, Telementorship, Creation on patient referral profile on Surgic Share is paid service and depends on fee of faculty. Surgic Share will charge service charge of 10% from the personalized paid service and fixed amount for profile creation for patient referral.</p>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 mt-lg-4 mb-4">
              <img src={require("../../../img/hero-image.png")} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  )
}

export default About