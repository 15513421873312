import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import Root from "../../components/Root";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSurgeonProfile } from "../../../redux/actions/patientReferral.action";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import { timeAgo } from "../../../utils/core";
import { Row } from "react-bootstrap";

const SurgeonProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const surgeonProfile = useSelector(
    (state) => state.patientReferral.surgeonProfile
  );
  useEffect(() => {
    dispatch(getSurgeonProfile({ user_id: id }));
  }, []);

  return (
    <Root>
      <Breadcrumb
        title={"Surgeon Profile"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Surgeon Profile",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="d-flex align-items-stretch">
          <div className="col-lg-5 col-xl-4 d-flex align-items-stretch">
            <div className="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
              <div className="card-body px-4 py-4">
                <div className="">
                  <div className="mb-3">
                    <div className="author d-flex align-items-center">
                      <span className="user-image-wrap user-image-lg me-md-3 mb-3 mb-md-0">
                        {surgeonProfile?.basic_information?.profilePicture ? (
                            <img
                              src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${surgeonProfile?.basic_information?.profilePicture}`}
                              alt=""
                              title="Surgeon Profile"
                            />
                          ) : (
                            <img
                              src={require("../../../img/user.png")}
                              alt=""
                              title="Surgeon Profile"
                            />
                          )}
                      </span>
                      <span className="author-details">
                        <h2 className="h5 fw-bold mb-1">
                          {surgeonProfile?.basic_information?.name}
                        </h2>
                        <p className="mb-2 text-muted">
                          {surgeonProfile?.basic_information?.tag}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="col text-start pt-1">
                    <p className="">
                      <strong>Speciality:</strong>{" "}
                      {surgeonProfile?.basic_information?.speciality}
                    </p>
                    <p className="">
                      <strong>Designation:</strong>{" "}
                      {surgeonProfile?.basic_information?.designation}
                    </p>
                    <p className="">
                      <strong>Years of Experience:</strong>{" "}
                      {surgeonProfile?.basic_information?.year_of_exp}
                    </p>
                    <p className="">
                      <strong>Qualification:</strong>{" "}
                      {surgeonProfile?.basic_information?.qualification}
                    </p>
                    {/* <p className="">
                      <strong>Education:</strong>{" "}
                      {surgeonProfile?.basic_information?.education}
                    </p> */}
                    <p className="">
                      <strong>Institute:</strong>{" "}
                      {surgeonProfile?.basic_information?.institute}
                    </p>
                    <p className="">
                      <strong>Location:</strong>{" "}
                      {surgeonProfile?.basic_information?.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 d-flex align-items-stretch">
            <div className="overflow-hidden w-100">
              <div className="bg-white card overflow-hidden rounded-3 mb-4 ">
                <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
                  <div className="col-12 ">
                    <div>
                      <h2 className="h4 mb-1">Surgeon Information</h2>
                      <p>Education, Work Experience and others.</p>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-1 px-4 py-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-lg align-top">
                      <tbody>
                        <tr>
                          <td>
                            <p className="mb-0 fw-bold">Work Experience:</p>
                          </td>
                          <td>
                            <p className="mb-0">
                              {
                                surgeonProfile?.surgeon_information
                                  ?.work_experience
                              }
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0 fw-bold">Achievements:</p>
                          </td>
                          <td>
                            <p className="mb-0">
                              {
                                surgeonProfile?.surgeon_information
                                  ?.achievements
                              }
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0 fw-bold">Area of Intrest:</p>
                          </td>
                          <td>
                            <p className="mb-0">
                              {
                                surgeonProfile?.surgeon_information
                                  ?.area_of_interest
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="bg-white card overflow-hidden rounded-3 mb-4 ">
                <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
                  <div className="col-12 ">
                    <div>
                      <h2 className="h4 mb-1">Surgeon Schedule</h2>
                      <p>
                        Check the availability of surgeon and refer your
                        patient.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-1 px-4 py-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Day</th>
                        <th>Timing</th>
                        <th>Hospital</th>
                        <th>Contact Person</th>
                      </thead>
                      <tbody>
                        {surgeonProfile?.surgeon_schedules?.map((schedule) =>
                          schedule.schedules.map((res) => (
                            <tr>
                              <td className="">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Day:
                                </p>
                                <p className="mb-0 fw-bold">{res.day}</p>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Timing:
                                </p>
                                <p className="mb-0">
                                  {res.available_from} to {res.available_to}
                                </p>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Hospital:
                                </p>
                                <p className="mb-0">
                                  {res.institution?.name}
                                  <br />
                                  {res.city?.name},{res.state?.name},
                                  {res.country?.name}
                                </p>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Contact Person:
                                </p>
                                <p className="mb-0">
                                  {res.contact_name}
                                  <br /> <strong>{res.contact_number}</strong>
                                </p>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="col-12">
                    <div className="text-center">
                      <a
                        href="javascript:void(0)"
                        title="View more"
                        className="btn btn-primary mb-2"
                        onClick={() => navigate(`/patientInfo/${id}`)}
                      >
                        Refer My Patient <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="bg-white card overflow-hidden rounded-3 mb-4 ">
                <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
                  <div className="col-12 ">
                    <div>
                      <h3 className="h4 mb-1">Videos by Surgeon</h3>
                      <p>Videos uploaded by the surgeon.</p>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-1 px-4 py-4">
                  <div className="row">
                    {surgeonProfile?.videos?.map((val) => (
                      <div className="col-12 col-sm-6 col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <ThumbnailCard
                          badgeclassNameName="bg-video"
                          badgeTitle="Video"
                          cardTitle={val.title}
                          detailsLink={`/video-details/${val?._id}`}
                          cardId={val?._id}
                          viewsCount={`${val.views} Views`}
                          noOfDays={timeAgo(val.createdAt)}
                          mediaType={val.urlType}
                          url={val.url}
                          isHost={false}
                        />
                      </div>
                    ))}
                    <div className="col-12">
                      <div className="text-center">
                        <a
                          href="javascript:void(0)"
                          title="View more"
                          className="btn btn-light mb-2"
                          onClick={() => navigate("/browseSurgeryVideos")}
                        >
                          View More Videos{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white card overflow-hidden rounded-3 mb-4 ">
                <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
                  <div className="col-12 ">
                    <div>
                      <h3 className="h4 mb-1">Tutorials by Surgeon</h3>
                      <p>Tutorials uploaded by the surgeon.</p>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-1 px-4 py-4">
                  <div className="row">
                    {surgeonProfile?.tutorials?.map((val) => (
                      <div className="col-12 col-sm-6 col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <ThumbnailCard
                          badgeclassNameName="bg-video"
                          badgeTitle="Tutorial"
                          cardTitle={val.title}
                          detailsLink={`/tutorial-details/${val?._id}`}
                          cardId={val?._id}
                          viewsCount={`${val.views} Views`}
                          noOfDays={timeAgo(val.createdAt)}
                          mediaType={val.urlType}
                          url={val.url}
                          isHost={false}
                        />
                      </div>
                    ))}
                    <div className="col-12">
                      <div className="text-center">
                        <a
                          href="javascript:void(0)"
                          title="View more"
                          className="btn btn-light mb-2"
                          onClick={() => navigate("/browseTutorials")}
                        >
                          View More Tutorials{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default SurgeonProfile;
