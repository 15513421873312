import {
  CLEAR_LOADING,
  SET_LOADING,
  SET_USER_PROFILE_STATUS,
} from "../constants/globalActionTypes";

const initialState = {
  globalLoading: false,
  isCompleteProfile: false,
};

export default (globalState = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...globalState,
        globalLoading: true,
      };
    case CLEAR_LOADING:
      return {
        ...globalState,
        globalLoading: false,
      };
    case SET_USER_PROFILE_STATUS:
      return {
        ...globalState,
        isCompleteProfile: action.payload
      };

    default:
      return globalState;
  }
};
