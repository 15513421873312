import { POST_SIGNIN, POST_SIGNUP, SET_USER_DATA, FORGOT_PASSWORD_SENT, RESET_PASSWORD_SUCCESS } from "../constants/authActionTypes";
import { UPDATE_PUBLIC_PROFILE } from "../constants/patientReferralActionTypes";

const initialState = {
  userProfile: {},
  isForgot: false,
  isResetPassword: false,
};

export default (auth = initialState, action) => {
  switch (action.type) {
    case POST_SIGNIN:
      if (action.payload.data.data.access_token) {
        // localStorage.setItem('email_id', action.payload.data.data.email);
        // localStorage.setItem('contact_number', action.payload.data.data.mobile);
        
        return {
          ...auth,
          userProfile: action.payload.data.data,
        };
      } else {
        return action.payload;
      }
      return action.payload;
    case SET_USER_DATA:
      return {
        ...auth,
        userProfile: action.payload,
      };
    case UPDATE_PUBLIC_PROFILE:
      return{
        ...auth,
        userProfile:{...auth.userProfile,...action.payload}
      };
    case FORGOT_PASSWORD_SENT:
      return{
        ...auth,
        isForgot: true
      };
    case RESET_PASSWORD_SUCCESS:
      return{
        ...auth,
        isResetPassword: true
      };
    default:
      return auth;
  }
};
