import Home from "../views/components/Home";
import About from "../views/pages/PublicScreens/About";
import TermsCondition from "../views/pages/PublicScreens/Terms";
import PrivacyPolicy from "../views/pages/PublicScreens/Privacy";
import PricingPolicy from "../views/pages/PublicScreens/Pricing";
import CancellationPolicy from "../views/pages/PublicScreens/Cancellation";
import Contact from "../views/pages/PublicScreens/Contact";
import Gallery from "../views/pages/PublicScreens/Gallery";
import GalleryDetails from "../views/pages/PublicScreens/GalleryDetails";
import Services from "../views/pages/PublicScreens/Services";
import Specialities from "../views/pages/PublicScreens/Specialities";

const publicRoutes = [
  {
    path: "/",
    component: <Home />,
    title: "Surgic Share | Home",
  },
  {
    path: "/home",
    component: <Home />,
    title: "Surgic Share | Home",
  },
  {
    path: "/speciality",
    component: <Specialities />,
    title: "Surgic Share | Home",
  },
  {
    path: "/services",
    component: <Services />,
    title: "Surgic Share | Home",
  },
  {
    path: "/gallery",
    component: <Gallery />,
    title: "Surgic Share | Home",
  },
  {
    path: "/about",
    component: <About />,
    title: "Surgic Share | Home",
  },
  {
    path: "/contact",
    component: <Contact />,
    title: "Surgic Share | Home",
  },
  {
    path: "/home/gallery-details",
    component: <GalleryDetails />,
    title: "Surgic Share | Home",
  },
  {
    path: "/terms-conditions",
    component: <TermsCondition />,
    title: "Surgic Share | Terms and Conditions",
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    title: "Surgic Share | Privacy Policy",
  },
  {
    path: "/pricing-policy",
    component: <PricingPolicy />,
    title: "Surgic Share | Pricing Policy",
  },
  {
    path: "/cancellation-and-Refund-Policy",
    component: <CancellationPolicy />,
    title: "Surgic Share | Cancellation and Refund Policy",
  },
];

export default publicRoutes;
