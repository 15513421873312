import React from "react";

// Import components
import Layoutwrapper from "../layouts/LayoutWrapper";
import LayoutPage from "../layouts/LayoutPage";
import Footer from "./Footer/Footer";
import PublicTopNavBar from "./TopNavBar/PublicTopNavBar";
import PublicFooter from "./Footer/PublicFooter";

const PublicRoot = ({ children }) => {
  return (
    <>
      <Layoutwrapper>
        <PublicTopNavBar />
        <LayoutPage>{children}</LayoutPage>
        <PublicFooter/>
      </Layoutwrapper>
    </>
  );
};

export default PublicRoot;
 