import React from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import TextField from '../../components/TextField/TextField';

// Styles sheets
// import '../../../globalstyles/css/pages/page-auth.css'

// Import Actions
import { signIn } from '../../../redux/actions/auth.action';

const Signin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validate = Yup.object({
        user_name: Yup.string().min(11, 'Must have minimun 11 characters.').required('User name required'),
        password: Yup.string().min(6, 'Must have minimun 6 characters').required('Password field required')
    });
    
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                    </span>
                                    <span className="app-brand-text demo fw-bolder">CRM ADMIN</span>
                                </Link>
                            </div>
                            <h4 className="mb-2">Welcome to CRM Admin! 👋</h4>
                            <p className="mb-4">Please sign-in to continue</p>

                            <Formik
                                initialValues={{
                                    user_name: '',
                                    password: ''
                                }}
                                validationSchema = {validate}
                                onSubmit = { values => {
                                    dispatch(signIn(values, navigate));
                                }}
                            >

                                {formik => (
                                    <Form id="formAuthentication" className="mb-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Username</label>
                                            <TextField type="text" id="email" name="user_name" placeholder="Enter your username" autoFocus />
                                        </div>
                                        <div className="mb-3 form-password-toggle">
                                        <div className="d-flex justify-content-between">
                                            <label className="form-label" htmlFor="password">Password</label>
                                            <Link to="/forgot-password">
                                            <small>Forgot Password?</small>
                                            </Link>
                                        </div>
                                        <div className="mb-3">
                                            <TextField type="password" id="password" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" />
                                        </div>
                                        </div>
                                        <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-me" />
                                            <label className="form-check-label" htmlFor="remember-me">
                                            Remember Me
                                            </label>
                                        </div>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                                        </div>
                                    </Form>
                                )}

                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin;