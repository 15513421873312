import {
  GET_ALL_MEETINGS,
  GET_MEETING_DETAILS,
  ADD_MEETING,
  EDIT_MEETING,
  MY_MEETINGS,
  MEETING_INVITATION,
  ACCEPT_REJECT_MEETING,
  ACCEPT_DECLINE_MEETING
} from "../constants/meetingActionTypes";

const initialState = {
  meetingsList: [],
  myMeetingLists: [],
  myInvitationsList: [],
  meetingDetails: [],
  like: 0,
  progress: 0,
  isLoading: false,
  page: 1,
  hasMore: true,
};

export default (meeting = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MEETINGS:
      if (action.reset) {
        return {
          ...meeting,
          meetingsList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...meeting,
          meetingsList: [
            ...meeting.meetingsList,
            ...action.payload,
          ],
          page: meeting.page + 1,
          hasMore: action.hasMore,
        };
      }
    case MY_MEETINGS:
      if (action.reset) {
        return {
          ...meeting,
          myMeetingLists: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...meeting,
          myMeetingLists: [
            ...meeting.myMeetingLists,
            ...action.payload,
          ],
          page: meeting.page + 1,
          hasMore: action.hasMore,
        };
      }
    case MEETING_INVITATION:
      if (action.reset) {
        return {
          ...meeting,
          myInvitationsList: action.payload,
          page: 1,
          hasMore: action.hasMore,
        };
      } else {
        return {
          ...meeting,
          myInvitationsList: [
            ...meeting.myInvitationsList,
            ...action.payload,
          ],
          page: meeting.page + 1,
          hasMore: action.hasMore,
        };
      }
    case ADD_MEETING:
      return {
        ...meeting,
        meetingsList: action.payload,
        meetingDetails: []
      };
    case GET_MEETING_DETAILS:
      return {
        ...meeting,
        meetingDetails: action.payload
      };
    case ACCEPT_DECLINE_MEETING:
      const updatedInvitationsList = meeting.myInvitationsList.map(invitation => {
        const { meetingId, userId } = action.payload;

        if (invitation._id === meetingId) {
          const updatedMembers = invitation.members.map(member => {
            if (member.userId === userId) {
              return { ...member, isAccept :action.payload.status }; 
            }
            return member;
          });
          return { ...invitation, members: updatedMembers };
        }
        return invitation;
      });

      return {
        ...meeting,
        myInvitationsList: updatedInvitationsList,
      };
    default:
      return meeting;
  }
};
