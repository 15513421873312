import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo } from "../../../redux/actions/video.action";
import {
  getSpeciality,
  getSubSpeciality,
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const UploadSurgeryVideo = () => {
  let { associationId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const [fileError, setFileError] = useState(false);

  const paidStatus = [
    { key: "Yes", value: 1 },
    { key: "No", value: 0 },
  ];

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const handleFileInputChange = (event) => {
    const fileDetails = event.currentTarget.files[0];
    const fileType = fileDetails.type;
    const dataArray = fileType.split("/");
    if (dataArray[0] == "video") {
      setFileError(false);
    } else {
      setFileError(true);
      return;
    }

    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
  }, []);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      dispatch(getSubSpeciality({ speciality_id: value }));
    }
  };

  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const requestData = new FormData();

    if (fileError) {
      return;
    }

    requestData.append("speciality", values.speciality);
    if (values.sub_speciality && values.sub_speciality != "") {
      requestData.append("sub_speciality", values.sub_speciality);
    }
    requestData.append("paid_telementorship", values.paid_telementorship);
    requestData.append("paid_training", values.paid_training);
    requestData.append("title", values.title);
    requestData.append("patient_details", values.patient_details);
    requestData.append("attachmentFile", file);
    requestData.append("attachmentUrl", values.attachmentUrl);
    requestData.append("urlType", values.type);
    if (associationId) {
      requestData.append("assocId", associationId);
    }

    dispatch(uploadVideo(requestData, navigate)).then((res) => {
      setSubmitting(false);
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
    });

    // Reset the form after submission
    // setFile(null);
    // resetForm();
  };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    // sub_speciality: Yup.string(),
    title: Yup.string().trim().required("Video title is required"),
    patient_details: Yup.string().trim(),
    paid_telementorship: Yup.number(),
    paid_training: Yup.number(),
    attachment: Yup.mixed(),
    agreement1: Yup.boolean().oneOf([true], "You must confirm the data"),
    agreement2: Yup.boolean().oneOf([true], "You must confirm the data"),
    type: Yup.string().oneOf(["video", "url"]).required("Type is required"),
    attachment: Yup.mixed().when("type", {
      is: "video",
      then: Yup.mixed().required("File is required"),
      otherwise: Yup.mixed(),
    }),
    attachmentUrl: Yup.string().when("type", {
      is: "url",
      then: Yup.string().url("Invalid URL").required("URL is required"),
      otherwise: Yup.string(),
    }),
  });

  return (
    <Root>


      {associationId ? (
        <Breadcrumb
          title={"Surgery Videos"}
          items={[
            {
              name: "Association Dashboard", routeTo: `/AssociationDetails/${associationId}`,
            },
            {
              name: "Upload Your Surgery Video",
            },
          ]}
        />
      ) : (
        <Breadcrumb
          title={"Surgery Videos"}
          fieldOne="Dashboard"
          fieldTwo="Upload Your Surgery Video"
        />
      )}

      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Upload Your Surgery Video</h2>
                    <p>
                      Fill in the below details and upload surgery video. Fields
                      with <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={{
                    speciality: "",
                    sub_speciality: "",
                    title: "",
                    patient_details: "",
                    paid_telementorship: 0,
                    paid_training: 0,
                    attachment: "",
                    attachmentUrl: "",
                    agreement1: false,
                    agreement2: false,
                    type: "video",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => {
                    console.log('Formik Values:', formik.values);

                    return (
                      <Form className="row g-3 g-lg-4">
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Speciality<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="speciality"
                            className="form-select"
                            onChange={(e) =>
                              handleChange(e, formik.setFieldValue, dispatch)
                            }
                          >
                            <option value="">Select</option>
                            {specialityList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.specialityName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="speciality"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Subspeciality
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="sub_speciality"
                            className="form-select"
                            onChange={(e) =>
                              handleChange(e, formik.setFieldValue, dispatch)
                            }
                          >
                            <option value="">Select</option>
                            {subSpecialityList.map((val, index) => (
                              <option key={index} value={val._id}>
                                {val.subSpecialityName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="sub_speciality"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          <ReactForm.Label className="form-label">
                            Video Title
                            <span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field
                            type="text"
                            name="title"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          <ReactForm.Label className="form-label">
                            Patient Profile (Non Personal)
                          </ReactForm.Label>
                          <Field
                            as="textarea"
                            name="patient_details"
                            className="form-control"
                            rows="4"
                          />
                          <ErrorMessage
                            name="patient_details"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Availability for Paid Telementoring
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="paid_telementorship"
                            className="form-select"
                          >
                            {paidStatus.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.key}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="paid_telementorship"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={6}>
                          <ReactForm.Label className="form-label">
                            Availability for Training/ Observership/ Expert
                            Opinion
                          </ReactForm.Label>
                          <Field
                            as="select"
                            name="paid_training"
                            className="form-select"
                          >
                            {paidStatus.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.key}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="paid_training"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          <ReactForm.Label className="form-label">
                            Type<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <Field as="select" name="type" className="form-select">
                            <option value="video">Video</option>
                            <option value="url">URL</option>
                          </Field>
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="text-danger text-small"
                          />
                        </Col>
                        <Col md={12}>
                          {formik.values.type === "video" ? (
                            <div>
                              <ReactForm.Label>
                                Select Video File
                                <span className="text-danger">*</span>
                              </ReactForm.Label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  name="attachment"
                                  className="form-control"
                                  onChange={(e) => {
                                    handleFileInputChange(e);
                                    formik.setFieldValue('attachment', e.currentTarget.files[0]); // Set the value for Formik
                                  }}
                                  ref={fileInputRef}
                                />
                              </div>
                              <ErrorMessage
                                name="attachment"
                                component="div"
                                className="text-danger text-small"
                              />
                              {fileError ? (
                                <p style={{ color: '#f64e60', fontWeight: '600' }}>Only video file is supported</p>
                              ) : (
                                null
                              )}
                            </div>
                          ) : (
                            <div>
                              <ReactForm.Label>
                                Enter Video URL
                                <span className="text-danger">*</span>
                              </ReactForm.Label>
                              <Field
                                type="url"
                                name="attachmentUrl"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="attachmentUrl"
                                component="div"
                                className="text-danger text-small"
                              />
                            </div>
                          )}
                        </Col>
                        <Col md={12}>
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              name="agreement1"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="agreement1"
                            >
                              I confirm, patient’s identity has not been revealed
                              directly or indirectly in the video.
                            </label>
                            <ErrorMessage
                              name="agreement1"
                              component="div"
                              className="text-danger text-small"
                            />
                          </div>
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              name="agreement2"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="agreement2"
                            >
                              I confirm, surgery in uploaded surgery video was
                              performed by me and I take accountability of
                              authenticity of video. Surgery video is for academic
                              purpose only.
                            </label>
                            <ErrorMessage
                              name="agreement2"
                              component="div"
                              className="text-danger text-small"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          Note: Video uploaded will be published on website within
                          two working days, post basic check list.
                        </Col>
                        <Col className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            Post Surgery Video{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Col>
                      </Form>
                    )
                  }
                  }
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default UploadSurgeryVideo;
