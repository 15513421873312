import React, { useEffect, useState, useCallback } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients, getProcedure, getProcedureList } from "../../../redux/actions/registry.action";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getSpeciality,
  getSubSpeciality,
  getCities,
  getCountries,
  getInstitute,
  getStates,
  getFaculty,
  getFacultySpeciality
} from "../../../redux/actions/filter.action";


const TrackPatients = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(
    (state) => state.registry.procedureList
  );
  const filterProcedureList = useSelector((state) => state.registry.filterProcedure);

  const pageNo = useSelector((state) => state.patientReferral.page);
  const hasMore = useSelector((state) => state.patientReferral.hasMore);

  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  // const facultyList = useSelector((state) => state.filters.specialityfaculties);
  const facultyList = useSelector((state) => state.filters.faculties);

  const { patientId } = useParams();

  const initialState = {
    id: null,
    country: null,
    state: null,
    city: null,
    institute: null,
    operating_surgeon: null,
    assistant_surgeon: null
  };

  const [formValues, setFormValues] = useState(initialState);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (countryList.length === 0) {
      dispatch(getCountries());
    }

    dispatch(getProcedureList({ patient_id: patientId }));
    dispatch(getProcedure({ patient_id: patientId, page: 1 }, true));
  }, []);


  const loadData = useCallback(() => {
    const payload = {
      page: 1,
      patient_id: patientId,
      ...formValues,
    };
    dispatch(getProcedure(payload, true));
  }, [formValues, dispatch, patientId]);



  const handleChange = async (e, dispatch) => {
    const { name, value } = e.target;

    if(value == "") {
      return;
    }

    // Use the functional form of setFormValues to ensure you get the most recent state
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));

    if (name === 'country') {
      await dispatch(getStates({ country_id: value }));
    } else if (name === 'state') {
      await dispatch(getCities({ state_id: value }));
    } else if (name === 'city') {
      await dispatch(getInstitute({ city_id: value }));
    } else if (name === 'institute') {
      await dispatch(getFaculty({ instituteId: value }));
      // setInstId(value)
      // dispatch(getFacultySpeciality({ instituteId: value, speciality: specialId }));
    }

  };


  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };

    fetchData();
  }, [loadData]);


  return (
    <Root>
      <Breadcrumb
        title={"Patient Registry"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Track Patient Registry",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Track Patient Registry</h4>
              <p>Total {patientList?.length} procedure.</p>
            </div>
          </Col>
        </Row>
  
        <div class="filter-wrap mb-3 border rounded p-4">
          <Row className="row g-3 g-lg-4 mb-3">
            <Col md={4}>
               <select 
                className="form-select" 
                name="id"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select Procedure</option>
                {filterProcedureList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.procedure_name}
                  </option>
                ))}
              </select>
            </Col>
            {/* <Col md={4}>
              <input
                type="date"
                name="procedureDate"
                className="form-select" 

              />
            </Col>
            <Col md={4}>
              <input
                type="date"
                name="entryDate"
                className="form-select" 

              />
            </Col> */}
          {/* </Row> */}
          {/* <Row className="row g-3 g-lg-4 mb-3"> */}
            <Col md={4}>
              <select 
                className="form-select" 
                name="country"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select Country</option>
                {countryList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.countryName}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <select 
                className="form-select" 
                name="state"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select State</option>
                {stateList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.stateName}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <select 
                className="form-select" 
                name="city"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select City</option>
                {cityList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.cityName}
                  </option>
                ))}
              </select>
            </Col>
          {/* </Row> */}
          {/* <Row className="row g-3 g-lg-4 mb-3"> */}
            <Col md={4}>
              <select 
                className="form-select" 
                name="institute"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select Institute</option>
                {instituteList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.instituteName}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <select 
                className="form-select" 
                name="operatingSergeon"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select Operating Surgeon</option>
                {facultyList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.fullname}
                  </option>
                ))}
              </select>
            </Col>
            {/* <Col md={4}>
              <select 
                className="form-select" 
                name="assistantSergeon"
                onChange={(e) =>
                  handleChange(e, dispatch)
                }
              >
                <option value="">Select Assistant Surgeon</option>
                {facultyList.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.fullname}
                  </option>
                ))}
              </select>
            </Col> */}
          </Row>
        </div>

        <Row>
          <Col>
            <Card className="bg-white card rounded-3 mb-4">
              {patientList?.length > 0 ? (
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Procedure Name</th>
                        <th>Procedure Date</th>
                        <th>Procedure Details</th>
                        <th>Operating Surgeon</th>
                      </thead>
                      <tbody>
                        {patientList.map((val) => (
                          <tr>
                            
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Procedure Name:
                              </p>
                              <p className="fw-bold mb-0">{val.procedure_name}</p>

                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                               Procedure Date:
                              </p>
                              <p className="fw-bold mb-0">{val.procedure_date}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                               Procedure Details:
                              </p>
                              <p className="fw-bold mb-0">{val.procedure_brief}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Operating Surgeon:
                              </p>
                              <p className="mb-0">{`${val.operatingSurgeonData[0].firstName} ${val.operatingSurgeonData[0].lastName}`}</p>
                            </td>

             
                          </tr>
                        ))}
                        
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              ) : (
                <NoDataFound />
              )}
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default TrackPatients;
