import React, { useEffect, useRef } from "react";
import Root from "../../../components/Root";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../../components/Cards/ThumbnailCard";
// import Filters from "../../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllVideos } from "../../../../redux/actions/recorded.conference.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo } from "../../../../utils/core";
import { RESET_VIDEOS } from "../../../../redux/constants/recordedConferenceActionTypes";
import { SET_LOADING } from "../../../../redux/constants/globalActionTypes";

const BrowseSurgeryVideo = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const { associationId } = useParams();
  const videosList = useSelector((state) => state.recordedConference.videosList);
  const pageNo = useSelector((state) => state.recordedConference.page);
  const hasMore = useSelector((state) => state.recordedConference.hasMore);

  const selectedFilter = useSelector((state) => state.filters.selectedFilters);

  useEffect(() => { }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    if (isInitialMount.current) {
      dispatch(getAllVideos({ ...selectedFilter, page: pageNo, associationId: associationId }));
      isInitialMount.current = false;
    } else {
      dispatch(getAllVideos({ ...selectedFilter, page: 1, associationId: associationId }, true));
    }
  }, [selectedFilter]);

  const fetchVideos = () => {
    dispatch(getAllVideos({ ...selectedFilter, page: pageNo, associationId: associationId }));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_VIDEOS });
    };
  }, []);


  return (
    <Root>
      {/* <Breadcrumb
        title={"Recorded Conference"}
        fieldOne="Dashboard"
        fieldTwo="Browse Recorded Conference"
      /> */}

      <Breadcrumb
        title={"Recorded Live Surgery / Web CME"}
        items={[
          {
            name: "Association Dashboard",  routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Browse Recorded Live Surgery / Web CME",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Recorded Live Surgery / Web CME</h4>
              <p>Browse Recorded Live Surgery / Web CME from top surgeons of your field.</p>
            </div>
            <div className="d-lg-block">
              <Button
                title="Upload Recorded Conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate(`/upload-recorded-conference/${associationId}`);
                }}
              >
                Upload{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>{" "}
              <Button
                title="Recorded conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate(`/my-recorded-conference/${associationId}`);
                }}
              >
                My Uploads{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </Button>

            </div>
          </Col>
        </Row>
        {/* <div className="filter-wrap mb-3 border rounded">
          <div className="d-grid">
            <button
              type="button"
              className=" btn btn-link btn-block d-block d-xl-none"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {" "}
              <i className="fa-solid fa-filter me-1"></i> Filter
            </button>
          </div>

          <Filters title="Filter Surgery Videos" module="observership" />
        </div> */}
        <Row>
          {videosList.length > 0 ? (
            videosList.map((val, index) => (
              <Col sm={6} lg={6} xl={3} className=" d-flex align-items-stretch">
                <ThumbnailCard
                  badgeClassName="bg-video"
                  badgeTitle="Conference"
                  cardTitle={val.title}
                  detailsLink={`/recorded-conference/${val._id}`}
                  cardId={val._id}
                  doctorName={`Dr. ${val.user_details[0].firstName} ${val.user_details[0].lastName}`}
                  doctorId={val.user_details[0]._id}
                  doctorAddress={`${val.user_details[0].institution.instituteName}, ${val.user_details[0].country.state.city.cityName}`}
                  viewsCount={`${val.views} Views`}
                  noOfDays={timeAgo(val.createdAt)}
                  mediaType={"video"}
                  url={val.url}
                  isHost={false}
                  profilePicture={val.user_details[0].profilePicture}
                />
              </Col>
            ))
          ) : (
            <NoDataFound />
          )}
        </Row>
        <InfiniteScroll
          fetchFunction={fetchVideos}
          globalState={videosList}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseSurgeryVideo;
