import React from 'react';
import ContainerWrapper from '../../layouts/ContainerWrapper';
import { Card, Col, Row } from 'react-bootstrap';
import logo from "../../../img/logo.svg";

const LinkExpired = () => {
    return (
        <main className="main pt-0 bg-light">
            <section className="py-4">
                <ContainerWrapper>
                    <Row>
                        <Col lg={8} xl={6} className="offset-lg-2 offset-xl-3">
                            <Card className="bg-white rounded-3 mb-4 shadow-lg">
                                <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                                    <Col className="text-center">
                                        <img src={logo} alt="Logo" className="mb-4" />
                                    </Col>
                                </Card.Header>
                                <Card.Body className="pt-2 px-4 pb-4">
                                   <h1 style={{color: "red", textAlign: "center"}}>Link Expired</h1>
                                </Card.Body>
                                <hr className="mb-0" />
                            </Card>
                        </Col>
                    </Row>
                </ContainerWrapper>
            </section>
        </main>
    )
}

export default LinkExpired