import React from "react";
import { RWebShare } from "react-web-share";


const Share = (props) => {

  const shareContent = async () => {

    const text = `MASOCON 2024- live surgical oncology procedures\n\nLive surgical oncology procedures:\n- Day 1: Laparoscopic Colon/Rectum, Breast and Chemoport Whipple’s\n- Day 2: VATS esophagectomy with extended 2F lymphadenectomy, Thyroid/Neck dissection, Lap total gastrectomy\n\n${props.url}`;

    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Hide the textarea
    textarea.style.position = 'fixed';
    textarea.style.opacity = 0;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Copy the selected text to the clipboard
      document.execCommand('copy');
      console.log('Content copied to clipboard');
      alert('Content copied to clipboard. You can now paste it to share.');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    } finally {
      // Remove the textarea from the DOM
      document.body.removeChild(textarea);
    }

  };  


  return (
    <div>
      <div onClick={shareContent} style={{ color: "#000684", cursor: "pointer" }}>
        <strong>Invite People</strong>
        <i className="fa-solid fa-share-nodes"></i>
      </div>
    </div>
  );
};

export default Share;
