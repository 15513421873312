import {
  CLEAR_FILTER,
  GET_FILTER_CITIES,
  GET_FILTER_COUNTRY,
  GET_FILTER_INSTITUTE,
  GET_FILTER_SPECIALITY,
  GET_FILTER_STATES,
  GET_FILTER_SUB_SPECIALITY,
  SET_SELECTED_FILTER,
  GET_FACULTY,
  GET_DESIGNATION,
  GET_YEAR_OF_EXPERIENCE,
  GET_QUALIFICATION,
  GET_REQUEST_TYPE,
  GET_FACULTY_SPECIALITY,
  GET_COUNTRY_CODE
} from "../constants/filterActionTypes";

const initialState = {
  speciality: [],
  subSpeciality: [],
  states: [],
  countries: [],
  contryCodes: [],
  cities: [],
  institutes: [],
  faculties: [],
  specialityfaculties: [],
  selectedFilters: {
    country:"",
    state:"",
    city:"",
    institution:"",
    speciality:"",
    subspeciality:""
  },
  designation:[],
  yearOfExperience: [],
  qualification:[],
  requestType:[]
};

export default (filters = initialState, action) => {
  switch (action.type) {
    case GET_FILTER_SPECIALITY:
      return {
        ...filters,
        speciality: action.payload,
      };
    case GET_FILTER_SUB_SPECIALITY:
      return {
        ...filters,
        subSpeciality: action.payload,
      };
    case GET_FILTER_COUNTRY:
      return {
        ...filters,
        countries: action.payload,
      };
    case GET_COUNTRY_CODE:
      return {
        ...filters,
        contryCodes: action.payload,
      };
    case GET_FILTER_STATES:
      return {
        ...filters,
        states: action.payload,
      };
    case GET_FILTER_CITIES:
      return {
        ...filters,
        cities: action.payload,
      };
    case GET_FILTER_INSTITUTE:
      return {
        ...filters,
        institutes: action.payload,
      };
    case GET_FACULTY:
      return {
        ...filters,
        faculties: action.payload,
      };
    case GET_FACULTY_SPECIALITY:
      return {
        ...filters,
        specialityfaculties: action.payload,
      };
    case GET_DESIGNATION:
      return {
        ...filters,
        designation: action.payload,
      };
    case GET_YEAR_OF_EXPERIENCE:
      return {
        ...filters,
        yearOfExperience: action.payload,
      };

      case GET_QUALIFICATION:
      return {
        ...filters,
        qualification: action.payload,
      };

      case GET_REQUEST_TYPE:
      return {
        ...filters,
        requestType: action.payload,
      };
      
    case SET_SELECTED_FILTER:
      return {
        ...filters,
        selectedFilters: {
          ...filters.selectedFilters,
          [action.name]: action.payload,
        },
      };
    case CLEAR_FILTER:
      return {
        ...filters,
        subSpeciality: [],
        states: [],
        // countries: [],
        cities:[],
        institutes:[],
        faculties:[],
        specialityfaculties:[],
        selectedFilters: {
          speciality: "",
          subspeciality: "",
          state: "",
          country: "647463309596a53f50eedb1d",
          city: "",
          institution: ""
        },
      };
    default:
      return filters;
  }
};
