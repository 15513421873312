import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import YouTubePlayer from "../../components/VideoPlayers/YouTubePlayer";
import IframeRender from "../VideoPlayers/IframeRender";

const ThumbnailCard = (props) => {
  let navigate = useNavigate();

  const handleNavigation = () => {
    if (props.detailsLink) {
      navigate(props.detailsLink);
    }
  };

  const renderPlayer = () => {

    if (props.url && props.mediaType) {
      if (props.mediaType === "video") {
        return (
          // <IframeRender
          //   videoUrl={`https://player.vimeo.com/video/${props.url}`}
          // />
          
          <iframe
            width={"100%"}
            height={"170px"}
            src={`https://player.vimeo.com/video/${props.url}`}
            title="Surgicshare video player"
            frameborder="0"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        );
      } else if (props.mediaType === "url") {
        var urlData = props.url;
        const urlArray = urlData.split("?");
        if(urlArray.length >= 2) {
          const urlId = urlArray[1].split("=");
          const modifiedUrl = `https://www.youtube.com/embed/${urlId[1]}`;
          return <iframe width={"100%"} height={"170px"} allow="fullscreen;" src={modifiedUrl}></iframe>;
        } else {
          return <iframe width={"100%"} height={"170px"} allow="fullscreen;" src={props.url}></iframe>;
        }
      } else if (
        props.mediaType === "ppt" ||
        props.mediaType === "pdf" ||
        props.mediaType === "document"
      ) {
        return (
          // <IframeRender
          //   videoUrl={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props.url}`}
          // />

          <iframe
            width={"100%"}
            height={"170px"}
            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props.url}`}
            title="Surgicshare Document viewer"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        );
      } else {
        return (
          <img
            width={"100%"}
            height={"170px"}
            src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props.url}`}
            className="card-img-top"
            alt=""
          />
        );
      }
    } else {
      return (<></>);
      // return (
      //   <img
      //     src="https://dummyimage.com/16:9x1080"
      //     className="card-img-top"
      //     alt=""
      //   />
      // );
    }
  };

  return (
    <Card className="event-card  bg-white border w-100 mb-4">
      <Link 
        to={props.detailsLink}
      >
        <div className="card-thumb-wrap">
          { renderPlayer()}
        </div>
      </Link>
      {/* <a href="javascript:void(0)" onClick={() => handleNavigation()} title="">
        
      </a> */}
      <Card.Body className="card-body px-3 px-lg-4 pt-3 pb-3">
        <Row>
          <Col>
            <span
              className={`badge ${
                props.badgeClassName ? props.badgeClassName : ""
              } mb-2`}
            >
              {props.badgeTitle}
            </span>
          </Col>
          {/* <Col className="text-end">
            {props.feeText ? (
              <span>
                <small>
                  <em>Starting from</em>
                </small>
              </span>
            ) : null}
            <h5 className="text-success">{props.feeAmount}</h5>
          </Col> */}
        </Row>
        <a href="javascript:void(0)" onClick={() => handleNavigation()}>
          <h6 className="card-title mb-1">{props.cardTitle}</h6>
        </a>
        {props.duration ? (
          <p className="card-text mb-0">{props.duration}</p>
        ) : null}

        {props.location ? <p className="card-text">{props.location}</p> : null}
        {props.viewsCount ? (
          <p className="card-text">
            {props.viewsCount}{" "}
            <i className="fa-solid fa-circle circle-seperator mx-1"></i>{" "}
            {props.noOfDays}
          </p>
        ) : null}
        {props.doctorName ? (
          <div className="author d-flex my-3">
            <span className="user-image-wrap me-2">
              {props?.profilePicture ? (
                <img
                  src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${props?.profilePicture}`}
                  alt=""
                  title="Surgeon Profile"
                />
              ) : (
                <img
                  src={require("../../../img/user.png")}
                  alt=""
                  title="Surgeon Profile"
                />
              )}
             
            </span>
            <span className="author-details">
              <p className="fw-bold mb-0">
                <Link to={`/surgeon-profile/${props.doctorId}`}>{props.doctorName}</Link>
                {/* <Link to="">{props.doctorName}</Link> */}
              </p>
              <p className="text-muted mb-2">{props?.doctorAddress}</p>
            </span>
          </div>
        ) : null}
      </Card.Body>

      {/* {props.buttonTitle && !props.isHost ? ( */}
      {props.enroll ? (
        <Card.Footer className="bg-transparent pt-0 border-0">
          {/* {props.buttonDisabed ? (
            <span
              className={`badge mb-2 ${
                props.buttonClassName ? props.buttonClassName : ""
              }`}
            >
              {props.buttonTitle}
            </span>
          ) : (
            <Button
              className="btn-primary mb-2"
              onClick={() => props.buttonAction(props.id)}
            >
              {props.buttonTitle} <i className="fa-solid fa-arrow-right"></i>
            </Button>
          )} */}
          {/* props.buttonAction(props.confUrl) */}
          {" "}
           {/* {props.badgeTitle=="Conference" ? ( */}
           
            <Button
              className="btn-primary mb-2"
            >
              <Link 
                to={props.detailsLink}
                style={{color: "#FFF"}}
              >
                {"View Details"} <i className="fa-solid fa-arrow-right"></i>
              </Link>
            </Button>
          {/* ) : (
            null
          )} */}
          {/* badgeTitle="Conference" */}
        </Card.Footer>
      ) : null}
    </Card>
  );
};

export default ThumbnailCard;
