import React from "react";

const DownloadAndroidIOS = () => {
  return (
    <section className="pt-5 pb-0 bg-light">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6 text-center order-2 order-lg-1">
            <div className="mockup-wrap">
              <img src={require("../../../img/mockup.png")} className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-5 order-1 order-lg-2">
            <p className="title-meta mt-0">
              Download App <span className="line"></span>
            </p>
            <h3 className=" mb-3 display-6">
              {/* Surgic Share for <strong>Android</strong> and <strong>iOS</strong>{" "}
              is coming soon... */}
              Please download the app in <strong>Android</strong> and <strong>iOS</strong>
            </h3> 
            <p className=" mb-3 d-block">
              Surgic Share is Patented digital academic platform for expert, young
              and future surgeons for learning & sharing surgical skills
              through organizing & attending CME & Live surgery on Zoom, posting and
              getting updated with surgery videos, tutorials, Conference/Workshop/hands on
              training & Observership course and many more...
            </p>
            <a href="#playstore" title="Play Store" className="mb-2 me-2">
              <img src={require("../../../img/playstore.png")} alt="Play Store" />
            </a>
            <a href="#appstore" title="App Store" className="mb-2">
              <img src={require("../../../img/appstore.png")} alt="App Store" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadAndroidIOS;
