import { toast } from "react-toastify";
import { changeRoute } from "../../utils/core";
import * as api from "../../services/api";
import { doPayment } from "../../utils/payment";
import {
  GET_ALL_LIVESURGERYS,
  GET_LIVESURGERY_DETAILS,
  ADD_LIVESURGERY,
  EDIT_LIVESURGERY,
  MY_LIVESURGERYS,
  LIVESURGERY_INVITATION,
  MY_REGISTERED,
} from "../constants/livesurgeryActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

import { MY_ASSOCIATION_LIVESURGERYS } from "../constants/assocGroupActionTypes"

export const getAllLiveSurgery =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllLiveSurgery(data);
      // if(result.data.data.docs.length > 0){
      //   dispatch({ type: GET_ALL_LIVESURGERYS, payload: result.data.data.docs , hasMore : true, reset:reset });
      // }else {
      //   dispatch({ type: GET_ALL_LIVESURGERYS, payload: [] , hasMore : false, reset:reset });
      // }
      dispatch({ type: CLEAR_LOADING });

      dispatch({
        type: GET_ALL_LIVESURGERYS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getMyRegistered =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getMyRegisteredLiveSurgery(data);

      // dispatch({ type: MY_REGISTERED, payload: result.data.data });
      if (result) {
        dispatch({ type: CLEAR_LOADING });

        dispatch({
          type: MY_REGISTERED,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getMyUploads =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getMyLiveSurgery(data);
      // dispatch({ type: MY_LIVESURGERYS, payload: result.data.data.docs });
      if (result) {
        dispatch({ type: CLEAR_LOADING });
        dispatch({
          type: MY_LIVESURGERYS,
          payload: result.data.data.docs,
          hasMore: result.data.data.hasNextPage,
          reset: reset,
        });
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
  };

export const getLivesurgery = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.getLiveSurgeryById(data);
    dispatch({ type: GET_LIVESURGERY_DETAILS, payload: result.data.data[0] });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const registerToSurgery = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.registerNow(data);

    if (result.data.status.success) {
      let request = result.data.data;
      request.type = "LIVE_SURGERY";

      const response = await api.getLiveSurgeryById(data);
      dispatch({ type: GET_LIVESURGERY_DETAILS, payload: response.data.data[0] });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // navigate('/attendLiveSurgery')
       dispatch({ type: CLEAR_LOADING });
      // await doPayment(request);
    } else {
      toast.error("Error while registering live suregry", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const addLivesurgery = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    let result = {};
    if (data.get("associationId")) {
      result = await api.postAssociationLiveSurgery(data);
    } else {
      result = await api.addLiveSurgery(data);
    }

    if(result.data.status.success) {
      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      dispatch({ type: CLEAR_LOADING });

      dispatch({ type: ADD_LIVESURGERY, payload: result.data.data });
      if (data.get("associationId")) {
        let assId = data.get("associationId")
        navigate(`/myAssociationLiveSurgery/${assId}`)
      }else{
        navigate(`/myLiveSurgery`)
      }
      
      return "success";
    } else {
       toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });
      return false;
    }
   
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
};

export const editLivesurgery = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.updateLiveSurgery(data);
    toast.success("Livesurgery Updated Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    if (data.get("associationId")) {
      let assId = data.get("associationId")
      const response = await api.myAssociationLiveSurgery(data);
      if (response) {
        dispatch({ type: CLEAR_LOADING });
        dispatch({
          type: MY_ASSOCIATION_LIVESURGERYS,
          payload: response.data.data.docs,
          hasMore: response.data.data.hasNextPage,
          reset: true,
        });
      }

      navigate(`/myAssociationLiveSurgery/${assId}`)
    }else{

      const response = await api.getMyLiveSurgery(data);

      if (response) {
        dispatch({ type: CLEAR_LOADING });
        dispatch({
          type: MY_LIVESURGERYS,
          payload: response.data.data.docs,
          hasMore: response.data.data.hasNextPage,
          reset: true,
        });
      }

      navigate(`/myLiveSurgery`)
    }
    return "success";
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
};


export const joinLiveSurgery = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.joinLiveSurgery(data);

    if(result.data.status.success) {
      window.open(`https://hls.surgicshare.com?token=${result.data.data.sdk_token}&id=${result.data.data.sdk_room_id}&isHost=${result.data.data.is_host}`, "_self");
    } else {
      navigate(`/live-surgery-request?id=${data.id}&message=${result.data.status.message}`)
    }
    
    
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};
