import React from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumb = (props) => {
  const { fieldOne, fieldTwo, fieldThree, title, items } = props;
  const isCompleteProfile = useSelector(
    (state) => state.globalState.isCompleteProfile
  );
  let navigate = useNavigate();

  return (
    <>
      <section className="py-4 bg-primary">
        <Container>
          <Row>
            <div className="col-12">
              <h1 className="h2 text-white">{title}</h1>
              <nav className="custom-breadcrumb" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 pb-0">
                  {fieldTwo ? (
                    <>
                      <li className="breadcrumb-item ">
                        <Link to="/dashboard">{fieldOne}</Link>
                      </li>
                      <li className="breadcrumb-item active">{fieldTwo}</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {fieldThree}
                      </li>
                    </>
                  ) : (
                    <li className="breadcrumb-item ">
                      <Link to="/dashboard">{fieldOne}</Link>
                    </li>
                  )}
                  {/* {items &&
                    items.map((val, index) => (
                      const topName = val.name
                      {topName == "Home" ? (
                        <li key={index} onClick={()=>navigate(val.routeTo)} className={`breadcrumb-item d-none d-lg-block ${index===0 ? "px-0" : "active"}`}>{val.name}</li>
                      ) : (
                        <li key={index} onClick={()=>navigate(val.routeTo)} className={`breadcrumb-item ${index===0 ? "px-0" : "active"}`}>{val.name}</li>
                      ) }
                      
                    ))} */}

                  {items &&
                    items.map((val, index) => {
                      const topName = val.name;
                      return (
                        <li
                          key={index}
                          onClick={() => navigate(val.routeTo)}
                          className={`breadcrumb-item ${
                            index === 0 ? "px-0" : "active"
                          } ${
                            topName === "Home"
                              ? "d-none d-lg-block"
                              : "d-lg-block"
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          {topName === "Home" ? (
                            <span>{val.name}</span>
                          ) : (
                            <span>{val.name}</span>
                          )}
                        </li>
                      );
                    })}
                </ol>
              </nav>
            </div>
          </Row>
        </Container>
      </section>
      {/* {!isCompleteProfile &&  */}

      {/* } */}
    </>
  );
};

export default Breadcrumb;
