import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getInstitute,
  getRequestType,
  getSpeciality,
  getStates,
  getFaculty,
  getFacultySpeciality
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { requestTelementorship, getDoctorInfo } from "../../../redux/actions/mentorship.action";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const RequestMentorship = () => {
  let navigate = useNavigate();

  const { userId } = useParams();

  const fileInputRef = useRef(null);
 
  const specialityList = useSelector((state) => state.filters.speciality);
  const requestTypeList = useSelector((state) => state.filters.requestType);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const facultyList = useSelector((state) => state.filters.specialityfaculties);
  const mentorshipDoctor = useSelector((state) => state.mentorship.mentorshipDoctor);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [specialId, setSpecialId] = useState(null);
  const [instId, setInstId] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (userId && userId != undefined && userId != null) {
      dispatch(getDoctorInfo({ userId: userId }));
    }
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
    if (requestTypeList.length === 0) {
      dispatch(getRequestType());
    }


    if (userId) {
      dispatch(getStates({ country_id:  mentorshipDoctor?.country?._id }));
      dispatch(getCities({ state_id:  mentorshipDoctor?.state?._id }));
      dispatch(getInstitute({ city_id:  mentorshipDoctor?.institution?.cityId }));
      dispatch(getFacultySpeciality({ instituteId: mentorshipDoctor?.institution?._id, speciality: mentorshipDoctor?.speciality?._id }));
    }


  }, []);

  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    if (name === "speciality") {
      setSpecialId(value)
      if (instId != null) {
        dispatch(getFacultySpeciality({ instituteId: instId, speciality: value }));
      }
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    } else if (name === "institute") {
      // dispatch(getFaculty({ instituteId: value }));
      setInstId(value)
      dispatch(getFacultySpeciality({ instituteId: value, speciality: specialId }));
    }
  };

  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const requestData = new FormData();
    requestData.append("facultyCountry", values.country);
    requestData.append("facultyState", values.state);
    requestData.append("facultyCity", values.city);
    requestData.append("facultyInstitute", values.institute);
    requestData.append("speciality", values.speciality);
    requestData.append("mentorshipRequestType", values.requestType);
    requestData.append("facultyName", values.facultyName);
    requestData.append("attachment", file);
    requestData.append("mentorshipDetail", values.patientProfileInBrief);
    requestData.append("patientProfileInBrief", values.patientProfileInBrief);

    dispatch(requestTelementorship(requestData, navigate)).then((res) => {
      if (res === "success") {
        // Reset the form after submission
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setFile(null);
        resetForm();
      }
    });

    setSubmitting(false);
  };
  const initialState = userId ? {

    requestType: "",
    speciality: mentorshipDoctor?.speciality?._id,
    country: mentorshipDoctor?.country?._id,
    state: mentorshipDoctor?.state?._id,
    city: mentorshipDoctor?.institution?.cityId,
    institute: mentorshipDoctor?.institution?._id,
    facultyName: mentorshipDoctor?._id,
    mentorshipDetail: "",
    patientProfileInBrief: "",
    attachment: "",
  } : {
    requestType: "",
    speciality: "",
    country: "",
    state: "",
    city: "",
    institute: "",
    facultyName: "",
    mentorshipDetail: "",
    patientProfileInBrief: "",
    attachment: "",
  };

  const validationSchema = Yup.object({
    requestType: Yup.string().required("Type is required"),
    speciality: Yup.string().required("Speciality is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    institute: Yup.string().required("Institute/Hospital is required"),
    facultyName: Yup.string().required("Faculty's Name is required"),
    mentorshipDetail: Yup.string().required("Enter Telementorship Details"),
    patientProfileInBrief: Yup.string().required("Please Enter Patient Info"),
    attachment: Yup.mixed(),
  });

  return (
    <Root>
      <Breadcrumb
        title={"Request Telementorship/ Mentorship/ Online Classes"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Request Mentorship",
          },
        ]}
      />
      <ContainerWrapper>
        <ProfileComplete />
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Request Telementorship/ Mentorship/ Online Classes/ Add Expert opinion</h2>
                    <p>
                      Fill in the below details and request your mentorship.
                      Fields with <span className="text-danger">*</span> are
                      mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Request Type<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="requestType"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {requestTypeList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.requestName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="requestType"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's Country
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {countryList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.countryName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's State
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="state"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {stateList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.stateName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Faculty's City
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="city"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {cityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.cityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Institute/Hospital
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="institute"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {instituteList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.instituteName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="institute"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Faculty's Name
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="facultyName"
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {facultyList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.fullname}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="facultyName"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Details of Telementorship, Mentorship & Online classes
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="mentorshipDetail"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="mentorshipDetail"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Patient profile in brief ( Non Personal) for
                          Telementorship or Mentorship
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="patientProfileInBrief"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="patientProfileInBrief"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label for="">
                          Attach Patient Report
                        </ReactForm.Label>
                        <div className="input-group">
                          <input
                            type="file"
                            name="attachment"
                            className="form-control"
                            onChange={handleFileInputChange}
                            ref={fileInputRef}
                          />
                        </div>
                        <ErrorMessage
                          name="attachment"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Request Mentorship{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default RequestMentorship;
