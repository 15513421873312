import React from "react";
import PublicRoot from "../../components/PublicRoot";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DownloadAndroidIOS from "../../components/Download/DownloadAndroidIOS";

const Terms = () => {
  return (
    <PublicRoot>
      <Breadcrumb
        title={"Terms of Service"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Terms of Service",
          },
        ]}
      />
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 col-lg-10 order-2 order-lg-1">
              <p className="title-meta mt-0">
                Terms of Service <span className="line"></span>
              </p>
              <p>
                <strong>Surgic Share</strong> is a digital platform designed for
                the learning and sharing of surgical skills. The platform offers
                a variety of services, which include but are not limited to
                organizing web CMEs (Continuing Medical Education), live
                conferences, live workshops, sharing surgery videos and
                tutorials with group members, observership programs,
                telementorship, online classes, creating customized profiles for
                referral patients, and more. Some of these services are provided
                free of charge, while others are paid services.
              </p>
              <p>
                <strong>Fee Structure:</strong> The fees for services such as
                live conferences, online classes, and mentorships are determined
                solely by the event organizers and faculty members. Fees may
                vary depending on the type of service and the faculty involved.
                Surgic Share does not have control over these fees.
              </p>
              <p>
                <strong>Platform Role:</strong> Surgic Share acts solely as a
                platform for facilitating interactions between organizers,
                participants, faculty, and students for the purpose of
                exchanging surgical knowledge. Surgic Share itself does not
                guarantee any outcomes from the services provided, nor does it
                act as a mediator in financial disputes between participants and
                organizers.{" "}
              </p>
              <p>
                <strong>Technical Issues:</strong> While Surgic Share strives to
                provide a seamless digital experience, it is not responsible for
                any technical breakdowns, disruptions, or unforeseen glitches
                that may occur during the use of the platform. This includes,
                but is not limited to, issues related to internet connectivity,
                software malfunctions, or third-party service outages. Users
                acknowledge that technical issues are a risk inherent to digital
                platforms, and Surgic Share cannot be held liable for any losses
                arising from such issues.
              </p>
            </div>
          </div>
        </div>
      </section>
      <DownloadAndroidIOS />
    </PublicRoot>
  );
};

export default Terms;
