import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import {
  handleAcceptDecline,
  myInvitations,
} from "../../../redux/actions/meeting.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { formatDate, formattTime, timeAgo, isMeetingEnd } from "../../../utils/core";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";

const AttendCmeRtm = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.meeting.myInvitationsList);
  const pageNo = useSelector((state) => state.meeting.page);
  const hasMore = useSelector((state) => state.meeting.hasMore);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(myInvitations({ page: pageNo }));
  }, []);

  const fetchMeetings = () => {
    dispatch(myInvitations({ page: pageNo }));
  };

  const handleAcceptOrDecline = (data, status) => {
    let requestData = {
      status: status,
      meetingId: data._id,
      userId: currentUser,
      ...data
    };
    dispatch(handleAcceptDecline(requestData));
  };


  const isMeetingAccespted = (memberDetails) => {
      memberDetails.forEach((member) => {
          if(member.userId === currentUser) {
              if(member.isAccept === 0) {
                return "PENDING";
              } else if(member.isAccept === 1) {
                return "ACCEPTED";
              } else {
                return "DECLINED";
              }
          } else {
            return "OTHER";
          }
      })
       
  }

  return (
    <Root>
      <Breadcrumb title={"CME (Continuing Medical Education)"} fieldOne="Dashboard" fieldTwo="CME (Continuing Medical Education) Invitations" />
      <ContainerWrapper>
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">CME &amp; RTM Invitations</h2>
              <p>Total {meetingsList.length} CME &amp; RTM</p>
            </div>
            <div className=" d-lg-block">
              <button
                title="Invitations"
                className="btn btn-primary m-2"
                onClick={() => {
                  navigate("/attendCmeRtm");
                }}
              >
                Attend CME (Continuing Medical Education) <i className="fa-solid fa-arrow-right"></i>
              </button>
              <button
                title="Schedule CME (Continuing Medical Education)"
                className="btn btn-primary m-2 "
                onClick={() => {
                  navigate("/scheduleLiveCmeRtm");
                }}
              >
                Schedule CME (Continuing Medical Education) <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              <div className="card-body px-4 py-3">
                <div className="table-responsive">
                  <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      <th>Topic</th>
                      <th>Date &amp; Time</th>
                      <th>Hosted by</th>
                      <th>Status</th>
                      <th className="text-end">&nbsp;</th>
                    </thead>
                    <tbody>
                      {meetingsList.length > 0 ? (
                        meetingsList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.meetingDate)}
                                <br />
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    <Link to={`/surgeon-profile/${val.user_details._id}`}>
                                      {`Dr. ${val.user_details.firstName} ${val.user_details.lastName}`}
                                    </Link>
                                  </p>
                                  <p className="text-muted mb-0">{`${val.user_details.institution.instituteName}, ${val.user_details.country.state.city.cityName}`}</p>
                                </span>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {val.members.map((member) =>
                                member.userId === currentUser ? (
                                  member.isAccept === 0 && !isMeetingEnd(val.endDate) ? (
                                    <>
                                      <Button
                                        className=" btn btn-success mb-2 me-1"
                                        onClick={() =>
                                          handleAcceptOrDecline(val, 1)
                                        }
                                      >
                                        <i className="fa-solid fa-check me-1">
                                          
                                          
                                        </i>
                                        {" "}Accept
                                      </Button>
                                      <Button
                                        className=" btn btn-danger mb-2  me-1"
                                        onClick={() =>
                                          handleAcceptOrDecline(val, 2)
                                        }
                                      >
                                        <i className="fa-solid fa-times me-1"></i>{" "}
                                        Decline
                                      </Button>
                                    </>
                                  ) : member.isAccept === 1 ? (
                                    <span className="badge badge-success mx-md-3">Accepted</span>
                                  ) : (
                                    <span className="badge badge-danger mx-md-3">Declined</span>
                                  )
                                ) : null
                              )}

                            </td>

                            <td>

                              {val.members.map((member) =>
                                member.userId === currentUser ? (
                                  member.isAccept === 0 ? (
                                   null
                                  ) : member.isAccept === 1 ? (
                                    currentUser == val.user_details._id ? (
                                        val.startUrl != null && !isMeetingEnd(val.endDate) ? (
                                          <a
                                            href={val.startUrl}
                                            target="_blank"
                                            className=" btn btn-primary mb-2 btn-sm"
                                          >
                                            Start{" "}
                                            <i className="fa-solid fa-arrow-right"></i>
                                          </a>
                                        ) : (
                                          <span></span>
                                        )
                                      ) : val.joinUrl != null && !isMeetingEnd(val.endDate) ? (
                                        <a
                                          href={val.joinUrl}
                                          target="_blank"
                                          className=" btn btn-primary mb-2 btn-sm"
                                        >
                                          Join{" "}
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </a>
                                      ) : (
                                        null
                                    )
                                  ) : (
                                    null
                                  )
                                ) : null
                              )}
                              

                              <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button></td>
                          </tr>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                      <InfiniteScroll
                        fetchFunction={fetchMeetings}
                        globalState={meetingsList}
                        hasMore={hasMore}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default AttendCmeRtm;
