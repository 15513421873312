import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Card, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addPatientProcedure
} from "../../../redux/actions/registry.action";
import {
  getSpeciality,
  getSubSpeciality,
  getCities,
  getCountries,
  getInstitute,
  getStates,
  getFaculty,
  getFacultySpeciality
} from "../../../redux/actions/filter.action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const AddProcedure = () => {
  const fileInputRef = useRef(null);

  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);

  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const facultyList = useSelector((state) => state.filters.specialityfaculties);

  const tutorialTypesList = useSelector(
    (state) => state.tutorial.tutorialTypes
  );

  const [fileError, setFileError] = useState("");
  const [isfileError, setisFileError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { patientId } = useParams();

  const [specialId, setSpecialId] = useState(null);
  const [instId, setInstId] = useState(null);

  const handleFileInputChange = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (specialityList.length === 0) {
      dispatch(getSpeciality());
    }
    if (countryList.length === 0) {
      dispatch(getCountries());
    }
  }, []);


  const handleChange = (e, setFieldValue, dispatch) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === "speciality") {
      setSpecialId(value)
      dispatch(getSubSpeciality({ speciality_id: value }));
      if (instId != null) {
        dispatch(getFacultySpeciality({ instituteId: instId, speciality: value }));
      }
    } else if (name === "country") {
      dispatch(getStates({ country_id: value }));
    } else if (name === "state") {
      dispatch(getCities({ state_id: value }));
    } else if (name === "city") {
      dispatch(getInstitute({ city_id: value }));
    } else if (name === "institute") {
      // dispatch(getFaculty({ instituteId: value }));
      setInstId(value)
      dispatch(getFacultySpeciality({ instituteId: value, speciality: specialId }));
    }
  };

  const handleSubmit = (values, { setFieldValue, resetForm, setSubmitting }) => {
    const requestData = new FormData();

    requestData.append("speciality", values.speciality);

    if (values.sub_speciality && values.sub_speciality != "") {
      requestData.append("sub_speciality", values.sub_speciality);
    }

    requestData.append("procedure_name", values.procedureName);
    requestData.append("procedure_date", values.procedureDate);
    requestData.append("date_of_entry", values.entryDate);
    requestData.append("country", values.country);
    requestData.append("state", values.state);
    requestData.append("city", values.city);
    requestData.append("institute", values.institute);
    requestData.append("operating_surgeon", values.operatingSergeon);
    requestData.append("assistant_surgeon", values.assistantSergeon);
    requestData.append("complication_brief", values.complecationDescription);
    requestData.append("procedure_brief", values.undertakenDescription);
    requestData.append("material_brief", values.materialDescription);
    requestData.append("patient_id", patientId);


    const fileType = file.type;
    const dataArray = fileType.split("/");
    if (dataArray[0] != "video") {
      requestData.append("fileType", "OTHER");
    } else {
      requestData.append("fileType", "VIDEO");
    }
    requestData.append("media", file);
              
    dispatch(addPatientProcedure(requestData, navigate)).then((res) => {
      setSubmitting(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    });

    // Reset the form after submission
    setFile(null);
    resetForm();
  };

  const validationSchema = Yup.object({
    speciality: Yup.string().required("Speciality is required"),
    // sub_speciality: Yup.string().required("Subspeciality is required"),
    procedureName: Yup.string().required("Procedure Name is required"),
    procedureDate: Yup.string().required("Date of Procedure is required"),
    entryDate: Yup.string().required("Date of Entry is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    institute: Yup.string().required("Institute/Hospital is required"),
    operatingSergeon: Yup.string().required("Operating Surgeon is required"),
    assistantSergeon: Yup.string().required("Assistant Surgeon is required"),
    complecationDescription: Yup.string().required("Description of the complication is required"),
    undertakenDescription: Yup.string().required("Description of procedure is required"),
    materialDescription: Yup.string().required("Description of implant is required"),
    attachment: Yup.mixed(),
    agreement: Yup.boolean().oneOf([true], "You must confirm the data"),
    agreement1: Yup.boolean().oneOf([true], "You must confirm the data"),
  });

  const initialState =  {
      speciality: "",
      sub_speciality: "",
      procedureName: "",
      procedureDate: "",
      entryDate: "",
      country: "",
      state: "",
      city: "",
      institute: "",
      operatingSergeon: "",
      assistantSergeon: "",
      complecationDescription: "",
      undertakenDescription: "",
      materialDescription: "",
      attachment: "",
      agreement: false,
      agreement1: false
    };


  return (
    <Root>
        <Breadcrumb
          title={"Patient Registry"}
          fieldOne="Dashboard"
          fieldTwo="Add Surgical Procedure"
        />
    
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <Row>
          <Col xl={10} className="offset-xl-1">
            <Card className="bg-white rounded-3 mb-4">
              <Card.Header className="bg-white border-0 px-4 pt-4 pb-0">
                <Col>
                  <div>
                    <h2 className="h4 mb-1">Add Surgical Procedure</h2>
                    <p>
                      Fill in the below details. Fields with{" "}
                      <span className="text-danger">*</span> are mandatory.
                    </p>
                  </div>
                </Col>
              </Card.Header>
              <Card.Body className="pt-2 px-4 pb-4">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialState}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {(formik) => (
                    <Form className="row g-3 g-lg-4">

                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Speciality<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {specialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.specialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label className="form-label">
                          Subspeciality
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="sub_speciality"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {subSpecialityList.map((val, index) => (
                            <option key={index} value={val?._id}>
                              {val.subSpecialityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="sub_speciality"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Procedure Name
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          type="text"
                          name="procedureName"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="procedureName"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Date of Procedure<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="date"
                          name="procedureDate"
                          // min={getDate(new Date())}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="procedureDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={6}>
                        <ReactForm.Label for="">
                          Date of Entry<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          className="form-control"
                          type="date"
                          name="entryDate"
                          // min={getDate(new Date())}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="entryDate"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Country
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {countryList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.countryName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          State
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="state"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {stateList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.stateName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          City
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="city"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {cityList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.cityName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Institute/Hospital
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="institute"
                          className="form-select"
                          onChange={(e) =>
                            handleChange(e, formik.setFieldValue, dispatch)
                          }
                        >
                          <option value="">Select</option>
                          {instituteList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.instituteName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="institute"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Operating Surgeon
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="operatingSergeon"
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {facultyList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.fullname}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="operatingSergeon"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={4}>
                        <ReactForm.Label className="form-label">
                          Assistant Surgeon
                          <span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="select"
                          name="assistantSergeon"
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {facultyList.map((val, index) => (
                            <option key={index} value={val._id}>
                              {val.fullname}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="assistantSergeon"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>
                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Brief description of the complication<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="complecationDescription"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="complecationDescription"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Brief description of procedure undertaken<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="undertakenDescription"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="undertakenDescription"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>

                      <Col md={12}>
                        <ReactForm.Label className="form-label">
                          Brief description of implant/ material used<span className="text-danger">*</span>
                        </ReactForm.Label>
                        <Field
                          as="textarea"
                          name="materialDescription"
                          className="form-control"
                          rows="4"
                        />
                        <ErrorMessage
                          name="materialDescription"
                          component="div"
                          className="text-danger text-small"
                        />
                      </Col>


                        <Col md={12}>
                          <ReactForm.Label for="">
                            Attach Photos/ Videos<span className="text-danger">*</span>
                          </ReactForm.Label>
                          <div className="input-group">
                            <input
                              type="file"
                              name="attachment"
                              className="form-control"
                              onChange={handleFileInputChange}
                              ref={fileInputRef}

                            />
                          </div>
                          {/* <Form.Text id="" className="form-text">
                            Supported files MP4, MOV, WMV, AVI, and FLV.
                          </Form.Text> */}
                          {isfileError ? (
                            <p className="text-danger text-small">
                              {fileError}
                            </p>
                          ) : null}
                        </Col>

                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement"
                          >
                            I confirm, patient’s identity has not been revealed directly or indirectly in the video.
                          </label>
                          <ErrorMessage
                            name="agreement"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="agreement1"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="agreement1"
                          >
                            I confirm, surgery in uploaded surgery video was performed by me and I take accountability of authenticity of video. Surgery video is for academic purpose only.
                          </label>
                          <ErrorMessage
                            name="agreement1"
                            component="div"
                            className="text-danger text-small"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        Note: Video uploaded will be published on website
                        within two working days, post basic check list.
                      </Col>
                      <Col className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save Procedure{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default AddProcedure;
