import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form as ReactForm, Row } from "react-bootstrap";


const ComingSoon = () => {
  

  return (
    <Root>
      <Breadcrumb
        title={"Coming Soon"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "Coming Soon",
          },
        ]}
      />

      <ContainerWrapper>
        <Row>
            <h2 style={{textAlign: "center"}}>This service will be availble soon...</h2>
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default ComingSoon;
