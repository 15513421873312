import axios from "axios";
import * as api from "../../services/api";
import { toast } from "react-toastify";

// export const makePayment = async (params) => {
//   try {

//       const order = await createOrder(params);

//       if(!order) {
//         // console.log("order not created")
//         return false;
//       }

//       const options = {
//         // key: "rzp_test_SwJkixsA4WGkUQ",
//         key: process.env.REACT_APP_RAZORPAY_KEY,
//         amount: order.data.amount,
//         currency: "INR",
//         name: "Surgic Share",
//         description: "Registration",
//         // image: "https://example.com/your_logo",
//         order_id: order.data.id,
//         handler: async (response) => {
//           if(params.type == "PATIENT_REGISTRY") {
//              const result = await api.addNewPatient(params.savedData);
//              if (result.data.status.success) {
//                 toast.success(result.data.status.message, {
//                   position: "top-right",
//                   autoClose: 3000,
//                   hideProgressBar: false,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                   draggable: true,
//                   progress: undefined,
//                 });
//               } else {
//                 toast.error(result.data.status.message, {
//                   position: "top-right",
//                   autoClose: 3000,
//                   hideProgressBar: false,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                   draggable: true,
//                   progress: undefined,
//                 });
//               }
//             // console.log("success payment", response);
//           }
//         },
//         prefill: {
//           // name: "example name",
//           // email: "youremail@example.com",
//           // contact: "9999999999",
//         },
//         notes: order.data.notes,
//         theme: {
//           color: "#0c2c54",
//         },
//         modal: {
//           ondismiss: async () => {
//             const cancelResponse = await cancelPayment(params);
//             // console.log('Payment modal closed');
//           }
//         }
//       };

//       const script = document.createElement("script");
//       script.src = "https://checkout.razorpay.com/v1/checkout.js";
//       script.async = true;

//       script.onload = () => {
//         const rzp1 = new window.Razorpay(options);
//         rzp1.open();
//         rzp1.on("payment.failed", function (response) {
//             console.log("Filed from Razor pay")
//         });
//       };

//       document.body.appendChild(script);

//   } catch (error) {
//     console.error('Failed', error);
//   }
// };

export const makePayment = async (params) => {
  try {
    const order = await createOrder(params);

    if (!order) {
      return false;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: order.data.amount,
      currency: "INR",
      name: "Surgic Share",
      description: "Registration",
      order_id: order.data.id,
      handler: async (response) => {
        if (params.type == "PATIENT_REGISTRY") {
          const result = await api.addNewPatient(params.savedData);
          if (result.data.status.success) {
            toast.success(result.data.status.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(result.data.status.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // console.log("success payment", response);
        }

        window.location.reload();
        // window.location.href = "https://surgicshare.com/live-conference-workshop";
        return response; // This will be captured in the promise
      },
      prefill: {},
      notes: order.data.notes,
      theme: {
        color: "#0c2c54",
      },
      modal: {
        ondismiss: async () => {
          const cancelResponse = await cancelPayment(params);
          // window.location.href = "https://surgicshare.com/live-conference-workshop";
          window.location.reload();
          // console.log('Payment modal closed');
        },
      },
    };

    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;

      script.onload = () => {
        const rzp1 = new window.Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", (response) => {
          console.log("Failed from Razorpay");
          reject(response); // Reject the promise with the response
        });

        options.handler = (response) => {
          resolve(response); // Resolve the promise with the response
        };
      };

      script.onerror = (error) => {
        reject(error); // Reject the promise if there's an error loading the script
      };

      document.body.appendChild(script);
    });
  } catch (error) {
    console.error("Failed", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

const createOrder = async (data) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/payments/service-api/v1/pay-sync/create-order`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    const request = {
      payment_type: data.type,
      id: data._id,
      booking_id: data.members.booking_id,
    };

    const response = await axios.post(apiUrl, request, config);
    return response.data;
  } catch (error) {
    console.error("Error while creating order:", error);
    return error.message;
  }
};

export const closeWindow = async (data) => {
  const cancelRes = await cancelPayment(data);
};

const cancelPayment = async (data) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/payments/service-api/v1/pay-sync/cancel-payment`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    const request = {
      payment_type: data.type,
      id: data._id,
      booking_id: data.members.booking_id,
    };

    const response = await axios.post(apiUrl, request, config);
    return response.data;
  } catch (error) {
    console.error("Error while canceling:", error);
    return error.message;
  }
};
